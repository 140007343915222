import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';

import { ModalModule, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

//pipe
import { DatePipeModule } from './pipes/date.pipe';
import { PhonePipeModule } from './pipes/phone.pipe';
import { TreeviewModule } from 'ngx-treeview';
//modals
import { ChangePasswordModalComponent } from './views/modals/change-password-modal/change-password-modal.component';

import { AddUpdateOrganizationInfoManagementModalComponent } from './views/organization-master/organization-info-management/add-update-organization-info-management-modal/add-update-organization-info-management-modal.component';
import { AddUpdateOrganizationUserManagementModalComponent } from './views/organization-master/organization-user-management/add-update-organization-user-management-modal/add-update-organization-user-management-modal.component';

import { ConfirmationModalComponent } from './views/modals/confirmation-modal/confirmation-modal.component';

// App views
import { LoginModule } from './views/login/login.module';
import { ResetPasswordModule } from './views/reset-password/reset-password.module';
import { OrganizationMasterModule } from './views/organization-master/organization-master.module';

//sub-component

//services
import { AdminService } from './components/common/services/admin.services';
import { AuthenticationService } from './components/common/services/authentication.service';
import { OrganizationManagementService } from './components/common/services/organization_management.service';

import { ToastMessageService } from './components/common/toast-message/toast-message.service';
import { LoaderService } from './views/loader.service';

//helpers
import { CommonHelper } from './components/common/helpers/common.helper';
import { ToastMessageComponent } from './components/common/toast-message/toast-message.component';
import { UploadFileComponent } from './components/common/upload-file/upload-file.component';

// App modules/components
import { LayoutsModule } from './components/common/layouts/layouts.module';
import { MultiSelectModule } from './components/common/multi-select/multi-select.module';

import { ChurchTypeSelectModule } from './components/common/church-type-select/church-type-select.module';

import { EditorModule } from '@tinymce/tinymce-angular';


import { PastorateDetailsModule } from './views/pastorate-details/pastorate-details.module';


import { AddUpdateChurchUserTypeComponent } from './views/church-user-type/add-update-church-user-type/add-update-church-user-type.component';
import { ChurchUserTypeService } from './components/common/services/church-user-type.service';
import { ChurchUserTypeModule } from './views/church-user-type/church-user-type.module';
import { ChurchUserTypeManagementComponent } from './views/church-user-type/church-user-type-management/church-user-type-management.component';



import { PastorateDetailsComponent } from './views/pastorate-details/pastorate-details.component';
import { AddUpdatePastorateDetailsComponent } from './views/pastorate-details/add-update-pastorate-details/add-update-pastorate-details.component';

import { PastorateDetailService } from './components/common/services/pastorate-details.service';

@NgModule({
  declarations: [
    AppComponent,
    CommonHelper,
    ToastMessageComponent,
    UploadFileComponent,
    ChangePasswordModalComponent,
    ConfirmationModalComponent,
    AddUpdateOrganizationInfoManagementModalComponent,
    AddUpdateOrganizationUserManagementModalComponent,
    AddUpdateChurchUserTypeComponent,
    ChurchUserTypeManagementComponent,
  ],
  imports: [
    // Angular modules
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    TreeviewModule,
    HttpClientModule,
    ModalModule.forRoot(),
    TreeviewModule.forRoot(),
    // Views
    LoginModule,
    ResetPasswordModule,
    OrganizationMasterModule,
    PhonePipeModule,

    // Modules
    LayoutsModule,
    MultiSelectModule,
    ChurchTypeSelectModule,
    PastorateDetailsModule,
    EditorModule,
    RouterModule.forRoot(ROUTES),
  ],
  entryComponents: [
    ChangePasswordModalComponent,
    ConfirmationModalComponent,
    AddUpdateOrganizationInfoManagementModalComponent,
    AddUpdateOrganizationUserManagementModalComponent,
    AddUpdateChurchUserTypeComponent,
    PastorateDetailsComponent,
    AddUpdatePastorateDetailsComponent
  ],
  providers: [
    AdminService,
    OrganizationManagementService,
    ChurchUserTypeService,
    PastorateDetailService,
    AuthenticationService,
    ToastMessageService,
    LoaderService,
    CommonHelper,
    BsModalService,
    BsModalRef,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
