import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddUpdatePastorateDetailsComponent } from './add-update-pastorate-details/add-update-pastorate-details.component';
import { PastorateDetailsManagementComponent } from './pastorate-details-management/pastorate-details-management.component';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { LoaderModule } from 'app/components/common/loader/loader.module';
import { MultiSelectModule } from 'app/components/common/multi-select/multi-select.module';
import { DatePipeModule } from 'app/pipes/date.pipe';
import { PhonePipeModule } from 'app/pipes/phone.pipe';
import { TreeviewModule } from 'ngx-treeview';
import { PastorateDetailsComponent } from './pastorate-details.component';

@NgModule({
  declarations: [
    AddUpdatePastorateDetailsComponent,
    PastorateDetailsManagementComponent,
    PastorateDetailsComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    DatePipeModule,
    LoaderModule,
    PhonePipeModule,
    TreeviewModule,
    MultiSelectModule,
  ],
})
export class PastorateDetailsModule {}
