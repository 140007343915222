import {NgModule} from "@angular/core";
import {ChurchTypeSelectComponent} from "./church-type-select.component";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule }   from '@angular/forms';
@NgModule({
    declarations: [ChurchTypeSelectComponent],
    imports     : [BrowserModule,FormsModule],
    exports     : [ChurchTypeSelectComponent],
})

export class ChurchTypeSelectModule {}