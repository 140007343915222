import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { ToastMessageService } from '../toast-message/toast-message.service';


@Component({
  selector: 'app-common-helper',
  template: '``'
})

export class CommonHelper {
  constructor(public router: Router, public _toastMessageService: ToastMessageService) {
  }

  showError(err) {
    try {
      if (err.error && err.error.error) {
        this._toastMessageService.alert("error", err.error.error);
      } else if (err.error && err.error.message) {
        this._toastMessageService.alert("error", err.error.message);
      } else {
        this._toastMessageService.alert("error", "internal server error");
      }
    } catch (e) {
      this._toastMessageService.alert("error", "internal server error");
    }
  }
  downloadSampleFile(data, filename = 'data', headers) {
    let csvData = this.ConvertToCSVWithourSRNO(data, headers);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  ConvertToCSVWithourSRNO(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];

        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }

}
