export const states = [{
    "value": "AN",
    "name": "Andaman and Nicobar Islands"
}, {
    "value": "AP",
    "name": "Andhra Pradesh"
}, {
    "value": "AR",
    "name": "Arunachal Pradesh"
}, {
    "value": "AS",
    "name": "Assam"
}, {
    "value": "BR",
    "name": "Bihar"
}, {
    "value": "CG",
    "name": "Chandigarh"
}, {
    "value": "CH",
    "name": "Chhattisgarh"
}, {
    "value": "DH",
    "name": "Dadra and Nagar Haveli"
}, {
    "value": "DD",
    "name": "Daman and Diu"
}, {
    "value": "DL",
    "name": "Delhi"
}, {
    "value": "GA",
    "name": "Goa"
}, {
    "value": "GJ",
    "name": "Gujarat"
}, {
    "value": "HR",
    "name": "Haryana"
}, {
    "value": "HP",
    "name": "Himachal Pradesh"
}, {
    "value": "JK",
    "name": "Jammu and Kashmir"
}, {
    "value": "JH",
    "name": "Jharkhand"
}, {
    "value": "KA",
    "name": "Karnataka"
}, {
    "value": "KL",
    "name": "Kerala"
}, {
    "value": "LA",
    "name": "Ladakh"
}, {
    "value": "LD",
    "name": "Lakshadweep"
}, {
    "value": "MP",
    "name": "Madhya Pradesh"
}, {
    "value": "MH",
    "name": "Maharashtra"
}, {
    "value": "MN",
    "name": "Manipur"
}, {
    "value": "ML",
    "name": "Meghalaya"
}, {
    "value": "MZ",
    "name": "Mizoram"
}, {
    "value": "NL",
    "name": "Nagaland"
}, {
    "value": "OR",
    "name": "Odisha"
}, {
    "value": "PY",
    "name": "Puducherry"
}, {
    "value": "PB",
    "name": "Punjab"
}, {
    "value": "RJ",
    "name": "Rajasthan"
}, {
    "value": "SK",
    "name": "Sikkim"
}, {
    "value": "TN",
    "name": "Tamil Nadu"
}, {
    "value": "TS",
    "name": "Telangana"
}, {
    "value": "TR",
    "name": "Tripura"
}, {
    "value": "UP",
    "name": "Uttar Pradesh"
}, {
    "value": "UK",
    "name": "Uttarakhand"
}, {
    "value": "WB",
    "name": "West Bengal"
}]