import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { OrganizationMasterComponent } from './organization-master.component';
import { DatePipeModule } from '../../pipes/date.pipe';
import { OrganizationInfoManagementComponent } from './organization-info-management/organization-info-management.component';
import { OrganizationUserManagementComponent } from './organization-user-management/organization-user-management.component';
import { TreeviewModule } from 'ngx-treeview';

import { LoaderModule } from '../../components/common/loader/loader.module';
import { PhonePipeModule } from '../../pipes/phone.pipe';
import { MultiSelectModule } from '../../components/common/multi-select/multi-select.module';

import { ChurchTypeSelectModule } from '../../components/common/church-type-select/church-type-select.module';
// import { TreeTableModule } from 'primeng/treetable';
// import { TreeModule } from 'primeng/tree';
@NgModule({
  declarations: [
    OrganizationMasterComponent,
    OrganizationInfoManagementComponent,
    OrganizationUserManagementComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    DatePipeModule,
    LoaderModule,
    PhonePipeModule,
    TreeviewModule,
    MultiSelectModule,
    ChurchTypeSelectModule,
    // TreeTableModule,
    // TreeModule,
  ],
})
export class OrganizationMasterModule {}
