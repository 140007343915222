import { Component, OnInit } from '@angular/core';
import { CommonHelper } from 'app/components/common/helpers/common.helper';
import { ChurchUserTypeService } from 'app/components/common/services/church-user-type.service';
import { ToastMessageService } from 'app/components/common/toast-message/toast-message.service';
import { BaseModalComponent } from 'app/views/modals/base-modal.component';
import { environment } from 'environments/environment';
import moment = require('moment');
import { BsModalRef } from 'ngx-bootstrap';
import { PastorateDetailService } from 'app/components/common/services/pastorate-details.service';

@Component({
  selector: 'app-add-update-pastorate-details',
  templateUrl: './add-update-pastorate-details.component.html',
  styleUrls: ['./add-update-pastorate-details.component.css'],
})
export class AddUpdatePastorateDetailsComponent
  extends BaseModalComponent
  implements OnInit
{
  decision: string = '';
  public recordLimit: number = 10;
  permissionDropdownList = [];
  default_church_type_list:any=[];
  selectedItems = [];
  dropdownSettings = {};
  public sort_by: any = {};
  public table_data: any[] = [];
  public activePageNumber: number = 0;
  passType: string = 'password';
  show = false;
  institute_obj: any = {
    _id: null,
    pastorate_union_id:null,
    pastorate_detail_name: null,
    is_active: true,
  };
  public isLoaderShow = false;
  countrycodelist: any;
  isDoneBtnDisabled: boolean = false;
  response_data: any = null;
  submitted: boolean = false;
  public current_sort_type: any = '';
  default_modules_list: any = [];
  selected_institute_modules: any = [];
  public filters: any = {};
  public tzNames: string[];
  public selectedTz: string;
  public total_table_records = 0;
  public utcDate: moment.Moment;


  churchtype:any=[
    {
      _id:1,
      name:'Amar'
    }
  ]

  public tzDate: moment.Moment;
  showErrormsg: any;

  institute_logo_data: any = '';
  time_zones = moment.tz.names();

  constructor(
    public modalRef: BsModalRef,
    public _toastMessageService: ToastMessageService,
    public commonHelper: CommonHelper,
    public churchUserTypeService: ChurchUserTypeService,
    public pastorateDetailService: PastorateDetailService
  ) {
    super(modalRef);
    this.tzNames = moment.tz.names();
    // this.timeZoneChanged('America/New_York');
  }
  public timeZoneChanged(timeZone: string): void {
    this.selectedTz = timeZone;

    this.updateTime(timeZone);
  }

  private updateTime(timeZone: string) {
    const currentTime = new Date().getTime();

    this.utcDate = moment(currentTime).utc();

    this.tzDate = moment(currentTime).tz(timeZone);
  }

  ngOnInit() {


    this.getPastorateUnionDetails({page:1,})
  }

  onCancel() {
    this.decision = 'cancel';
    this.close(true);
  }






  getPastorateUnionDetails(page) {
    //this._loaderService.changeLoaderStatus(true);
    this.isLoaderShow = true;

    var params = {
      filters: this.filters,
      limit: this.recordLimit,
      page: page,
    };



    this.activePageNumber = page;
    
      this.churchUserTypeService.getChurchUserType(params).subscribe(
        (response: any) => {
          this.table_data = [];
          response.data.data.forEach((ho, index) => {
            ho.index = index + 1 + params.limit * (params.page - 1);
          });
          this.table_data = JSON.parse(JSON.stringify(response.data.data));
          this.total_table_records = response.data.total_record_count;
          // this.onChangePage(page, 0);
          this.isLoaderShow = false;
          //this._loaderService.changeLoaderStatus(false);
        },
        (err) => {
          this.commonHelper.showError(err);
          this.isLoaderShow = false;
          //this._loaderService.changeLoaderStatus(false);
        }
      );
    
      //this._loaderService.changeLoaderStatus(false);
      this.isLoaderShow = false;
    
  }



  


  submit() {

    if (!this.institute_obj.pastorate_union_id ||!this.institute_obj.pastorate_union_id.trim()) {
      this._toastMessageService.alert('error', 'Please add Pastorate Union Detail ');
      return;
    }
    if (!this.institute_obj.pastorate_detail_name ||!this.institute_obj.pastorate_detail_name.trim()) {
      this._toastMessageService.alert('error', 'Please add Pastorate Union Detail Name');
      return;
    }

    let sData = JSON.parse(JSON.stringify(this.institute_obj));

    if (!this.institute_obj._id) {
      //add new institute
      this.isDoneBtnDisabled = true;
      delete sData._id;
      this.pastorateDetailService.addPastorateDetail(sData).subscribe(
        (response: any) => {
          this.response_data = response.data;
          this._toastMessageService.alert(
            'success',
            'Church type data added successfully.'
          );
          this.isDoneBtnDisabled = false;
          this.done();
        },

        (err) => {
          this.commonHelper.showError(err);
          this.isDoneBtnDisabled = false;
        }
      );
    } else {
      //update institute
      this.isDoneBtnDisabled = true;
      this.pastorateDetailService
        .updatePastorateDetail(this.institute_obj._id, sData)
        .subscribe(
          (response: any) => {
            this._toastMessageService.alert(
              'success',
              'Pastorate Detail updated successfully.'
            );
            this.response_data = response.data;

            this.isDoneBtnDisabled = false;
            this.done();
          },
          (err) => {
            this.commonHelper.showError(err);
            this.isDoneBtnDisabled = false;
          }
        );
    }
  }

  done() {
    this.decision = 'done';
    this.close(true);
  }

  ShowHide(value) {
    if (value == 'password') {
      this.passType = 'text';
      this.show = true;
    } else {
      this.passType = 'password';
      this.show = false;
    }
  }
}
