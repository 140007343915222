import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { CommonHelper } from '../../../components/common/helpers/common.helper';
import { LoaderService } from '../../loader.service';
import { OrganizationManagementService } from '../../../components/common/services/organization_management.service';
import { ChurchUserTypeService } from '../../../components/common/services/church-user-type.service';
import { AddUpdateOrganizationInfoManagementModalComponent } from './add-update-organization-info-management-modal/add-update-organization-info-management-modal.component';
import { ConfirmationModalComponent } from '../../modals/confirmation-modal/confirmation-modal.component';
import { ToastMessageService } from '../../../components/common/toast-message/toast-message.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BaseModalComponent } from '../../../views/modals/base-modal.component';
import { Subscription } from 'rxjs';
import { PastorateDetailService } from 'app/components/common/services/pastorate-details.service';

@Component({
  selector: 'organization-info-management',
  providers: [],
  templateUrl: 'organization-info-management.template.html',
  styleUrls: ['./organization-info-management.component.css'],
})
export class OrganizationInfoManagementComponent
  extends BaseModalComponent
  implements OnInit
{
  @Input('table_info') table_info: any;
  @Input('default_modules_list') default_modules_list: any = [];
  @Input('country_list') country_list: any = [];
  @Input('state_list') state_list: any = [];
  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  @Output() onLoaderStatusChange: EventEmitter<any> = new EventEmitter();
  @Output() refreshTable: EventEmitter<any> = new EventEmitter();
  default_roles_list: any = [];
  // Inside your component class
selectedPastorateUnionId: string | null = null;

  tabledata: any = [];
  childerndata: any = [];
  selected_organization: any = [];
  selected_createdby;
  public filters: any = {};
  public loading: boolean = false;
  public recordLimit: number = 10;
  search_text: any = '';
  items: any;
  default_organizations_list: any = [];
  organizations_list: any = [];
  default_church_type_list: any;
  showchildernlist: any = [];
  originalTableInfo: any[] = []
  showchilderdorpdown: boolean = false;
  showchildern = [];
  showGrandchildern = [];
  showGreatGrandchildern = [];
  showOnSearch: boolean = false;
  matched_data = [];
  pastorateDetail: any = [];
  filtered_organizations_list = [];
  churchtype: any;
  organization_user_obj: any = {
    _id: null,
    first_name: null,
    middle_name: null,
    last_name: '',
    employee_id: '',
    phone_number: '',
    email: '',
    is_active: true,
    username: null,
    church_name_type: '',
  };

  constructor(
    private router: Router,
    private commonHelper: CommonHelper,
    private _loaderService: LoaderService,
    private organizationManagementService: OrganizationManagementService,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    private _toastMessageService: ToastMessageService,
    private churchUserTypesService: ChurchUserTypeService,
    public pastorateDetailService   :PastorateDetailService
  ) {
    super(modalRef);
  }

  ngOnInit() {
    let organization_id = localStorage.getItem('organization_id');
    // this.getOrganizationList();
    this.getChurchTypeList();
    this.getChurchType();
    this.getPastorateDetails();
    this.initializeOriginalData();

    // setTimeout(() => {
    //   this.table_info.forEach((org) => {
    //     org.showChildren = false;
    //     org.showGrandchildren = false;
    //     org.showGreatGrandchildren = false;
    //   });
    // }, 1000);
  }

  ngOnChanges(changeRecord: any) {
    let getAlldata: any = [];
    this.table_info.forEach((element: any) => {
      getAlldata.push(element.data);
    });

    getAlldata.forEach((element: any) => {
      if (element.search == true) {
        let data: any[] = [];
        data = element;
        this.selected_organization = getAlldata.map((d) => {
          return {
            _id: d._id,
            text: d.organization_name,
            organization_name: d.organization_name,
            is_active: true,
          };
        });
      }
    });
  }

  initializeOriginalData() {
    setTimeout(() => {
    // Assuming this.table_info is already populated through the @Input property
    this.originalTableInfo = this.table_info;
    console.log('this.originalTableInfo',this.originalTableInfo)
    }, 500);
    
  }


  getPastorateDetails() {
    this.pastorateDetailService.getAllPastorateType({}).subscribe((res: any) => {
      this.loading = false;
      if (res.status == 200 && res.data) {
        this.pastorateDetail = res.data.data;
      }
    });
  }

  // Inside your component class
  onPastorateUnionChange(event) {
  
    // Update the table_info based on the selectedPastorateUnionId
    // You can modify this logic according to your requirements
    if (event) {
      // Filter the original data and assign it back to table_info
      this.table_info = this.originalTableInfo.filter(org => org.data.church_type._id === event);
    } else {
      // If no filter is selected, reset table_info to the original data
      this.table_info = [...this.originalTableInfo];
    }
  }


  getOrganizationFilter(event){
    console.log('event',event)
     // Update the table_info based on the selectedPastorateUnionId
    // You can modify this logic according to your requirements
    if (event) {
      // Filter the original data and assign it back to table_info
      this.table_info = this.originalTableInfo.filter(org => org.data._id === event);
      console.log('tab',this.table_info)
    } else {
      // If no filter is selected, reset table_info to the original data
      this.table_info = [...this.originalTableInfo];
    }
  }


  onPastorateDetailChange(event){
        // Update the table_info based on the selectedPastorateUnionId
    // You can modify this logic according to your requirements
    if (event) {
      console.log('this.originalTableInfo',this.originalTableInfo)
      // Filter the original data and assign it back to table_info
      this.table_info = this.originalTableInfo.filter(org => org.data.pastorate_detail_id === event);
    } else {
      // If no filter is selected, reset table_info to the original data
      this.table_info = [...this.originalTableInfo];
    }
  }

  


  getChurchTypeList() {
    return new Promise((resolve, reject) => {
      this.churchUserTypesService.getAChurchTypes().subscribe(
        (response: any) => {
          this.default_church_type_list = response.data.data.map((d) => {
            return {
              _id: d._id,
              text: d.organization_name,
              organization_name: d.organization_name,
              is_active: d.is_active,
            };
          });
          return resolve({});
        },
        (err) => {
          this.commonHelper.showError(err);
          return resolve({});
        }
      );
    });
  }

  reloadCurrentRoute() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    const currentUrl = this.router.url + '?';
    this.router.navigateByUrl(currentUrl).then(() => {
      this.router.navigated = false;
      this.router.navigate([this.activatedRoute.snapshot.url.join('/')]);
    });
  }

  onClickAdd(table_info) {
    this.modalRef = this.modalService.show(
      AddUpdateOrganizationInfoManagementModalComponent,
      {
        class: 'organization-info-management-modal',
        backdrop: 'static',
        keyboard: false,
      }
    );
    this.modalRef.content.decision = '';
    this.modalRef.content.default_roles_list = this.default_roles_list;
    var currSub: Subscription = this.modalRef.content.onHide.subscribe(
      (result: boolean) => {
        currSub.unsubscribe();
        if (this.modalRef.content.decision === 'done') {
          const newRecord = JSON.parse(
            JSON.stringify(this.modalRef.content.response_data)
          );
          newRecord.index = this.table_info.length + 1;
          this.table_info.push(newRecord);
          this.reloadCurrentRoute();
        }
      }
    );
  }

  onClickEdit(refData, index) {
    console.log('refData',refData)
    this.modalRef = this.modalService.show(
      AddUpdateOrganizationInfoManagementModalComponent,
      {
        class: 'organization-info-management-modal',
        backdrop: 'static',
        keyboard: false,
      }
    );
    this.modalRef.content.organization_obj = JSON.parse(
      JSON.stringify(refData)
    );
    this.modalRef.content.default_roles_list = this.default_roles_list;
    var currSub: Subscription = this.modalRef.content.onHide.subscribe(
      (result: boolean) => {
        currSub.unsubscribe();
        if (this.modalRef.content.decision === 'done') {
          this.table_info[index] = JSON.parse(
            JSON.stringify(this.modalRef.content.organization_obj)
          );
          this.reloadCurrentRoute();
        }
      }
    );
  }

  onClickDelete(refData, index) {
    this.modalRef = this.modalService.show(ConfirmationModalComponent, {
      class: 'common-small-modal',
      backdrop: 'static',
      keyboard: false,
    });
    this.modalRef.content.decision = '';
    this.modalRef.content.step = 2;
    this.modalRef.content.confirmation_text =
      'Are sure to delete organization?';

    var currSub: Subscription = this.modalRef.content.onHide.subscribe(
      (result: boolean) => {
        currSub.unsubscribe();
        if (this.modalRef.content.decision === 'done') {
          this.onLoaderStatusChange.emit(true);
          //this._loaderService.changeLoaderStatus(true);
          this.organizationManagementService
            .removeOrganization(refData._id, {})
            .subscribe(
              (response: any) => {
                if (response.data) {
                  this._toastMessageService.alert(
                    'success',
                    'Organization data deleted successfully.'
                  );
                  this.table_info.splice(index, 1);
                  this.reloadCurrentRoute();
                }
                this.onLoaderStatusChange.emit(false);
                //this._loaderService.changeLoaderStatus(false);
              },
              (err) => {
                this.commonHelper.showError(err);
                this.onLoaderStatusChange.emit(false);
                //this._loaderService.changeLoaderStatus(false);
              }
            );
        }
      }
    );
  }

  onChangeSelection(event) {
    try {
      var data = JSON.parse(event);
      this.selected_organization = data.value;
    } catch (err) {
      console.log(err);
    }
  }
  resetSearchOnTable() {
     this.organization_user_obj.user_type_id ='';
    this.organization_user_obj.church_name_type ='';

    this.reloadCurrentRoute();

  }



  searchOnTable() {
    
    if(this.organization_user_obj.user_type_id && this.organization_user_obj.church_name_type){
      if (this.table_info.length) {
        this.table_info.forEach((element) => {
          if (element.data && element.data.church_type || element.data.church_type._id) {
            if (element.data.church_type._id === this.organization_user_obj.user_type_id) {
              if (element.data._id === this.organization_user_obj.church_name_type) {
                this.matched_data.push(element);
              }
            }
          }
        });
    
        this.table_info = this.matched_data;
      }
    }
    
  }
  

  

  getChurchTypeFilters(event) {

    return new Promise((resolve, reject) => {
      this.organizationManagementService.getAllOrganization().subscribe(
        (response: any) => {
          this.organizations_list = response.data.data.map((d) => {
            return {
              _id: d._id,
              text: d.organization_name,
              organization_name: d.organization_name,
              is_active: d.is_active,
              church_type: d.church_type,
              filter:event
            };
          });

          // Filter the organizations_list based on the church type ID

          this.organizations_list = this.organizations_list;
          console.log('this.organizations_list',this.organizations_list)
          return resolve({});
        },
        (err) => {
          this.commonHelper.showError(err);
          return resolve({});
        }
      );
    });
  }




  getChurchTypeFilterss(event) {
    this.table_info = this.table_info.filter((element) => {
      return (
        element.data.church_type._id ===
          this.organization_user_obj.user_type_id &&
        this.organization_user_obj.church_name_type === element.data._id
      );
    });
  }

  getChurchType() {
    this.churchUserTypesService.getAllChurchUserType().subscribe((res: any) => {
      this.loading = false;
      if (res.status == 200 && res.data) {
        this.churchtype = res.data.data;
      }
    });
  }




  
}
