import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseModalComponent } from '../base-modal.component';
import { AuthenticationService } from '../../../components/common/services/authentication.service';
import { ToastMessageService } from '../../../components/common/toast-message/toast-message.service';
import { CommonHelper } from '../../../components/common/helpers/common.helper';

declare var $ : any

@Component({
  selector: 'change-password-modal',
  templateUrl: './change-password-modal.template.html',
  styleUrls: ['./change-password-modal.component.css']
})

export class ChangePasswordModalComponent extends BaseModalComponent implements OnInit {
  decision: string = '';
  isDoneBtnDisabled: boolean = false;
  old_pwd: string = "";
  new_pwd: string = "";
  confirm_pwd: string = "";
  constructor(public modalRef: BsModalRef,public authService:AuthenticationService,public _toastMessageService: ToastMessageService,
    public commonHelper:CommonHelper) {
    super(modalRef);
  }

  ngOnInit() {
  }

  onCancel() {
    this.decision='cancel';
    this.close(true);
  }

  submit(){
    if(!this.old_pwd || !this.new_pwd.trim() || !this.confirm_pwd.trim()) {
      this._toastMessageService.alert("error","Please add all data");
      return;
    } else if(this.new_pwd.trim() != this.confirm_pwd.trim()) {
      this._toastMessageService.alert("error","New Password and Confirm Passoword not matched");
      return;
    }

    this.isDoneBtnDisabled = true;
    let sendData = {
        old_password:this.old_pwd,
        new_password:this.new_pwd
    }

    this.authService.changePassword(sendData)
        .subscribe((response:any) =>{
        if(response.data){
          this._toastMessageService.alert("success","password updated successfully");          
        }
        this.isDoneBtnDisabled = false;
        this.done();
    },err => {
        this.commonHelper.showError(err);
        this.isDoneBtnDisabled = false;
    });
  }

 
  done(){
    this.decision='done';
    this.close(true);
  }
}