import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../components/common/services/authentication.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonHelper } from '../../components/common/helpers/common.helper';
import { ToastMessageService } from '../../components/common/toast-message/toast-message.service';

@Component({
  selector: 'login',
  templateUrl: 'login.template.html',
  styleUrls: ['./login.component.css'],
})
export class loginComponent implements OnInit {
  public errorMsg = '';
  public user = { email: '', password: '' };
  public resetEmail = '';
  public isLoginDisabled: boolean = false;
  public isRPBtnDisabled: boolean = false;
  public step: string = 'login';
  show = false;
  show_eye: Boolean = false;
  passType: string = 'password';

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private commonHelper: CommonHelper,
    private _toastMessageService: ToastMessageService
  ) {}

  ngOnInit() {
    let role = localStorage.getItem('role');
    if (localStorage.getItem('x_auth_token')) {
      if (role == 'SUPER_USER') {
        this.router.navigate(['/organization-management']);
      }
    }
  }

  login() {
    this.isLoginDisabled = true;
    this.authService.userLogin(this.user).subscribe(
      (response: any) => {
        if (
          response.status == 200 &&
          response.data &&
          response.data.x_auth_token &&
          response.data.user_id
        ) {
          localStorage.x_auth_token = response.data.x_auth_token;
          localStorage.user_id = response.data.user_id;
          localStorage.organization_id = response.data.organization_id;
          localStorage.firstName = response.data.first_name;
          localStorage.lastName = response.data.last_name;
          localStorage.organization_name = response.data.organization_name;
          //   localStorage.management_name = response.data.management_name;
          if (response.data.user_type == 'MASTER_USER') {
            this.router.navigate(['/organization-management']);
          } else if (response.data.role == 'QUESTION_MANAGER') {
            this.router.navigate(['/technology-management']);
          } else if (response.data.role == 'COORDINATOR') {
            this.router.navigate(['/coordinator-interview']);
          }
        }

        this.isLoginDisabled = false;
      },
      (err) => {
        this.commonHelper.showError(err);
        this.isLoginDisabled = false;
      }
    );
  }

  onChangeStep(tStep) {
    this.user = { email: '', password: '' };
    this.resetEmail = '';
    this.step = tStep;
  }

  forgotPassword() {
    if (!this.resetEmail) {
      this._toastMessageService.alert('error', 'please enter email');
    }

    this.isRPBtnDisabled = true;
    let data = { email: this.resetEmail.trim().toLowerCase() };
    this.authService.forgotPassword(data).subscribe(
      (res: any) => {
        this.isRPBtnDisabled = false;
        if (res.status == 200) {
          this._toastMessageService.alert(
            'success',
            'We have sent a reset password link to your email. Didn’t receive the email? Check email address again or look in your spam folder.'
          );
          this.onChangeStep('login');
        }
      },
      (error) => {
        this.isRPBtnDisabled = false;
        this.commonHelper.showError(error);
      }
    );
  }
  ShowHide(value) {
    if (value == 'password') {
      this.passType = 'text';
      this.show = true;
    } else {
      this.passType = 'password';
      this.show = false;
    }
  }
}
