import { Pipe, PipeTransform,NgModule } from '@angular/core';

@Pipe({name: 'datepipe'})
export class DatePipe implements PipeTransform {
	transform(item: any, filter: string): any {
		let day, month, month_names, year,hours,minutes,seconds;
		month_names = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		if(filter != 'string') {
			item = new Date(item);
			day = item.getDate();
			month = item.getMonth();
			year = item.getFullYear().toString();
			hours = item.getHours()
			hours = (hours<10) ? "0"+hours : hours;
			minutes = item.getMinutes()
			minutes = (minutes<10) ? "0"+minutes : minutes;
			seconds = item.getSeconds()
			seconds = (seconds<10) ? "0"+seconds : seconds;
		}
		if(filter == 'short') {
			return day + ' ' + month_names[month] + ' ' + '`' + year.slice(year.length - 2, year.length);
		} else if(filter == 'no-year') {
			return day + ' ' + month_names[month];
		} else if(filter == 'on-month') {
			return ' ' + month_names[month] + ' ';
		} else if(filter == 'on-year') {
			return ' ' + year + ' ';
		} else if(filter == 'string') {
			return item;
		} else if( filter == 'full datetime') {
			return  (month+1) + '/' + day + '/' + year + ' '+ (((parseInt(hours) %  12) == 0) ? "12" : (parseInt(hours)%12)) +':'+minutes+ ((parseInt(hours)>= 12) ? ' PM':'AM');
		} else {
			return day + ' ' + month_names[month] + ' ' + year ;
		}
	}
}

@NgModule({
  declarations: [ DatePipe ],
  exports: [ DatePipe ]
})
export class DatePipeModule {
	constructor() {    	
  	}  	
}