import { Routes } from '@angular/router';

import { loginComponent } from './views/login/login.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { OrganizationMasterComponent } from './views/organization-master/organization-master.component';
import { blankComponent } from './components/common/layouts/blank.component';
import { mainComponent } from './components/common/layouts/main.component';
import { ChurchUserTypeManagementComponent } from './views/church-user-type/church-user-type-management/church-user-type-management.component';

import { PastorateDetailsComponent } from './views/pastorate-details/pastorate-details.component';

export const ROUTES: Routes = [
  // Main redirect
  { path: '', redirectTo: 'login', pathMatch: 'full' },

  // App views
  {
    path: '',
    component: blankComponent,
    children: [
      { path: 'login', component: loginComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
    ],
  },
  {
    path: '',
    component: mainComponent,
    children: [
      {
        path: 'organization-management',
        component: OrganizationMasterComponent,
      },
    ],
  },
  {
    path: '',
    component: mainComponent,
    children: [
      {
        path: 'church_user_type',
        component: ChurchUserTypeManagementComponent,
      },
    ],
  },


  {
    path: '',
    component: mainComponent,
    children: [
      {
        path: 'pastorate-Details',
        component: PastorateDetailsComponent,
      },
    ],
  },

  // Handle all other routes
  { path: '**', component: loginComponent },
];
