import { Component, OnInit, Input, Output, EventEmitter,ElementRef } from '@angular/core';
import * as _ from 'lodash';
declare var $:any;
@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.css']
})
export class MultiSelectComponent implements OnInit {

	@Input('label') label: string;
	@Input('type') type: string;
	@Input('name') name: string;
  @Input('value') value: any;
	@Input('disabled') disabled: boolean = false;
	@Input('placeholder') placeholder: string;
  @Input('option_list') option_list: any;
  @Input('isRequired') isRequired: boolean = false;
  @Input('isSubmitted') isSubmitted: boolean = false;

  @Output() onChange = new EventEmitter<string>();

	value_list: any[] = [];
  temp_option_list: any[] = [];
  showDropDown:boolean = false;
  filter_text: any = "";

  constructor(private elRef: ElementRef) { }

  ngOnInit() {
    var self = this;
    setTimeout(()=>{
      self.setInitialData();
    },1000);
     
    setTimeout(() => {
      this.temp_option_list = JSON.parse(JSON.stringify(this.option_list));
    },400);

    $(document).click(function(event){
        var $trigger = $(".multi-select-dropdown-main-wrapper");
        if($trigger !== event.target && !$trigger.has(event.target).length){
          self.onClickDropDownInput(false);
        }            
    });
  }

  onClickDropDownInput(isShow) {  
    //alert("is - "+isShow);
    this.showDropDown = isShow;      
    $(this.elRef.nativeElement.parentElement).find("#drop_down_wrapper").css("display",(isShow) ? "block" : "none");
  }

  applyFilter() {
    let tempList = (!this.filter_text) ? this.option_list : this.option_list.filter(ol => { return (ol.text.toLowerCase().indexOf(this.filter_text.toLowerCase()) != -1)});
    this.temp_option_list = JSON.parse(JSON.stringify(tempList))
  }

  removeSelection(val) {
    if(val._id && val.text && this.value) {
      let findIndex = _.findIndex(this.value, v => { return v._id == val._id})
      if(findIndex != -1) {
        this.value.splice(findIndex,1);
        this.sendData();
      }
    }    
  }

  onPageClick(event) {
    var $trigger = $(".multi-select-dropdown-main-wrapper");
    if($trigger !== event.target && !$trigger.has(event.target).length){      
      this.onClickDropDownInput(false);
    }
  }

  setInitialData(){
    if(Array.isArray(this.value)){
      this.value.forEach(i => {
        if(i.text) {
          let fData = this.value_list.filter(it => it._id == i._id)

          if(fData.length == 0){
            this.value_list.push({text:i.text,_id:i._id});            
          }
        }
      })
    }

    this.onChangeSelection({text:'',id:''},null);    
    /*if(this.type == "scene_mood_selection") {
      $(".un-select-list").css({"position":"unset"});
    }*/
  }

  validate(item){
    let fData = this.value.filter(it => it._id == item._id)

    if(fData.length == 0){
      return false;
    }else{
      return true;
    }
  }

  ngOnChanges(changeRecord: any) {    
    if(changeRecord && changeRecord.value && changeRecord.value.currentValue && changeRecord.value.currentValue.length == 0){
      this.value_list = [];
    } else if(changeRecord && changeRecord.value && changeRecord.value.currentValue && changeRecord.value.currentValue.length != 0){
      this.value_list = changeRecord.value.currentValue.map(v => { return {_id:v._id,text:v.text};});
    }
  }

  onChangeSelection(option, index) {
    if(option._id && option.text && this.value) {
      let findIndex = _.findIndex(this.value, v => { return v._id == option._id})
      if(findIndex == -1) {
        this.value.push(option);
      } else {
        this.value.splice(findIndex,1);
      }
    }
    return this.value;
  }

  sendData() {
    let sendData = {
      value : this.value
    }

    this.onChange.emit(JSON.stringify(sendData));
  }

}
