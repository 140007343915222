
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { ActivatedRoute } from '@angular/router';
import { CommonHelper } from "../../components/common/helpers/common.helper";
import { LoaderService } from "../loader.service";
import { OrganizationManagementService } from "../../components/common/services/organization_management.service";
interface OrgData {
  _id: string;
  organization_name: string;
  parent_orgnization?: ParentOrgnization;
  children: OrgData[];
}

interface ParentOrgnization {
  _id: string;
  organization_name: string;
}
interface OrgNode {
  data: OrgData;
  children: OrgNode[];
}
@Component({
  selector: "organization-master",
  providers: [],
  templateUrl: "organization-master.template.html",
  styleUrls: ["./organization-master.component.css"],
})
export class OrganizationMasterComponent implements OnInit {
  public isLoaderShow = false;
  public current_selected_menu_type: any = "organization info";

  public table_data: any[] = [];
  public total_table_records = 0;

  public loading: boolean = false;
  public recordLimit: number = 10;

  public pages: number[] = [];
  public activePageNumber: number = 0;
  public iteration: number;
   searchQuery ='';
  public filters: any = {};
  public selectedLimit: any = 10;
  public sort_by: any = {};
  public current_sort_type: any = "";

  public default_modules_list = [];
  public country_list = [];
  public state_list = [];
  mydata: any;
  default_organizations_list: OrgNode[];
  constructor(
    private router: Router,
    private commonHelper: CommonHelper,
    private _loaderService: LoaderService,
    private organizationManagementService: OrganizationManagementService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    if (!localStorage.getItem("x_auth_token")) {
      this.router.navigate(["/login"]);
    }
    this.activeTab("organization info", true);
    this.getTableData(event, 1);
  }


  reloadCurrentRoute() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    const currentUrl = this.router.url + '?';
    this.router.navigateByUrl(currentUrl).then(() => {
      this.router.navigated = false;
      this.router.navigate([this.activatedRoute.snapshot.url.join('/')]);
    });
  }

  
  activeTab(type, isFirstCall) {
    if (isFirstCall || this.current_selected_menu_type != type) {
      this.resetData();
      this.current_selected_menu_type = type;
      if (type == "organization user") {
        this.filters = { name: "", organizations: [], createdby: "all" };
      }
      this.getTableData(1, type);
    }
  }

  resetData() {
    this.table_data = [];
    this.filters = {};
    this.sort_by = {};
    this.current_sort_type = "";
  }

  

  getTableData(page, type,searchTerm?) {
    console.log('this.filters',this.filters)
    console.log('type',type)
    this.isLoaderShow = true;
    var params = {
      filters: this.filters,
      limit: this.recordLimit,
      page: page,
    };

    if (searchTerm) {
      params.filters.organization_name = searchTerm;
    }
    if (this.current_sort_type) {
      params["sort_by"] = {};
      params["sort_by"][this.current_sort_type] = this.sort_by[
        this.current_sort_type
      ]
        ? 1
        : -1;
    }

    this.activePageNumber = page;
    if (type == "organization info") {
      this.organizationManagementService.getOrganization(params).subscribe(
        (response: any) => {
          this.table_data = [];
          response.data.data.forEach((ho, index) => {
            ho.index = index + 1 + params.limit * (params.page - 1);
            ho.organization_logo_url = ho.organization_logo_url
              ? environment.url +
                ho.organization_logo_url +
                "?t=" +
                new Date().getTime()
              : "";
          });
          this.mydata = response.data.data;
          const orgData: OrgData[] = this.mydata;      
          function buildOrgHierarchy(orgData: OrgData[]): OrgNode[] {
          const orgMap = new Map<string, OrgNode>();
          orgData.forEach((org: OrgData) => {
          const orgNode: OrgNode = { data: org, children: [] };
          orgMap.set(org._id, orgNode);
          });

          const roots: OrgNode[] = [];

          orgData.forEach((org: OrgData) => {
          const parentOrg = org.parent_orgnization;
          if (parentOrg) {
            const parentNode = orgMap.get(parentOrg._id);
            if (parentNode) {
              parentNode.children.push(orgMap.get(org._id)!);
            }
          } else {
            roots.push(orgMap.get(org._id)!);
          }
          });
          return roots;
          }           
        this.table_data = buildOrgHierarchy(orgData);

//   return resolve({});
        },
        //   this.table_data = JSON.parse(JSON.stringify(response.data.data));
        //   this.total_table_records = response.data.total_record_count;
        //   this.onChangePage(page, 0);
        //   this.isLoaderShow = false;
        //this._loaderService.changeLoaderStatus(false);
        // },
        (err) => {
          this.commonHelper.showError(err);
          this.isLoaderShow = false;
          //this._loaderService.changeLoaderStatus(false);
        }
      );
    } else if (type == "organization user") {
      this.organizationManagementService.getOrganizationUser(params).subscribe(
        (response: any) => {
          this.table_data = [];
          response.data.data.forEach((ho_user, index) => {
            ho_user.middle_name = ho_user.middle_name
              ? ho_user.middle_name
              : "";
            ho_user.phone_number = ho_user.phone_number
              ? ho_user.phone_number.trim().replace(/\D/g, "")
              : "";
            ho_user.organization_logo_url = ho_user.organization_logo_url
              ? environment.url +
                ho_user.organization_logo_url +
                "?t=" +
                new Date().getTime()
              : "";
            ho_user.index = index + 1 + params.limit * (params.page - 1);
          });
          this.table_data = JSON.parse(JSON.stringify(response.data.data));
          this.total_table_records = response.data.total_record_count;
          this.onChangePage(page, 0);
          this.isLoaderShow = false;
          //this._loaderService.changeLoaderStatus(false);
        },
        (err) => {
          this.commonHelper.showError(err);
          this.isLoaderShow = false;
          //this._loaderService.changeLoaderStatus(false);
        }
      );
    } else {
      //this._loaderService.changeLoaderStatus(false);
      this.isLoaderShow = false;
    }
  }

  
 onSearch(event,type) {
        if(type== "organization info") {
          console.log(event,type,'lejjj')
            if(event && event.name || event.organization_name) {
              console.log(event,type,'lejjj')
              this.filters = event;
              this.getTableData(1,type);
            } else {
              this.filters = {};
              this.getTableData(1,type);
            }
        } else if(type == "organization user") {
          console.log(event,type,'lejjj')
            if(event && (event.name || event.organizations.length > 0 || event.createdby)) {            
              this.filters = event;
              this.getTableData(1,type);
            } else {                
              this.filters = {name:"",organizations:[],createdby:"all"};
              this.getTableData(1,type);
            }
        } 
    }

  calcNumberOfPage() {
    this.iteration =
      this.recordLimit == 0 ? 0 : this.total_table_records / this.recordLimit;
    if (this.iteration < 0) {
      this.iteration = 0;
    } else if (this.iteration > Number(this.iteration.toFixed())) {
      this.iteration = Number(this.iteration.toFixed()) + 1;
    } else {
      this.iteration = Number(this.iteration.toFixed());
    }
  }

  onChangePage(page, isForceChange) {
    this.calcNumberOfPage();
    this.pages = [];
    for (var i = 1; i <= this.iteration; i++) {
      this.pages[i - 1] = i;
    }
    if (page != this.activePageNumber || isForceChange) {
      this.getTableData(page, this.current_selected_menu_type);
    }
  }

  queryLimitChange(value) {
    if (value == "ALL") {
      this.recordLimit = 0;
    } else {
      this.recordLimit = parseInt(value);
    }
    this.onChangePage(1, 1);
  }

  changePage(val) {
    var tempPageNumber = this.activePageNumber + Number(val);
    this.calcNumberOfPage();
    if (tempPageNumber == -1) {
      tempPageNumber = 0;
    }
    if (tempPageNumber > this.iteration) {
      tempPageNumber = this.iteration;
    }
    this.onChangePage(tempPageNumber, 0);
  }

  showPages(index) {
    if (
      index > this.activePageNumber - 5 &&
      this.activePageNumber + 4 > index
    ) {
      return true;
    }
  }
}
