import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class ChurchUserTypeService {
  constructor(private http: HttpClient) {}

  getHeader(): HttpHeaders {
    let headers = new HttpHeaders({
      'x-auth-token': localStorage.getItem('x_auth_token') || '',
    });
    return headers;
  }


  getAChurchTypes() {
    return this.http.get(
      environment.url +
        'api/user/church_user_type_management/get_all_church_types',
      { headers: this.getHeader() }
    );
  }


  

  /*Church User Type*/
  getAllChurchUserType() {
    return this.http.get(
      environment.url +
        'api/user/church_user_type_management/get_all_church_user_types',
      { headers: this.getHeader() }
    );
  }

  getChurchUserType(data) {
    return this.http.post(
      environment.url +
        'api/user/church_user_type_management/get_church_user_types',
      data,
      { headers: this.getHeader() }
    );
  }

  addChurchUserType(data) {
    return this.http.post(
      environment.url +
        'api/user/church_user_type_management/add_church_user_type',
      data,
      { headers: this.getHeader() }
    );
  }

  updateChurchUserType(church_user_type_id, data) {
    return this.http.patch(
      environment.url +
        'api/user/church_user_type_management/update_church_user_type/' +
        church_user_type_id,
      data,
      { headers: this.getHeader() }
    );
  }

  removeChurchUserType(church_user_type_id, data) {
    return this.http.patch(
      environment.url +
        'api/user/church_user_type_management/remove_church_user_type/' +
        church_user_type_id,
      data,
      { headers: this.getHeader() }
    );
  }
}
