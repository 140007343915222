import { Component, OnInit, EventEmitter, HostListener, Output, Input } from '@angular/core';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit {

	@Input('upload') upload: boolean;
	@Input('file_element_id') file_element_id: string = "";

	@Output() fileDropped = new EventEmitter<FileList>();
	@Output() fileUpload = new EventEmitter();

  	constructor() { }

	ngOnInit() {}

    getBase64Data(file) {
	  return new Promise((resolve, reject) => {
	    const reader = new FileReader();
	    reader.readAsDataURL(file);
	    reader.onload = () => resolve(reader.result);
	    reader.onerror = error => reject(error);
	  });
	}	
	
  	@HostListener('drop', ['$event'])
		onDrop($event) {
			$event.preventDefault();
			
			let transfer = $event.dataTransfer;
			this.fileDropped.emit(transfer.files);
			this.getBase64Data($event.target.files[0]).then(result => {
				this.fileUpload.emit(result);							
			})
			.catch(error => {
				this.upload = false;
				console.log(error);
			})
		}

	@HostListener('dragover', ['$event'])
		onDragOver($event) {
			$event.preventDefault();
			/*this.fileUpload.emit(this.upload);		*/
		}

/*	@HostListener('dragleave', ['$event'])
		onDragLeave($event) {
		}
*/
	@HostListener('click', ['$event'])
		onClick($event) {
			console.log("file click");
			/*this.fileUpload.emit(this.upload);			*/
		}

	@HostListener('change', ['$event'])
		onChange($event) {
			$event.preventDefault();
			this.fileDropped.emit($event.srcElement.file);
			this.getBase64Data($event.target.files[0]).then(result => {
				this.fileUpload.emit(result);							
			})
			.catch(error => {
				console.log(error);
			})
		}
}
