import { Component, OnInit } from '@angular/core';
import { CommonHelper } from 'app/components/common/helpers/common.helper';
import { ChurchUserTypeService } from 'app/components/common/services/church-user-type.service';
import { ToastMessageService } from 'app/components/common/toast-message/toast-message.service';
import { BaseModalComponent } from 'app/views/modals/base-modal.component';
import { environment } from 'environments/environment';
import moment = require('moment');
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-add-update-church-user-type',
  templateUrl: './add-update-church-user-type.component.html',
  styleUrls: ['./add-update-church-user-type.component.css'],
})
export class AddUpdateChurchUserTypeComponent
  extends BaseModalComponent
  implements OnInit
{
  decision: string = '';
  permissionDropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  passType: string = 'password';
  show = false;
  institute_obj: any = {
    _id: null,
    church_user_type_name: null,
    is_active: true,
  };
  countrycodelist: any;
  isDoneBtnDisabled: boolean = false;
  response_data: any = null;
  submitted: boolean = false;
  default_modules_list: any = [];
  selected_institute_modules: any = [];

  public tzNames: string[];
  public selectedTz: string;
  public utcDate: moment.Moment;

  public tzDate: moment.Moment;
  showErrormsg: any;

  institute_logo_data: any = '';
  time_zones = moment.tz.names();

  constructor(
    public modalRef: BsModalRef,
    public _toastMessageService: ToastMessageService,
    public commonHelper: CommonHelper,
    public churchUserTypeService: ChurchUserTypeService
  ) {
    super(modalRef);
    this.tzNames = moment.tz.names();
    // this.timeZoneChanged('America/New_York');
  }
  public timeZoneChanged(timeZone: string): void {
    this.selectedTz = timeZone;

    this.updateTime(timeZone);
  }

  private updateTime(timeZone: string) {
    const currentTime = new Date().getTime();

    this.utcDate = moment(currentTime).utc();

    this.tzDate = moment(currentTime).tz(timeZone);
  }

  ngOnInit() {}

  onCancel() {
    this.decision = 'cancel';
    this.close(true);
  }

  submit() {
    if (
      !this.institute_obj.church_user_type_name ||
      !this.institute_obj.church_user_type_name.trim()
    ) {
      this._toastMessageService.alert('error', 'Please add institute name');
      return;
    }

    let sData = JSON.parse(JSON.stringify(this.institute_obj));

    if (!this.institute_obj._id) {
      //add new institute
      this.isDoneBtnDisabled = true;
      delete sData._id;
      this.churchUserTypeService.addChurchUserType(sData).subscribe(
        (response: any) => {
          this.response_data = response.data;

          this._toastMessageService.alert(
            'success',
            'Church type data added successfully.'
          );
          this.isDoneBtnDisabled = false;
          this.done();
        },

        (err) => {
          this.commonHelper.showError(err);
          this.isDoneBtnDisabled = false;
        }
      );
    } else {
      //update institute
      this.isDoneBtnDisabled = true;
      this.churchUserTypeService
        .updateChurchUserType(this.institute_obj._id, sData)
        .subscribe(
          (response: any) => {
            this._toastMessageService.alert(
              'success',
              'Church Type data updated successfully.'
            );
            this.response_data = response.data;

            this.isDoneBtnDisabled = false;
            this.done();
          },
          (err) => {
            this.commonHelper.showError(err);
            this.isDoneBtnDisabled = false;
          }
        );
    }
  }

  done() {
    this.decision = 'done';
    this.close(true);
  }

  ShowHide(value) {
    if (value == 'password') {
      this.passType = 'text';
      this.show = true;
    } else {
      this.passType = 'password';
      this.show = false;
    }
  }
}
