import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams} from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';


@Injectable()
export class AdminService{

    constructor(private http: HttpClient){}

    getHeader():HttpHeaders {            
      let headers = new HttpHeaders({'x-auth-token': (localStorage.getItem('x_auth_token') || "")});
      return headers;
    }
}