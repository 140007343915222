import { Component,Input } from '@angular/core';

@Component({
    selector: 'loader',
    templateUrl: 'loader.template.html',
    styleUrls: ['./loader.component.css']
})
export class LoaderComponent {
	@Input() isLoaderShow: boolean = false;

	constructor(){}

    ngOnInit() {}
}
