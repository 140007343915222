import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoaderService } from '../../../views/loader.service';
import { ChangePasswordModalComponent } from '../../../views/modals/change-password-modal/change-password-modal.component';
import { AuthenticationService } from '../services/authentication.service';
import { BsModalService,BsModalRef } from 'ngx-bootstrap/modal';
import { BaseModalComponent } from '../../../views/modals/base-modal.component';
import { environment } from '../../../../environments/environment';


declare var $:any;

@Component({
    selector: 'topnavbar',
    templateUrl: 'topnavbar.template.html',
    styleUrls: ['./topnavbar.component.css'],
    providers:[AuthenticationService],

})
export class TopnavbarComponent {

    public current_password:any
    public new_password:any
    public repeat_new_password:any
    public change_password_err:string = "";
    public page_title:string = "";
    public isChangePaddwordDisabled:boolean = false;
    public subscription: Subscription;
    public modalRef: BsModalRef;
    public role: string = "";
    public first_name: string = "";
    public last_name: string = "";
    public env:any = (environment.production) ? "Production" : "Development";
	constructor(private authService: AuthenticationService,
        private modalService:BsModalService, private router: Router,
        private _loaderService:LoaderService,
       ){
        
    }

    ngOnInit() {        
        this.first_name = localStorage.getItem('firstName');
        this.last_name = localStorage.getItem('lastName');
    }

    toggleSideMenu() {
        if($("#menu_wrapper_id").css("width") == "0px") {
            $("#menu_wrapper_id").css("width","220px");
            $("#page-wrapper").css("margin","0 0 0 220px");
        } else{ 
            $("#menu_wrapper_id").css("width","0px");
            $("#page-wrapper").css("margin","0px");
        }        
    }

    logout() {
        
        this._loaderService.changeLoaderStatus(true);

        this.authService.userLogout()
            .subscribe(response =>{
                if(response){
                    localStorage.clear();                    
                    this.router.navigateByUrl('/login');
                }
                this._loaderService.changeLoaderStatus(false);
            },err =>{
                localStorage.clear();
                this.router.navigateByUrl('/login');            
                this._loaderService.changeLoaderStatus(false);
            });
    }

    

    changePassword() {      
      this.modalRef = this.modalService.show(ChangePasswordModalComponent, {class: 'common-small-modal',backdrop: 'static', keyboard: false});
      this.modalRef.content.decision = "";
      var currSub:Subscription = this.modalRef.content.onHide.subscribe((result: boolean) => {
        currSub.unsubscribe();
        if (this.modalRef.content.decision === 'done') {         
        }
      });
    }

    

}
