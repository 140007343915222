import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class PastorateDetailService {
  constructor(private http: HttpClient) {}

  getHeader(): HttpHeaders {
    let headers = new HttpHeaders({
      'x-auth-token': localStorage.getItem('x_auth_token') || '',
    });
    return headers;
  }


  getAChurchTypes() {
    return this.http.get(
      environment.url +
        'api/user/church_user_type_management/get_all_church_types',
      { headers: this.getHeader() }
    );
  }


  

  /*Church User Type*/
  getAllChurchUserType() {
    return this.http.get(
      environment.url +
        'api/user/church_user_type_management/get_all_church_user_types',
      { headers: this.getHeader() }
    );
  }

  getAllPastorateType(data) {
    return this.http.post(
      environment.url +
        'api/user/pastorate_details/get_all_pastorate_details',
      data,
      { headers: this.getHeader() }
    );
  }

  addPastorateDetail(data) {
    return this.http.post(
      environment.url +
        'api/user/pastorate_details/add_pastorate_details',
      data,
      { headers: this.getHeader() }
    );
  }

  updatePastorateDetail(church_user_type_id, data) {
    return this.http.patch(
      environment.url +
        'api/user/pastorate_details/update_pastorate_details/' +
        church_user_type_id,
      data,
      { headers: this.getHeader() }
    );
  }

  removePastorateDetail(church_user_type_id, data) {
    return this.http.patch(
      environment.url +
        'api/user/pastorate_details/remove_pastorate_details/' +
        church_user_type_id,
      data,
      { headers: this.getHeader() }
    );
  }
}
