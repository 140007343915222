import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable,Subject } from 'rxjs';

@Injectable()
export class LoaderService {
    private subject = new Subject<boolean>();
    private keepAfterRouteChange = false;

    constructor(private router: Router) {
    }

    getLoaderFlag(): Observable<boolean> {
        return this.subject.asObservable();
    }

    changeLoaderStatus(isLoaderShow:boolean) {
        this.subject.next(<boolean>isLoaderShow);
    }
}