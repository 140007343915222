import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonHelper } from "app/components/common/helpers/common.helper";
import { ChurchUserTypeService } from "app/components/common/services/church-user-type.service";
import { ToastMessageService } from "app/components/common/toast-message/toast-message.service";
import { LoaderService } from "app/views/loader.service";
import { BaseModalComponent } from "app/views/modals/base-modal.component";
import { ConfirmationModalComponent } from "app/views/modals/confirmation-modal/confirmation-modal.component";
import { AddUpdatePastorateDetailsComponent } from "app/views/pastorate-details/add-update-pastorate-details/add-update-pastorate-details.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { Subscription } from "rxjs";
import { PastorateDetailService } from "app/components/common/services/pastorate-details.service";
@Component({
  selector: "app-pastorate-details-management",
  templateUrl: "./pastorate-details-management.component.html",
  styleUrls: ["./pastorate-details-management.component.css"],
})
export class PastorateDetailsManagementComponent
  extends BaseModalComponent
  implements OnInit
{
  @Input("table_info") table_info: any;

  @Input("default_modules_list") default_modules_list: any = [];

  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  @Output() onLoaderStatusChange: EventEmitter<any> = new EventEmitter();
  default_roles_list: any = [];
  public isLoaderShow = false;
  public total_table_records = 0;
  public table_data: any[] = [];

  public pastorate_details: any[] = [];
  pastorate_union_id: any;
  public filters: any = {};
  public recordLimit: number = 10;
  search_text: any = "";
  constructor(
    private router: Router,
    private commonHelper: CommonHelper,
    private _loaderService: LoaderService,
    private churchUserTypeService: ChurchUserTypeService,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    private _toastMessageService: ToastMessageService,
    public pastorateDetailService: PastorateDetailService
  ) {
    super(modalRef);
  }

  ngOnInit() {
    this.getPastorateUnionDetails(1);

    // this.getInstituteList();
  }

  getPastorateUnionDetails(page) {
    //this._loaderService.changeLoaderStatus(true);
    this.isLoaderShow = true;

    var params = {
      filters: this.filters,
      limit: this.recordLimit,
      page: page,
    };
    this.churchUserTypeService.getChurchUserType(params).subscribe(
      (response: any) => {
        this.pastorate_details = [];
        this.pastorate_details = JSON.parse(JSON.stringify(response.data.data));
        this.total_table_records = response.data.total_record_count;
        // this.onChangePage(page, 0);
        this.isLoaderShow = false;
        //this._loaderService.changeLoaderStatus(false);
      },
      (err) => {
        this.commonHelper.showError(err);
        this.isLoaderShow = false;
        //this._loaderService.changeLoaderStatus(false);
      }
    );

    //this._loaderService.changeLoaderStatus(false);
    this.isLoaderShow = false;
  }

  onClickAdd() {
    this.modalRef = this.modalService.show(AddUpdatePastorateDetailsComponent, {
      class: "organization-info-management-modal",
      backdrop: "static",
      keyboard: false,
    });
    this.modalRef.content.decision = "";
    this.modalRef.content.default_roles_list = this.default_roles_list;
    /*this.modalRef.content.country_list = this.country_list;
    this.modalRef.content.state_list = this.state_list;*/

    var currSub: Subscription = this.modalRef.content.onHide.subscribe(
      (result: boolean) => {
        currSub.unsubscribe();
        if (this.modalRef.content.decision === "done") {
          this.modalRef.content.response_data.index =
            this.table_info.length + 1;
          this.table_info.push(
            JSON.parse(JSON.stringify(this.modalRef.content.response_data))
          );
        }
      }
    );
  }

  onClickEdit(refData, index) {
    this.modalRef = this.modalService.show(AddUpdatePastorateDetailsComponent, {
      class: "organization-info-management-modal",
      backdrop: "static",
      keyboard: false,
    });
    this.modalRef.content.institute_obj = JSON.parse(JSON.stringify(refData));
    this.modalRef.content.default_roles_list = this.default_roles_list;
    var currSub: Subscription = this.modalRef.content.onHide.subscribe(
      (result: boolean) => {
        currSub.unsubscribe();
        if (this.modalRef.content.decision === "done") {
          this.table_info[index] = JSON.parse(
            JSON.stringify(this.modalRef.content.institute_obj)
          );
        }
      }
    );
  }

  onClickDelete(refData, index) {
    this.modalRef = this.modalService.show(ConfirmationModalComponent, {
      class: "common-small-modal",
      backdrop: "static",
      keyboard: false,
    });
    this.modalRef.content.decision = "";
    this.modalRef.content.step = 2;
    this.modalRef.content.confirmation_text =
      "Are you sure to delete church user type?";

    var currSub: Subscription = this.modalRef.content.onHide.subscribe(
      (result: boolean) => {
        currSub.unsubscribe();
        if (this.modalRef.content.decision === "done") {
          this.onLoaderStatusChange.emit(true);
          //this._loaderService.changeLoaderStatus(true);
          this.pastorateDetailService
            .removePastorateDetail(refData._id, {})
            .subscribe(
              (response: any) => {
                if (response.data) {
                  this._toastMessageService.alert(
                    "success",
                    "Pastorate Detail data deleted successfully."
                  );
                  this.table_info.splice(index, 1);
                }
                this.onLoaderStatusChange.emit(false);
                //this._loaderService.changeLoaderStatus(false);
              },
              (err) => {
                this.commonHelper.showError(err);
                this.onLoaderStatusChange.emit(false);
                //this._loaderService.changeLoaderStatus(false);
              }
            );
        }
      }
    );
  }

  searchOnTable() {
    this.onSearch.emit({
      pastorate_detail_name: this.search_text.trim(),
      pastorate_union_id: this.pastorate_union_id
    });
  }
  
  reloadCurrentRoute() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    const currentUrl = this.router.url + "?";
    this.router.navigateByUrl(currentUrl).then(() => {
      this.router.navigated = false;
      this.router.navigate([this.activatedRoute.snapshot.url.join("/")]);
    });
  }

  // searchOnTable() {
  //   const searchLower = this.search_text.trim().toLowerCase();
  //   const searchUpper = this.search_text.trim().toUpperCase();
  //   const pastorateUnion = this.pastorate_union_id;


  //   const filteredResults = this.table_info.filter(
  //     (x) =>
  //       (x.pastorate_detail_name.toLowerCase().includes(searchLower) ||
  //         x.pastorate_detail_name.toUpperCase().includes(searchUpper)) &&
  //       (pastorateUnion ? pastorateUnion === x.pastorate_union_id : true)
  //   );

  //   console.log('filteredResults',filteredResults)

  //   // Assuming you want to show all matching records
  //   if (filteredResults.length > 0) {
  //     console.log('inside the if',filteredResults)
  //     this.table_info = filteredResults;
  //   } else {
  //     this.table_info = filteredResults;
      
  //     console.log('inside the else',filteredResults)
  //     // If no match is found, you might want to reset the table_info to its original state
  //     // this.getpastorateunionList();
  //   }
  // }

  resetSearchOnTable() {
    this.search_text = "";
    this.pastorate_union_id = "";

    this.reloadCurrentRoute();

    // this.getpastorateunionList();
  }
}
