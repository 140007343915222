// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
    production: false,
  	tinyMCEKey: "w3imjvweuy4nb7lg",
		// url:'http://localhost:3002/',
		// aUrl:'http://localhost:3001/',
	//   url: 'http://38.242.236.229:9001/',
	     url: "https://church-demo-api.campusfloor.com/",
		 curl:"https://admin-demo-api.campusfloor.com/",
		//  curl : 'http://localhost:3002/',
       	// productUrl: 'http://localhost:3000/'
	  	// productUrl: "https://church-demo.campusfloor.com/",
		// aUrl: "https://admin-demo-api.campusfloor.com/",
		// url:"https://church-demo-api.campusfloor.com/",
	    // productUrl: "https://church-demo.campusfloor.com/"
  	/*url: 'http://142.93.200.33:3000/'*/
};
