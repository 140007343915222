import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../../../environments/environment';


@Injectable()
export class AuthenticationService {
	constructor(
		private http: HttpClient
	){}

  getHeader():HttpHeaders {
    let headers = new HttpHeaders({'x-auth-token': (localStorage.getItem('church_x_auth_token') || "")});
    return headers; 
  }

  userLogin(user){
		return this.http.post(environment.url+'api/user/login',user,{ headers: this.getHeader() });
  }

  forgotPassword(params){
    return this.http.post(environment.url+'api/admin/forgot_password',params,{ headers: this.getHeader() });
  }

  resetPassword(params){
    return this.http.post(environment.url+'api/admin/reset_password',params,{ headers: this.getHeader() });
  }

  changePassword(params) {
  	return this.http.patch(environment.url+'api/admin/super_admin_user/password',params,{ headers:this.getHeader() });
  }

  userLogout(){
  	return this.http.post(environment.url+'api/admin/super_admin_user/signout',{},{ headers: this.getHeader() });
  }  

  churchUserLogin(user){
		return this.http.post(environment.curl+'api/admin/login',user,{ headers: this.getHeader() });
  }
}