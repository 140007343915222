import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../components/common/services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonHelper } from '../../components/common/helpers/common.helper';
import { ToastMessageService } from '../../components/common/toast-message/toast-message.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',  
  styleUrls: ['./reset-password.component.css']
})

export class ResetPasswordComponent implements OnInit {
  password : any;
  confirmPassword : any;
  resetEmail : any;
  isRPDisabled : boolean = false;
  accessToken : any = "";
  
  constructor(private authService : AuthenticationService,private commonHelper: CommonHelper,
    private route: ActivatedRoute, private _toastMessageService: ToastMessageService, 
    private router : Router) {
      this.route.queryParams.subscribe(params => {
          if(params) {
            this.resetEmail = params["email"] ? params["email"] : "";
            this.accessToken = params["accessToken"] ? params["accessToken"] : "";
          }
      });
  }

  ngOnInit(): void {

  }

  resetPassword() {
    if(!this.resetEmail || !this.accessToken) {
      this._toastMessageService.alert("error","invalid link");
      return;
    } 

    if(this.password != this.confirmPassword) {
      this._toastMessageService.alert("error","password and confirm password not matched");      
      return;
    }

    this.isRPDisabled = true;
    let data = {
      email : this.resetEmail.trim().toLowerCase(),
      password : this.password,
      token : this.accessToken
    };
    this.authService.resetPassword(data).subscribe((res:any) => {
      this.isRPDisabled = false;
      if(res.status == 200) {
        this._toastMessageService.alert("success","password reset successfully.")
        this.router.navigate(['/login']);        
      }
    }, error => {
      this.isRPDisabled = false;
      this.commonHelper.showError(error);
    });
  }

  redirectToLogin() {
    this.router.navigate(['/login']);
  }
}
