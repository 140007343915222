import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonHelper } from 'app/components/common/helpers/common.helper';
import { ChurchUserTypeService } from 'app/components/common/services/church-user-type.service';
import { environment } from 'environments/environment';
import { LoaderService } from '../loader.service';

import { PastorateDetailService } from 'app/components/common/services/pastorate-details.service';

@Component({
  selector: 'app-pastorate-details',
  templateUrl: './pastorate-details.component.html',
  styleUrls: ['./pastorate-details.component.css'],
})
export class PastorateDetailsComponent implements OnInit {
  public isLoaderShow = false;
  public current_selected_menu_type: any = 'institute info';

  public table_data: any[] = [];
  public total_table_records = 0;

  public loading: boolean = false;
  public recordLimit: number = 10;

  public pages: number[] = [];
  public activePageNumber: number = 0;
  public iteration: number;

  public filters: any = {};
  
  public selectedLimit: any = 10;
  public sort_by: any = {};
  public current_sort_type: any = '';

  public default_modules_list = [];
  public country_list = [];
  public state_list = [];
  constructor(
    private router: Router,
    private commonHelper: CommonHelper,
    private _loaderService: LoaderService,
    private churchUserTypeService: ChurchUserTypeService,
    public pastorateDetailService: PastorateDetailService
  ) {}

  ngOnInit() {
    if (!localStorage.getItem('x_auth_token')) {
      this.router.navigate(['/login']);
    }
    this.getTableData(1);
  }

  activeTab(type, isFirstCall) {
    if (isFirstCall || this.current_selected_menu_type != type) {
      this.resetData();
      this.current_selected_menu_type = type;
      if (type == 'institute user') {
        this.filters = { name: '', institutes: [], createdby: 'all' };
      }
      this.getTableData(1);
    }
  }

  resetData() {
    this.table_data = [];
    this.filters = {};
    this.sort_by = {};
    this.current_sort_type = '';
  }

  getTableData(page) {
    //this._loaderService.changeLoaderStatus(true);
    this.isLoaderShow = true;

    var params = {
      filters: this.filters,
      limit: this.recordLimit,
      page: page,
    };


    if (this.current_sort_type) {
      params['sort_by'] = {};
      params['sort_by'][this.current_sort_type] = this.sort_by[
        this.current_sort_type
      ]
        ? 1
        : -1;
    }

    this.activePageNumber = page;
      this.pastorateDetailService.getAllPastorateType(params).subscribe(
        (response: any) => {
          this.table_data = [];
          response.data.data.forEach((ho, index) => {
            ho.index = index + 1 + params.limit * (params.page - 1);
          });
          this.table_data = JSON.parse(JSON.stringify(response.data.data));
          this.total_table_records = response.data.total_record_count;
          this.onChangePage(page, 0);
          this.isLoaderShow = false;
          //this._loaderService.changeLoaderStatus(false);
        },
        (err) => {
          this.commonHelper.showError(err);
          this.isLoaderShow = false;
          //this._loaderService.changeLoaderStatus(false);
        }
      );
    
  }

  onSearch(eventData: { pastorate_detail_name: string, pastorate_union_id: string }) {
      if (eventData.pastorate_union_id || eventData.pastorate_detail_name) {
        this.filters.pastorate_union_id = eventData.pastorate_union_id;
        this.filters.pastorate_detail = eventData.pastorate_detail_name;
        
        this.getTableData(1);
      } else {
        this.filters = {};
        this.getTableData(1);
      }
   
  }

  calcNumberOfPage() {
    this.iteration =
      this.recordLimit == 0 ? 0 : this.total_table_records / this.recordLimit;
    if (this.iteration < 0) {
      this.iteration = 0;
    } else if (this.iteration > Number(this.iteration.toFixed())) {
      this.iteration = Number(this.iteration.toFixed()) + 1;
    } else {
      this.iteration = Number(this.iteration.toFixed());
    }
  }

  onChangePage(page, isForceChange) {
    this.calcNumberOfPage();
    this.pages = [];
    for (var i = 1; i <= this.iteration; i++) {
      this.pages[i - 1] = i;
    }
    if (page != this.activePageNumber || isForceChange) {
      this.getTableData(page);
    }
  }

  queryLimitChange(value) {
    if (value == 'ALL') {
      this.recordLimit = 0;
    } else {
      this.recordLimit = parseInt(value);
    }
    this.onChangePage(1, 1);
  }

  changePage(val) {
    var tempPageNumber = this.activePageNumber + Number(val);
    this.calcNumberOfPage();
    if (tempPageNumber == -1) {
      tempPageNumber = 0;
    }
    if (tempPageNumber > this.iteration) {
      tempPageNumber = this.iteration;
    }
    this.onChangePage(tempPageNumber, 0);
  }

  showPages(index) {
    if (
      index > this.activePageNumber - 5 &&
      this.activePageNumber + 4 > index
    ) {
      return true;
    }
  }
}
