import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule}   from '@angular/forms';
import {ResetPasswordComponent} from "./reset-password.component";


@NgModule({
    declarations: [ResetPasswordComponent],
    imports     : [BrowserModule,FormsModule],
})

export class ResetPasswordModule {}