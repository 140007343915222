import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class OrganizationManagementService {
  authService: any;

  constructor(private http: HttpClient) {}
  


// Your Angular service
private importCsvUrl = environment.url + 'api/organization_management/import-csv';


private getHeader(): HttpHeaders {
  let headers = new HttpHeaders({
    'x-auth-token': localStorage.getItem('x_auth_token') || '',
  });
  return headers;
}


importCSV(formData: FormData): Observable<any> {
  // Get the headers
  const headers = this.getHeader();

  // Now, use these headers in your HTTP request
  return this.http.post<any>(this.importCsvUrl, formData, { headers });
}


  /*organization*/
  getAllOrganization() {
    return this.http.get(
      environment.url +
        'api/user/organization_management/get_all_organizations',
      { headers: this.getHeader() }
    );
  }

  getChurchOrg(params) {
    // console.log(params);
    return this.http.post(
      environment.url + 'api/user/organization_management/get_church_org',
      params,
      { headers: this.getHeader() }
    );
  }

  getOrganization(data) {
    return this.http.post(
      environment.url + 'api/user/organization_management/get_organizations',
      data,
      { headers: this.getHeader() }
    );
  }

  addOrganization(data) {
    return this.http.post(
      environment.url + 'api/user/organization_management/add_organization',
      data,
      { headers: this.getHeader() }
    );
  }

  updateOrganization(organization_id, data) {
    return this.http.patch(
      environment.url +
        'api/user/organization_management/update_organization/' +
        organization_id,
      data,
      { headers: this.getHeader() }
    );
  }

  updateOrganizationLogo(organization_id, data) {
    return this.http.patch(
      environment.url +
        'api/user/organization_management/update_organization_logo/' +
        organization_id,
      data,
      { headers: this.getHeader() }
    );
  }

  removeOrganization(organization_id, data) {
    return this.http.patch(
      environment.url +
        'api/user/organization_management/remove_organization/' +
        organization_id,
      data,
      { headers: this.getHeader() }
    );
  }

  /*organization user*/
  getOrganizationUser(data) {
    return this.http.post(
      environment.url +
        'api/user/organization_management/get_organization_users',
      data,
      { headers: this.getHeader() }
    );
  }

  addOrganizationUser(data) {
    // console.log(data, 'all data');
    return this.http.post(
      environment.url +
        'api/user/organization_management/add_organization_user',
      data,
      { headers: this.getHeader() }
    );
  }

  updateOrganizationUser(organization_user_id, data) {
    return this.http.patch(
      environment.url +
        'api/user/organization_management/update_organization_user/' +
        organization_user_id,
      data,
      { headers: this.getHeader() }
    );
  }

  removeOrganizationUser(organization_user_id, data) {
    return this.http.patch(
      environment.url +
        'api/user/organization_management/remove_organization_user/' +
        organization_user_id,
      data,
      { headers: this.getHeader() }
    );
  }

  updateOrganizationUserLogo(organization_id, data) {
    return this.http.patch(
      environment.url +
        'api/user/organization_management/update_organization_user_logo/' +
        organization_id,
      data,
      { headers: this.getHeader() }
    );
  }

  //check org user

  checkOrganizationUser(params) {
    return this.http.post(environment.url + 'api/check_user_name', params, {
      headers: this.getHeader(),
    });
  }

  checkUservalid(params) {
    return this.http.post(environment.url + 'api/check_church_name', params, {
      headers: this.getHeader(),
    });
  }
}
