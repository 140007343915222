import { Component } from '@angular/core';
import { LoaderService } from './views/loader.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
	  public isLoaderShow: boolean = false;
	
  	constructor(public _loaderService:LoaderService) {}

	  ngAfterViewInit() {
      setTimeout(()=> {
        this._loaderService.getLoaderFlag().subscribe((showLoader: boolean) => {        
          this.isLoaderShow = (showLoader) ? true : false;
        });      
      })
  	}       
}
