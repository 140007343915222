import { Component, HostListener, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { environment } from "../../../../../environments/environment";
import { BaseModalComponent } from "../../../modals/base-modal.component";
import { ToastMessageService } from "../../../../components/common/toast-message/toast-message.service";
import { CommonHelper } from "../../../../components/common/helpers/common.helper";
import { OrganizationManagementService } from "../../../../components/common/services/organization_management.service";
import { ChurchUserTypeService } from "../../../../components/common/services/church-user-type.service";
declare var $: any;
import * as moment from "moment";
import "moment-timezone";
import { DatePipe } from "@angular/common";
import { PastorateDetailService } from "app/components/common/services/pastorate-details.service";

@Component({
  selector: "add-update-organization-user-management-modal",
  templateUrl: "./add-update-organization-user-management-modal.template.html",
  styleUrls: ["./add-update-organization-user-management-modal.component.css"],
  providers: [DatePipe],
})
export class AddUpdateOrganizationUserManagementModalComponent
  extends BaseModalComponent
  implements OnInit
{
  dragAreaClass: string;
  formData = new FormData();
  mimeType = ["application/vnd.ms-excel", "application/json"];
  csvFileName: any;
  dragdropmsg: any;
  fatherLastName: any;
  table_data: any;
  fatherName: any;
  dialogResult: any;
  approveObj: any;
  cilModel: any;
  church_member_detail: boolean = true;
  church_member_user_detail: boolean = false;
  decision: string = "";
  users_detail: any = [];
  mockCsvData: string;
  pastorateDetail: any = [];
  FamilyDetails: any = [];
  filteredPastorateDetails: any = [];
  organization_user_obj: any = {
    _id: null,
    first_name: null,
    middle_name: null,
    last_name: "",
    employee_id: "",
    phone_number: "",
    email: "",
    father_name: "",
    mother_name: "",
    marriage_type: "",
    spouse_name: "",
    date_of_marriage: "",
    users_details: this.users_detail,
    family_details: this.FamilyDetails,
    address: "",
    aadhar_member_aadhar_no: "",
    date_of_death: "",
    pastorate_detail: "",
    is_active: true,
    username: null,
  }; /*,user_type:null*/
  isDoneBtnDisabled: boolean = false;
  organization_logo_data: any = "";
  response_data: any = null;
  public loading: boolean = false;
  submitted: boolean = false;
  parent_organization_id: any = "";
  public selectedTz: string;
  churchtype: any = [];
  show = false;

  default_church_type_list: any = [];
  filtered_organizations_list = [];
  public tzNames: string[];

  public utcDate: moment.Moment;
  public tzDate: moment.Moment;
  passType: string = "password";
  isUserExist: "";
  isUserAvailabe: "";
  chooselanguage: any = [
    {
      id: "en",
      name: "English",
    },
    {
      id: "fr",
      name: "French",
    },
    {
      id: "hi",
      name: "Hindi",
    },
  ];

  marriage_types: any = [
    { id: "married", name: "Married" },
    { id: "unmarried", name: "Unmarried" },
    { id: "divorce", name: "Divorce" },
    { id: "widower", name: "Widower" },
  ];

  default_organizations_list: any = [];
  demo: any;
  org_list: any = [];

  countryCode: any = [
    {
      name: "Afghanistan",
      dial_code: "+93",
      code: "AF",
    },
    {
      name: "Aland Islands",
      dial_code: "+358",
      code: "AX",
    },
    {
      name: "Albania",
      dial_code: "+355",
      code: "AL",
    },
    {
      name: "Algeria",
      dial_code: "+213",
      code: "DZ",
    },
    {
      name: "AmericanSamoa",
      dial_code: "+1684",
      code: "AS",
    },
    {
      name: "Andorra",
      dial_code: "+376",
      code: "AD",
    },
    {
      name: "Angola",
      dial_code: "+244",
      code: "AO",
    },
    {
      name: "Anguilla",
      dial_code: "+1264",
      code: "AI",
    },
    {
      name: "Antarctica",
      dial_code: "+672",
      code: "AQ",
    },
    {
      name: "Antigua and Barbuda",
      dial_code: "+1268",
      code: "AG",
    },
    {
      name: "Argentina",
      dial_code: "+54",
      code: "AR",
    },
    {
      name: "Armenia",
      dial_code: "+374",
      code: "AM",
    },
    {
      name: "Aruba",
      dial_code: "+297",
      code: "AW",
    },
    {
      name: "Australia",
      dial_code: "+61",
      code: "AU",
    },
    {
      name: "Austria",
      dial_code: "+43",
      code: "AT",
    },
    {
      name: "Azerbaijan",
      dial_code: "+994",
      code: "AZ",
    },
    {
      name: "Bahamas",
      dial_code: "+1242",
      code: "BS",
    },
    {
      name: "Bahrain",
      dial_code: "+973",
      code: "BH",
    },
    {
      name: "Bangladesh",
      dial_code: "+880",
      code: "BD",
    },
    {
      name: "Barbados",
      dial_code: "+1246",
      code: "BB",
    },
    {
      name: "Belarus",
      dial_code: "+375",
      code: "BY",
    },
    {
      name: "Belgium",
      dial_code: "+32",
      code: "BE",
    },
    {
      name: "Belize",
      dial_code: "+501",
      code: "BZ",
    },
    {
      name: "Benin",
      dial_code: "+229",
      code: "BJ",
    },
    {
      name: "Bermuda",
      dial_code: "+1441",
      code: "BM",
    },
    {
      name: "Bhutan",
      dial_code: "+975",
      code: "BT",
    },
    {
      name: "Bolivia, Plurinational State of",
      dial_code: "+591",
      code: "BO",
    },
    {
      name: "Bosnia and Herzegovina",
      dial_code: "+387",
      code: "BA",
    },
    {
      name: "Botswana",
      dial_code: "+267",
      code: "BW",
    },
    {
      name: "Brazil",
      dial_code: "+55",
      code: "BR",
    },
    {
      name: "British Indian Ocean Territory",
      dial_code: "+246",
      code: "IO",
    },
    {
      name: "Brunei Darussalam",
      dial_code: "+673",
      code: "BN",
    },
    {
      name: "Bulgaria",
      dial_code: "+359",
      code: "BG",
    },
    {
      name: "Burkina Faso",
      dial_code: "+226",
      code: "BF",
    },
    {
      name: "Burundi",
      dial_code: "+257",
      code: "BI",
    },
    {
      name: "Cambodia",
      dial_code: "+855",
      code: "KH",
    },
    {
      name: "Cameroon",
      dial_code: "+237",
      code: "CM",
    },
    {
      name: "Canada",
      dial_code: "+1",
      code: "CA",
    },
    {
      name: "Cape Verde",
      dial_code: "+238",
      code: "CV",
    },
    {
      name: "Cayman Islands",
      dial_code: "+ 345",
      code: "KY",
    },
    {
      name: "Central African Republic",
      dial_code: "+236",
      code: "CF",
    },
    {
      name: "Chad",
      dial_code: "+235",
      code: "TD",
    },
    {
      name: "Chile",
      dial_code: "+56",
      code: "CL",
    },
    {
      name: "China",
      dial_code: "+86",
      code: "CN",
    },
    {
      name: "Christmas Island",
      dial_code: "+61",
      code: "CX",
    },
    {
      name: "Cocos (Keeling) Islands",
      dial_code: "+61",
      code: "CC",
    },
    {
      name: "Colombia",
      dial_code: "+57",
      code: "CO",
    },
    {
      name: "Comoros",
      dial_code: "+269",
      code: "KM",
    },
    {
      name: "Congo",
      dial_code: "+242",
      code: "CG",
    },
    {
      name: "Congo, The Democratic Republic of the Congo",
      dial_code: "+243",
      code: "CD",
    },
    {
      name: "Cook Islands",
      dial_code: "+682",
      code: "CK",
    },
    {
      name: "Costa Rica",
      dial_code: "+506",
      code: "CR",
    },
    {
      name: "Cote d'Ivoire",
      dial_code: "+225",
      code: "CI",
    },
    {
      name: "Croatia",
      dial_code: "+385",
      code: "HR",
    },
    {
      name: "Cuba",
      dial_code: "+53",
      code: "CU",
    },
    {
      name: "Cyprus",
      dial_code: "+357",
      code: "CY",
    },
    {
      name: "Czech Republic",
      dial_code: "+420",
      code: "CZ",
    },
    {
      name: "Denmark",
      dial_code: "+45",
      code: "DK",
    },
    {
      name: "Djibouti",
      dial_code: "+253",
      code: "DJ",
    },
    {
      name: "Dominica",
      dial_code: "+1767",
      code: "DM",
    },
    {
      name: "Dominican Republic",
      dial_code: "+1849",
      code: "DO",
    },
    {
      name: "Ecuador",
      dial_code: "+593",
      code: "EC",
    },
    {
      name: "Egypt",
      dial_code: "+20",
      code: "EG",
    },
    {
      name: "El Salvador",
      dial_code: "+503",
      code: "SV",
    },
    {
      name: "Equatorial Guinea",
      dial_code: "+240",
      code: "GQ",
    },
    {
      name: "Eritrea",
      dial_code: "+291",
      code: "ER",
    },
    {
      name: "Estonia",
      dial_code: "+372",
      code: "EE",
    },
    {
      name: "Ethiopia",
      dial_code: "+251",
      code: "ET",
    },
    {
      name: "Falkland Islands (Malvinas)",
      dial_code: "+500",
      code: "FK",
    },
    {
      name: "Faroe Islands",
      dial_code: "+298",
      code: "FO",
    },
    {
      name: "Fiji",
      dial_code: "+679",
      code: "FJ",
    },
    {
      name: "Finland",
      dial_code: "+358",
      code: "FI",
    },
    {
      name: "France",
      dial_code: "+33",
      code: "FR",
    },
    {
      name: "French Guiana",
      dial_code: "+594",
      code: "GF",
    },
    {
      name: "French Polynesia",
      dial_code: "+689",
      code: "PF",
    },
    {
      name: "Gabon",
      dial_code: "+241",
      code: "GA",
    },
    {
      name: "Gambia",
      dial_code: "+220",
      code: "GM",
    },
    {
      name: "Georgia",
      dial_code: "+995",
      code: "GE",
    },
    {
      name: "Germany",
      dial_code: "+49",
      code: "DE",
    },
    {
      name: "Ghana",
      dial_code: "+233",
      code: "GH",
    },
    {
      name: "Gibraltar",
      dial_code: "+350",
      code: "GI",
    },
    {
      name: "Greece",
      dial_code: "+30",
      code: "GR",
    },
    {
      name: "Greenland",
      dial_code: "+299",
      code: "GL",
    },
    {
      name: "Grenada",
      dial_code: "+1473",
      code: "GD",
    },
    {
      name: "Guadeloupe",
      dial_code: "+590",
      code: "GP",
    },
    {
      name: "Guam",
      dial_code: "+1671",
      code: "GU",
    },
    {
      name: "Guatemala",
      dial_code: "+502",
      code: "GT",
    },
    {
      name: "Guernsey",
      dial_code: "+44",
      code: "GG",
    },
    {
      name: "Guinea",
      dial_code: "+224",
      code: "GN",
    },
    {
      name: "Guinea-Bissau",
      dial_code: "+245",
      code: "GW",
    },
    {
      name: "Guyana",
      dial_code: "+595",
      code: "GY",
    },
    {
      name: "Haiti",
      dial_code: "+509",
      code: "HT",
    },
    {
      name: "Holy See (Vatican City State)",
      dial_code: "+379",
      code: "VA",
    },
    {
      name: "Honduras",
      dial_code: "+504",
      code: "HN",
    },
    {
      name: "Hong Kong",
      dial_code: "+852",
      code: "HK",
    },
    {
      name: "Hungary",
      dial_code: "+36",
      code: "HU",
    },
    {
      name: "Iceland",
      dial_code: "+354",
      code: "IS",
    },
    {
      name: "India",
      dial_code: "+91",
      code: "IN",
    },
    {
      name: "Indonesia",
      dial_code: "+62",
      code: "ID",
    },
    {
      name: "Iran, Islamic Republic of Persian Gulf",
      dial_code: "+98",
      code: "IR",
    },
    {
      name: "Iraq",
      dial_code: "+964",
      code: "IQ",
    },
    {
      name: "Ireland",
      dial_code: "+353",
      code: "IE",
    },
    {
      name: "Isle of Man",
      dial_code: "+44",
      code: "IM",
    },
    {
      name: "Israel",
      dial_code: "+972",
      code: "IL",
    },
    {
      name: "Italy",
      dial_code: "+39",
      code: "IT",
    },
    {
      name: "Jamaica",
      dial_code: "+1876",
      code: "JM",
    },
    {
      name: "Japan",
      dial_code: "+81",
      code: "JP",
    },
    {
      name: "Jersey",
      dial_code: "+44",
      code: "JE",
    },
    {
      name: "Jordan",
      dial_code: "+962",
      code: "JO",
    },
    {
      name: "Kazakhstan",
      dial_code: "+77",
      code: "KZ",
    },
    {
      name: "Kenya",
      dial_code: "+254",
      code: "KE",
    },
    {
      name: "Kiribati",
      dial_code: "+686",
      code: "KI",
    },
    {
      name: "Korea, Democratic People's Republic of Korea",
      dial_code: "+850",
      code: "KP",
    },
    {
      name: "Korea, Republic of South Korea",
      dial_code: "+82",
      code: "KR",
    },
    {
      name: "Kuwait",
      dial_code: "+965",
      code: "KW",
    },
    {
      name: "Kyrgyzstan",
      dial_code: "+996",
      code: "KG",
    },
    {
      name: "Laos",
      dial_code: "+856",
      code: "LA",
    },
    {
      name: "Latvia",
      dial_code: "+371",
      code: "LV",
    },
    {
      name: "Lebanon",
      dial_code: "+961",
      code: "LB",
    },
    {
      name: "Lesotho",
      dial_code: "+266",
      code: "LS",
    },
    {
      name: "Liberia",
      dial_code: "+231",
      code: "LR",
    },
    {
      name: "Libyan Arab Jamahiriya",
      dial_code: "+218",
      code: "LY",
    },
    {
      name: "Liechtenstein",
      dial_code: "+423",
      code: "LI",
    },
    {
      name: "Lithuania",
      dial_code: "+370",
      code: "LT",
    },
    {
      name: "Luxembourg",
      dial_code: "+352",
      code: "LU",
    },
    {
      name: "Macao",
      dial_code: "+853",
      code: "MO",
    },
    {
      name: "Macedonia",
      dial_code: "+389",
      code: "MK",
    },
    {
      name: "Madagascar",
      dial_code: "+261",
      code: "MG",
    },
    {
      name: "Malawi",
      dial_code: "+265",
      code: "MW",
    },
    {
      name: "Malaysia",
      dial_code: "+60",
      code: "MY",
    },
    {
      name: "Maldives",
      dial_code: "+960",
      code: "MV",
    },
    {
      name: "Mali",
      dial_code: "+223",
      code: "ML",
    },
    {
      name: "Malta",
      dial_code: "+356",
      code: "MT",
    },
    {
      name: "Marshall Islands",
      dial_code: "+692",
      code: "MH",
    },
    {
      name: "Martinique",
      dial_code: "+596",
      code: "MQ",
    },
    {
      name: "Mauritania",
      dial_code: "+222",
      code: "MR",
    },
    {
      name: "Mauritius",
      dial_code: "+230",
      code: "MU",
    },
    {
      name: "Mayotte",
      dial_code: "+262",
      code: "YT",
    },
    {
      name: "Mexico",
      dial_code: "+52",
      code: "MX",
    },
    {
      name: "Micronesia, Federated States of Micronesia",
      dial_code: "+691",
      code: "FM",
    },
    {
      name: "Moldova",
      dial_code: "+373",
      code: "MD",
    },
    {
      name: "Monaco",
      dial_code: "+377",
      code: "MC",
    },
    {
      name: "Mongolia",
      dial_code: "+976",
      code: "MN",
    },
    {
      name: "Montenegro",
      dial_code: "+382",
      code: "ME",
    },
    {
      name: "Montserrat",
      dial_code: "+1664",
      code: "MS",
    },
    {
      name: "Morocco",
      dial_code: "+212",
      code: "MA",
    },
    {
      name: "Mozambique",
      dial_code: "+258",
      code: "MZ",
    },
    {
      name: "Myanmar",
      dial_code: "+95",
      code: "MM",
    },
    {
      name: "Namibia",
      dial_code: "+264",
      code: "NA",
    },
    {
      name: "Nauru",
      dial_code: "+674",
      code: "NR",
    },
    {
      name: "Nepal",
      dial_code: "+977",
      code: "NP",
    },
    {
      name: "Netherlands",
      dial_code: "+31",
      code: "NL",
    },
    {
      name: "Netherlands Antilles",
      dial_code: "+599",
      code: "AN",
    },
    {
      name: "New Caledonia",
      dial_code: "+687",
      code: "NC",
    },
    {
      name: "New Zealand",
      dial_code: "+64",
      code: "NZ",
    },
    {
      name: "Nicaragua",
      dial_code: "+505",
      code: "NI",
    },
    {
      name: "Niger",
      dial_code: "+227",
      code: "NE",
    },
    {
      name: "Nigeria",
      dial_code: "+234",
      code: "NG",
    },
    {
      name: "Niue",
      dial_code: "+683",
      code: "NU",
    },
    {
      name: "Norfolk Island",
      dial_code: "+672",
      code: "NF",
    },
    {
      name: "Northern Mariana Islands",
      dial_code: "+1670",
      code: "MP",
    },
    {
      name: "Norway",
      dial_code: "+47",
      code: "NO",
    },
    {
      name: "Oman",
      dial_code: "+968",
      code: "OM",
    },
    {
      name: "Pakistan",
      dial_code: "+92",
      code: "PK",
    },
    {
      name: "Palau",
      dial_code: "+680",
      code: "PW",
    },
    {
      name: "Palestinian Territory, Occupied",
      dial_code: "+970",
      code: "PS",
    },
    {
      name: "Panama",
      dial_code: "+507",
      code: "PA",
    },
    {
      name: "Papua New Guinea",
      dial_code: "+675",
      code: "PG",
    },
    {
      name: "Paraguay",
      dial_code: "+595",
      code: "PY",
    },
    {
      name: "Peru",
      dial_code: "+51",
      code: "PE",
    },
    {
      name: "Philippines",
      dial_code: "+63",
      code: "PH",
    },
    {
      name: "Pitcairn",
      dial_code: "+872",
      code: "PN",
    },
    {
      name: "Poland",
      dial_code: "+48",
      code: "PL",
    },
    {
      name: "Portugal",
      dial_code: "+351",
      code: "PT",
    },
    {
      name: "Puerto Rico",
      dial_code: "+1939",
      code: "PR",
    },
    {
      name: "Qatar",
      dial_code: "+974",
      code: "QA",
    },
    {
      name: "Romania",
      dial_code: "+40",
      code: "RO",
    },
    {
      name: "Russia",
      dial_code: "+7",
      code: "RU",
    },
    {
      name: "Rwanda",
      dial_code: "+250",
      code: "RW",
    },
    {
      name: "Reunion",
      dial_code: "+262",
      code: "RE",
    },
    {
      name: "Saint Barthelemy",
      dial_code: "+590",
      code: "BL",
    },
    {
      name: "Saint Helena, Ascension and Tristan Da Cunha",
      dial_code: "+290",
      code: "SH",
    },
    {
      name: "Saint Kitts and Nevis",
      dial_code: "+1869",
      code: "KN",
    },
    {
      name: "Saint Lucia",
      dial_code: "+1758",
      code: "LC",
    },
    {
      name: "Saint Martin",
      dial_code: "+590",
      code: "MF",
    },
    {
      name: "Saint Pierre and Miquelon",
      dial_code: "+508",
      code: "PM",
    },
    {
      name: "Saint Vincent and the Grenadines",
      dial_code: "+1784",
      code: "VC",
    },
    {
      name: "Samoa",
      dial_code: "+685",
      code: "WS",
    },
    {
      name: "San Marino",
      dial_code: "+378",
      code: "SM",
    },
    {
      name: "Sao Tome and Principe",
      dial_code: "+239",
      code: "ST",
    },
    {
      name: "Saudi Arabia",
      dial_code: "+966",
      code: "SA",
    },
    {
      name: "Senegal",
      dial_code: "+221",
      code: "SN",
    },
    {
      name: "Serbia",
      dial_code: "+381",
      code: "RS",
    },
    {
      name: "Seychelles",
      dial_code: "+248",
      code: "SC",
    },
    {
      name: "Sierra Leone",
      dial_code: "+232",
      code: "SL",
    },
    {
      name: "Singapore",
      dial_code: "+65",
      code: "SG",
    },
    {
      name: "Slovakia",
      dial_code: "+421",
      code: "SK",
    },
    {
      name: "Slovenia",
      dial_code: "+386",
      code: "SI",
    },
    {
      name: "Solomon Islands",
      dial_code: "+677",
      code: "SB",
    },
    {
      name: "Somalia",
      dial_code: "+252",
      code: "SO",
    },
    {
      name: "South Africa",
      dial_code: "+27",
      code: "ZA",
    },
    {
      name: "South Sudan",
      dial_code: "+211",
      code: "SS",
    },
    {
      name: "South Georgia and the South Sandwich Islands",
      dial_code: "+500",
      code: "GS",
    },
    {
      name: "Spain",
      dial_code: "+34",
      code: "ES",
    },
    {
      name: "Sri Lanka",
      dial_code: "+94",
      code: "LK",
    },
    {
      name: "Sudan",
      dial_code: "+249",
      code: "SD",
    },
    {
      name: "Suriname",
      dial_code: "+597",
      code: "SR",
    },
    {
      name: "Svalbard and Jan Mayen",
      dial_code: "+47",
      code: "SJ",
    },
    {
      name: "Swaziland",
      dial_code: "+268",
      code: "SZ",
    },
    {
      name: "Sweden",
      dial_code: "+46",
      code: "SE",
    },
    {
      name: "Switzerland",
      dial_code: "+41",
      code: "CH",
    },
    {
      name: "Syrian Arab Republic",
      dial_code: "+963",
      code: "SY",
    },
    {
      name: "Taiwan",
      dial_code: "+886",
      code: "TW",
    },
    {
      name: "Tajikistan",
      dial_code: "+992",
      code: "TJ",
    },
    {
      name: "Tanzania, United Republic of Tanzania",
      dial_code: "+255",
      code: "TZ",
    },
    {
      name: "Thailand",
      dial_code: "+66",
      code: "TH",
    },
    {
      name: "Timor-Leste",
      dial_code: "+670",
      code: "TL",
    },
    {
      name: "Togo",
      dial_code: "+228",
      code: "TG",
    },
    {
      name: "Tokelau",
      dial_code: "+690",
      code: "TK",
    },
    {
      name: "Tonga",
      dial_code: "+676",
      code: "TO",
    },
    {
      name: "Trinidad and Tobago",
      dial_code: "+1868",
      code: "TT",
    },
    {
      name: "Tunisia",
      dial_code: "+216",
      code: "TN",
    },
    {
      name: "Turkey",
      dial_code: "+90",
      code: "TR",
    },
    {
      name: "Turkmenistan",
      dial_code: "+993",
      code: "TM",
    },
    {
      name: "Turks and Caicos Islands",
      dial_code: "+1649",
      code: "TC",
    },
    {
      name: "Tuvalu",
      dial_code: "+688",
      code: "TV",
    },
    {
      name: "Uganda",
      dial_code: "+256",
      code: "UG",
    },
    {
      name: "Ukraine",
      dial_code: "+380",
      code: "UA",
    },
    {
      name: "United Arab Emirates",
      dial_code: "+971",
      code: "AE",
    },
    {
      name: "United Kingdom",
      dial_code: "+44",
      code: "GB",
    },
    {
      name: "United States",
      dial_code: "+1",
      code: "US",
    },
    {
      name: "Uruguay",
      dial_code: "+598",
      code: "UY",
    },
    {
      name: "Uzbekistan",
      dial_code: "+998",
      code: "UZ",
    },
    {
      name: "Vanuatu",
      dial_code: "+678",
      code: "VU",
    },
    {
      name: "Venezuela, Bolivarian Republic of Venezuela",
      dial_code: "+58",
      code: "VE",
    },
    {
      name: "Vietnam",
      dial_code: "+84",
      code: "VN",
    },
    {
      name: "Virgin Islands, British",
      dial_code: "+1284",
      code: "VG",
    },
    {
      name: "Virgin Islands, U.S.",
      dial_code: "+1340",
      code: "VI",
    },
    {
      name: "Wallis and Futuna",
      dial_code: "+681",
      code: "WF",
    },
    {
      name: "Yemen",
      dial_code: "+967",
      code: "YE",
    },
    {
      name: "Zambia",
      dial_code: "+260",
      code: "ZM",
    },
    {
      name: "Zimbabwe",
      dial_code: "+263",
      code: "ZW",
    },
  ];

  originalData = [
    { name: "Pastorate Union", type: "text", unique: "Required" },
    { name: "Church Name", type: "text", unique: "Required" },
    { name: "Father Name", type: "text", unique: "Not Required" },
    { name: "Mother Name", type: "text", unique: " Not Required" },
    { name: "Marriage Status", type: "text", unique: "Required" },
    { name: "Date of Baptisim", type: "number", unique: "NotRequired" },
    { name: "Middle Name", type: "text", unique: "Not Required" },
    { name: "Last Name ", type: "text", unique: "Required" },
    { name: "Member First Name", type: "number", unique: "Required" },
    { name: "Member ID", type: "number", unique: "Not Required" },
    { name: "Mob No", type: "number", unique: "Required" },
    { name: "Email", type: "text", unique: "Not Required" },
    { name: "Spouse Name", type: "text", unique: "Not Required" },
    { name: "Date of Marrige", type: "text", unique: "Not Required" },
    { name: "Date of Death", type: "text", unique: "Not Required" },
    { name: "No of Childreen", type: "number", unique: "Required" },
    { name: "Names Children", type: "text", unique: "Not Required" },
    { name: "Address", type: "text", unique: "Not Required " },
    { name: "Aadhar No", type: "number", unique: "Not Required" },
    {
      name: "Choose_Date",
      type: "date / 20-2-2023  24-2-2024",
      unique: "Required",
    },
    { name: "Date of Confermation", type: "text", unique: "Not Required" },
  ];
  time_zones = moment.tz.names();

  constructor(
    public modalRef: BsModalRef,
    public _toastMessageService: ToastMessageService,
    public commonHelper: CommonHelper,
    public organizationManagementService: OrganizationManagementService,
    private churchUserTypesService: ChurchUserTypeService,
    public pastorateDetailService: PastorateDetailService
  ) {
    super(modalRef);
    this.tzNames = moment.tz.names();
  }

  public timeZoneChanged(timeZone: string): void {
    this.selectedTz = timeZone;

    this.updateTime(timeZone);
  }

  private updateTime(timeZone: string) {
    const currentTime = new Date().getTime();
    this.utcDate = moment(currentTime).utc();
    this.tzDate = moment(currentTime).tz(timeZone);
  }

  ngOnInit() {
    this.getOrganizationList();
    this.getChurchType();
    this.getPastorateDetails();
    // this.getChurchTypeList()
    this.users_detail.push({ selected_date: "", date_selected_for: "" });
    this.FamilyDetails.push({ childern_name: "" });
    setTimeout(() => {
      if (this.organization_user_obj._id) {
        this.organization_user_obj.users_details.forEach((x) => {
          // x.date_of_baptism = new Date(x.selected_date);
          x.date_selected_for = x.date_selected_for;
        });
        this.organization_user_obj.family_details.forEach((element) => {
          element.childern_name = element.childern_name;
        });
        
        this.organization_user_obj.pastorate_detail =
          this.organization_user_obj.pastorate_detail;
        this.onPastorateUnionChange(this.organization_user_obj.user_type_id);
      }

      this.users_detail = this.organization_user_obj.users_details;
      this.FamilyDetails = this.organization_user_obj.family_details;
      this.organization_user_obj.marriage_type = this.organization_user_obj.marriage_type ;
      

    
    }, 300);
  }

  showCILModal() {
    setTimeout(() => {
      if (this.cilModel && this.cilModel[0]) {
        this.cilModel[0].style.opacity = 1;
      }
    }, 250);
  }

  onClose() {
    this.decision = "";
    this.close(true);
    this.showCILModal();
  }

  getOrganizationList() {
    return new Promise((resolve, reject) => {
      this.organizationManagementService.getAllOrganization().subscribe(
        (response: any) => {
          this.demo = response.data.data;
          return resolve({});
        },
        (err) => {
          this.commonHelper.showError(err);
          return resolve({});
        }
      );
    });
  }

  onCancel() {
    this.decision = "cancel";
    this.close(true);
  }

  getChurchTypeList() {
    return new Promise((resolve, reject) => {
      this.churchUserTypesService.getAChurchTypes().subscribe(
        (response: any) => {
          this.default_church_type_list = response.data.data.map((d) => {
            return {
              _id: d._id,
              text: d.organization_name,
              organization_name: d.organization_name,
              is_active: d.is_active,
            };
          });
          return resolve({});
        },
        (err) => {
          this.commonHelper.showError(err);
          return resolve({});
        }
      );
    });
  }

  getChurchTypeFilters(event) {
    if (event) {
      this.filtered_organizations_list = this.default_organizations_list.filter(
        (element) => element.pastorate_detail_id === event
      );
    }
  }

  submit() {
    if (
      !this.organization_user_obj.first_name ||
      !this.organization_user_obj.first_name.trim()
    ) {
      this._toastMessageService.alert(
        "error",
        "Please add organization user first name"
      );
      return;
    }
    if (
      !this.organization_user_obj.last_name ||
      !this.organization_user_obj.last_name.trim()
    ) {
      this._toastMessageService.alert(
        "error",
        "Please add organization user last name"
      );
      return;
    }

    // console.log(this.organization_user_obj, 'obj');

    if (!this.organization_user_obj.organization_id) {
      this._toastMessageService.alert("error", "Please select a organization");
      return;
    }

    if (!this.organization_user_obj.user_type_id) {
      this._toastMessageService.alert("error", "Please select a church Type");
      return;
    }

    let sData = JSON.parse(JSON.stringify(this.organization_user_obj));
    sData.phone_number = sData.phone_number
      ? sData.phone_number.trim().replace(/\D/g, "")
      : "";
    if (!this.organization_user_obj._id) {
      //add new organization
      this.isDoneBtnDisabled = true;
      delete sData._id;
      this.organizationManagementService.addOrganizationUser(sData).subscribe(
        (response: any) => {
          this.response_data = response.data;
          if (this.organization_logo_data) {
            this.changeOrganizationLogo(response.data._id).then(
              (logoResult: any) => {
                this._toastMessageService.alert(
                  "success",
                  "Organization data added successfully."
                );
                this.organization_user_obj.organization_logo_url =
                  logoResult.data && logoResult.data.organization_logo_url
                    ? environment.url +
                      logoResult.data.organization_logo_url +
                      "?t=" +
                      new Date().getTime()
                    : "";
                this.response_data.organization_logo_url =
                  this.organization_user_obj.organization_logo_url;
                this.isDoneBtnDisabled = false;
                this.done();
              }
            );
          } else {
            this._toastMessageService.alert(
              "success",
              "Organization data added successfully."
            );
            this.isDoneBtnDisabled = false;
            this.done();
          }
        },
        (err) => {
          this.commonHelper.showError(err);
          this.isDoneBtnDisabled = false;
        }
      );
    } else {
      //update organization
      this.isDoneBtnDisabled = true;
      this.organizationManagementService
        .updateOrganizationUser(this.organization_user_obj._id, sData)
        .subscribe(
          (response: any) => {
            this._toastMessageService.alert(
              "success",
              "Organization User data updated successfully."
            );
            this.response_data = response.data;
            if (this.organization_logo_data) {
              this.changeOrganizationLogo(this.organization_user_obj._id).then(
                (logoResult: any) => {
                  this.organization_user_obj.organization_logo_url =
                    logoResult.data && logoResult.data.organization_logo_url
                      ? environment.url +
                        logoResult.data.organization_logo_url +
                        "?t=" +
                        new Date().getTime()
                      : "";
                  this.isDoneBtnDisabled = false;
                  this.done();
                }
              );
            } else {
              this.isDoneBtnDisabled = false;
              this.done();
            }
          },
          (err) => {
            this.commonHelper.showError(err);
            this.isDoneBtnDisabled = false;
          }
        );
    }
  }

  uploadCsv() {
    this.formData.append("csvFile", this.approveObj.csvFile);
    this.loading = true;
    // Assuming importCSV returns an Observable
    this.organizationManagementService.importCSV(this.formData).subscribe(
      (res: any) => {
        this.loading = false;
        if (res.status == 200 && res.data.successRecords.length > 0) {
          this._toastMessageService.alert(
            "success",
            "Success CSV File: " +
              res.data.successRecords.length +
              ", Error CSV File: " +
              res.data.errorRecords.length
          );
          setTimeout(() => {
            if (res.data.errorRecords.length > 0) {
              let errorMessages = [];
              res.data.errorRecords.forEach((record) => {
                errorMessages.push(
                  "Failed Record: " +
                    record.value +
                    "\nReason: " +
                    record.reason +
                    "\n"
                );
              });
              setTimeout(() => {
                this._toastMessageService.alert(
                  "error",
                  errorMessages.join("\n\n")
                );
              }, 7000);
            }
          }, 1000);
          this.dialogResult = res.status;
          this.done();
        } else {
          if (res.data.errorRecords.length > 0) {
            let errorMessages = res.data.errorRecords.map((record) => record);
            const causeOfError = errorMessages[0].reason;
            const firstName = errorMessages[0].value;
            const errorMessage = causeOfError
              ? "Error: " + causeOfError + "\n\n"
              : "";
            const firstNameMessage = firstName
              ? "First Name: " + firstName + "\n\n"
              : "";
            const mainMessage =
              "Please make your CSV file according to the reference document and try again." +
              "\n\n" +
              firstNameMessage +
              errorMessage;

            // Display the main message in a toast
            this._toastMessageService.alert("error", mainMessage);
          }
        }
      },
      (error: any) => {
        this.loading = false;
        this.commonHelper.showError(error);
      }
    );
  }

  onClickSampleCSV() {
    let headers = [
      "Pastorate Union",
      "Pastorate",
      "Church Name",
      "Member First Name",
      "Middle Name",
      "Last Name ",
      "Member ID",
      "Mob No",
      "Email",
      "Father Name",
      "Mother Name",
      "Marriage Status",
      "Date of Baptisim",
      "Date of Confermation",
      "Spouse Name",
      "Date of Marrige ",
      "Date of Death",
      "No of Childreen",
      "Names Children",
      "Address",
      "Aadhar No",
    ];
    this.downloadSampleFile(
      [
        "GajaPathi",
        "Demo",
        "church demo",
        "jhon",
        "doe",
        "smith",
        "123456",
        "7894561231",
        "email@gmail.com",
        "Prem",
        "Demo",
        "Unmarried",
        "20-2-2023",
        "PleaseSelect_the_Cause",
        "demo",
        "10-02-2023",
        "",
        "3",
        "Ajay",
        "Banglore",
        "78944561231",
      ],
      "sample-file",
      headers
    );
  }

  downloadSampleFile(data, filename = "data", headers) {
    let csvData = this.ConvertToCSVWithourSRNO(data, headers);
    let blob = new Blob(["\ufeff" + csvData], { type: "text/.csv;" });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSVWithourSRNO(objArray, headerList) {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    let row = "";

    for (let index in headerList) {
      row += headerList[index] + ",";
    }
    row = row.slice(0, -1);
    str += row + "\r\n";
    let line;
    for (let index in headerList) {
      let head = headerList[index];
      line = array + ",";
    }
    str += line + "\r\n";

    return str;
  }

  onFileChange($event: any) {
    if (this.mimeType.includes($event.target.files[0].type)) {
      const file = $event.target.files[0];
      this.formData.append("csvFile", $event.target.files[0]);
      this.approveObj.csvFile = file.name;
    }
  }

  getPastorateDetails() {
    this.pastorateDetailService
      .getAllPastorateType({})
      .subscribe((res: any) => {
        this.loading = false;
        if (res.status == 200 && res.data) {
          this.pastorateDetail = res.data.data;
        }
      });
  }

  onPastorateUnionChange(event) {
    this.filteredPastorateDetails = [];
    setTimeout(() => {
      this.pastorateDetail.forEach((element) => {
        
        if (element.pastorateUnionDetails._id === event) {
          this.filteredPastorateDetails.push(element);
        }
      });      
    }, 300);

  }

  onStudentSelected(selectedOption: any) {
    if (selectedOption) {
      this.table_data.forEach((element) => {
        if (element._id === selectedOption) {
          this.fatherName = element.father_name;
          this.fatherLastName = element.father_lastname;
        }
      });
    }
  }
  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      let files = event.dataTransfer.files[0];
      this.formData.append("csvFile", event.dataTransfer.files[0]);
      this.csvFileName = files.name;
      this.dragdropmsg = files.name;
    }
  }

  changeOrganizationLogo(organization_id) {
    return new Promise((resolve, reject) => {
      this.organizationManagementService
        .updateOrganizationUserLogo(organization_id, {
          organization_logo_data: this.organization_logo_data,
        })
        .subscribe(
          (response: any) => {
            return resolve({ status: 0, data: response.data });
          },
          (err) => {
            this.commonHelper.showError(err);
            return resolve({ status: -1 });
          }
        );
    });
  }

  ShowHide(value) {
    if (value == "password") {
      this.passType = "text";
      this.show = true;
    } else {
      this.passType = "password";
      this.show = false;
    }
  }

  download() {
    this.formatToCsvData();
    const exportedFilenmae = this.fileTitle + ".csv";
    const blob = new Blob([this.mockCsvData], {
      type: "text/.csv;",
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", exportedFilenmae);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  formatToCsvData() {
    let itemsFormatted = [];
    this.originalData.forEach((item) => {
      itemsFormatted.push({
        name: item.name.replace(/,/g, ""), // remove commas
        type: item.type,
        unique: item.unique,
      });
    });
    const jsonObject = JSON.stringify(itemsFormatted);
    const csv = this.convertToCSV(jsonObject);
    this.mockCsvData = csv;
  }
  convertToCSV(objArray) {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";

    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line != "") line += ",";
        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  }

  fileTitle = "export-sample-result";

  done() {
    this.decision = "done";
    this.close(true);
  }

  adduserdetails() {
    if (this.users_detail.length) {
      this.users_detail.push({ selected_date: "", date_selected_for: "" });
    }
  }

  addfamilydetails() {
    if (this.FamilyDetails.length) {
      this.FamilyDetails.push({ childern_name: "" });
    }
  }

  removefamiltdetails(i) {
    this.FamilyDetails.splice(i, 1);
  }

  showNextDetails() {
    this.church_member_detail = false;
    this.church_member_user_detail = true;
  }

  showPreviousDetails() {
    this.church_member_detail = true;
    this.church_member_user_detail = false;
  }

  removeuserdetails(i) {
    this.users_detail.splice(i, 1);
  }

  onOrganizationLogoUpload(event) {
    if (event && event.indexOf("base64") != -1) {
      this.organization_logo_data = event;
    }
  }

  GetDetails(event) {
    console.log(event);
    this.parent_organization_id = event;
    this.org_list.forEach((element) => {
      console.log("event", event);
      if (event == element._id) {
        this.organization_user_obj.phone_number =
          element.organization_phone_number;
      }
    });
  }

  getChurchType() {
    this.churchUserTypesService.getAllChurchUserType().subscribe((res: any) => {
      this.loading = false;
      if (res.status == 200 && res.data) {
        this.churchtype = res.data.data;
      }
    });
  }

  checkOrganizationUser(event) {
    let params = {
      username: event,
    };
    this.organizationManagementService.checkOrganizationUser(params).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.isUserExist = res.data;
        }
      },
      (error) => {
        this.commonHelper.showError(error);
      }
    );
  }
}
