import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { CommonHelper } from '../../../components/common/helpers/common.helper';
import { LoaderService } from '../../loader.service';
import { OrganizationManagementService } from '../../../components/common/services/organization_management.service';
import { AddUpdateOrganizationUserManagementModalComponent } from './add-update-organization-user-management-modal/add-update-organization-user-management-modal.component';
import { ConfirmationModalComponent } from '../../modals/confirmation-modal/confirmation-modal.component';
import { ToastMessageService } from '../../../components/common/toast-message/toast-message.service';
import { ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BaseModalComponent } from '../../../views/modals/base-modal.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'organization-user-management',
  providers: [],
  templateUrl: 'organization-user-management.template.html',
  styleUrls: ['./organization-user-management.component.css'],
})
export class OrganizationUserManagementComponent
  extends BaseModalComponent
  implements OnInit
{
  @Input('table_info') table_info: any;
  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  @Output() onLoaderStatusChange: EventEmitter<any> = new EventEmitter();
  default_roles_list: any = [];
  default_organizations_list: any = [];
  selected_organization: any = [];
  selected_createdby: any = 'all';
  search_text: any = '';
  first_name: any;
  last_name: any;
  approveObj: { id: any; };
  getSlidersWithFilters: any;
organization_name: any;

  constructor(
    private router: Router,
    private commonHelper: CommonHelper,
    private _loaderService: LoaderService,
    private organizationManagementService: OrganizationManagementService,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private _toastMessageService: ToastMessageService,
    private activatedRoute: ActivatedRoute,
  ) {
    super(modalRef);
  }

  ngOnInit() {
    this.first_name = localStorage.getItem('firstName');
    this.last_name = localStorage.getItem('lastName');
    this.onLoaderStatusChange.emit(true);
    this.getOrganizationList().then((result) => {
    this.onLoaderStatusChange.emit(false);
    });
  }

  ngOnChanges(changeRecord: any) {
    if((this.table_info.length==1) &&( this.table_info.search=true))
{
    // this.table_info.forEach(element => {
    //   let data =element.organization_name
    //   this.selected_organization =  data.map(d => {return {_id:d._id,text:d.organization_name,organization_name:d.organization_name,is_active:true}});
    // });
   
}

if (
      changeRecord &&
      changeRecord.table_info &&
      !changeRecord.table_info.firstChange &&
      changeRecord.table_info.currentValue &&
      changeRecord.table_info.currentValue.length > 0
    ) {
    }
  }


  getOrganizationList() {
    return new Promise((resolve, reject) => {
      this.organizationManagementService.getAllOrganization().subscribe(
        (response: any) => {
          this.default_organizations_list = response.data.data.map(d => {return {_id:d._id,text:d.organization_name,organization_name:d.organization_name,is_active:d.is_active,church_type:d.church_type,pastorate_detail_id:d.pastorate_detail_id  }});
          return resolve({});
        },
        (err) => {
          this.commonHelper.showError(err);
          return resolve({});
        }
      );
    });
  }

  reloadCurrentRoute() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    const currentUrl = this.router.url + '?';
    this.router.navigateByUrl(currentUrl).then(() => {
      this.router.navigated = false;
      this.router.navigate([this.activatedRoute.snapshot.url.join('/')]);
    });
  }

  onClickAdd() {
    this.modalRef = this.modalService.show(
      AddUpdateOrganizationUserManagementModalComponent,
      {
        class: 'organization-user-management-modal',
        backdrop: 'static',
        keyboard: false,
      }
    );
    this.modalRef.content.decision = '';
    this.modalRef.content.default_roles_list = this.default_roles_list;
    this.modalRef.content.default_organizations_list =
      this.default_organizations_list;
    var currSub: Subscription = this.modalRef.content.onHide.subscribe(
      (result: boolean) => {
        currSub.unsubscribe();
        if (this.modalRef.content.decision === 'done') {
          this.getOrganizationList();
          this.modalRef.content.response_data.index =
            this.table_info.length + 1;
          this.table_info.push(
            JSON.parse(JSON.stringify(this.modalRef.content.response_data))
          );
          
          this.reloadCurrentRoute()
        }
      }
    );
  }

  showUploadCSVModal() {
    this.modalRef = this.modalService.show(AddUpdateOrganizationUserManagementModalComponent, {
      class: 'add-csv-modal',
      backdrop: 'static',
      keyboard: false,
    });
    this.modalRef.content.approveObj = this.approveObj;
    this.modalRef.content.decision = '';
    var tempSubObj: Subscription = this.modalRef.content.onHide.subscribe(
      () => {
        if (this.modalRef.content.decision === 'done') {
          // this.getSlidersWithFilters({ page: 1 });
          this.reloadCurrentRoute()
        }
        tempSubObj.unsubscribe();
      }
    );
  }


  uploadCsv() {
    this.approveObj = {
      id: null,
    };
    this.showUploadCSVModal();
  }
  onClickEdit(refData, index) {
    console.log('refData',refData)
    this.modalRef = this.modalService.show(
      AddUpdateOrganizationUserManagementModalComponent,
      {
        class: 'organization-user-management-modal',
        backdrop: 'static',
        keyboard: false,
      }
    );
    this.modalRef.content.organization_user_obj = JSON.parse(
      JSON.stringify(refData)
    );
    this.modalRef.content.default_organizations_list =
      this.default_organizations_list;
    var currSub: Subscription = this.modalRef.content.onHide.subscribe(
      (result: boolean) => {
        currSub.unsubscribe();
        if (this.modalRef.content.decision === 'done') {
          this.modalRef.content.organization_user_obj.middle_name = '';
          this.table_info[index] = JSON.parse(JSON.stringify(this.modalRef.content.organization_user_obj));
        }
      }
    );
  }

  onClickDelete(refData, index) {
    this.modalRef = this.modalService.show(ConfirmationModalComponent, {
      class: 'common-small-modal',
      backdrop: 'static',
      keyboard: false,
    });
    this.modalRef.content.decision = '';
    this.modalRef.content.step = 2;
    this.modalRef.content.confirmation_text =
      'Are sure to delete organization user?';

    var currSub: Subscription = this.modalRef.content.onHide.subscribe(
      (result: boolean) => {
        currSub.unsubscribe();
        if (this.modalRef.content.decision === 'done') {
          this.onLoaderStatusChange.emit(true);
          //this._loaderService.changeLoaderStatus(true);
          this.organizationManagementService
            .removeOrganizationUser(refData._id, {
              organization_id: refData.organization_id,
            })
            .subscribe(
              (response: any) => {
                if (response.data) {
                  this._toastMessageService.alert(
                    'success',
                    'Organization user data deleted successfully.'
                  );
                  this.table_info.splice(index, 1);
                }
                this.onLoaderStatusChange.emit(false);
                //this._loaderService.changeLoaderStatus(false);
              },
              (err) => {
                this.commonHelper.showError(err);
                this.onLoaderStatusChange.emit(false);
                //this._loaderService.changeLoaderStatus(false);
              }
            );
        }
      }
    );
  }
  onChangeSelection(event) {
    try {
      var data = JSON.parse(event);
      this.selected_organization = data.value;
    } catch (err) {
      console.log(err);
    }
  }
  resetSearchOnTable() {
    this.selected_organization = [];
    this.selected_createdby = 'all';
    this.search_text = '';
    this.onSearch.emit({
      name: this.search_text.trim(),
      organizations: this.selected_organization.map((a) => a._id),
      createdby: this.selected_createdby,
    });
  }
  // searchOnTable() {
  //   this.onSearch.emit({
  //     name: this.search_text.trim(),
  //     organizations: this.selected_organization.map((a) => a._id),
  //     createdby: this.selected_createdby,
  //   });
  // }

  searchOnTable() {      
    this.onSearch.emit({name:this.search_text.trim(),organizations:this.selected_organization.map((a)=>a._id),createdby:this.selected_createdby})
  }

  
}
