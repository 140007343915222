import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from '../../../views/loader.service';
import { ChangePasswordModalComponent } from '../../../views/modals/change-password-modal/change-password-modal.component';
import { AuthenticationService } from '../services/authentication.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BaseModalComponent } from '../../../views/modals/base-modal.component';
import { Subscription } from 'rxjs';
import { OrganizationManagementService } from '../services/organization_management.service';
declare var $: any;
@Component({
  selector: 'navigation',
  templateUrl: 'navigation.template.html',
  styleUrls: ['./navigation.component.css'],
})
export class NavigationComponent extends BaseModalComponent implements OnInit {
  menu_flag: any = {};
  role: any = localStorage.getItem('role');
  public first_name: string = '';
  public last_name: string = '';
  public organization_id: string = '';
  response_data: any;
  public management_name: string = '';

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private _loaderService: LoaderService,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    public organizationManagementService: OrganizationManagementService
  ) {
    super(modalRef);
  }

  ngOnInit() {
    this.first_name = localStorage.getItem('firstName');
    this.last_name = localStorage.getItem('lastName');
    this.organization_id = localStorage.getItem('organization_id');

    this.getOrgName();
  }

  redirectTo(page) {
    /*this.closeOtherSubMenu()*/
    this.router.navigateByUrl(page);
  }

  currentRoute(routenames: any): boolean {
    return routenames.indexOf(this.router.url) != -1;
  }

  ToggleSubMenu(id) {
    this.menu_flag[id] = this.menu_flag[id] ? false : true;
    if (this.menu_flag[id]) {
      $('#' + id).addClass('open_sub_menu');
    } else {
      $('#' + id).removeClass('open_sub_menu');
    }
  }

  closeOtherSubMenu() {
    for (var x in this.menu_flag) {
      if (this.menu_flag[x]) {
        this.menu_flag[x] = false;
        $('#' + x).removeClass('open_sub_menu');
      }
    }
  }

  getOrgName() {
    if (this.organization_id) {
      let params = {
        organization_id: this.organization_id,
      };
      this.organizationManagementService
        .getChurchOrg(params)
        .subscribe((response: any) => {
          localStorage.setItem(
            'management_name',
            response.data[0].organization_name
          );
          this.management_name = response.data[0].organization_name;

          this.response_data = response.data;
        });
    }
  }
}
