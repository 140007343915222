import { Component, OnInit } from "@angular/core";
import { environment } from "../../../../../environments/environment";
import { BsModalRef } from "ngx-bootstrap/modal";
import { BaseModalComponent } from "../../../modals/base-modal.component";
import { ToastMessageService } from "../../../../components/common/toast-message/toast-message.service";
import { CommonHelper } from "../../../../components/common/helpers/common.helper";
import { OrganizationManagementService } from "../../../../components/common/services/organization_management.service";
import { states } from "../../../../constants/states";
import * as moment from "moment";
import "moment-timezone";
import { ChurchUserTypeService } from "app/components/common/services/church-user-type.service";
import { PastorateDetailService } from "app/components/common/services/pastorate-details.service";

declare var $: any;

@Component({
  selector: "add-update-organization-info-management-modal",
  templateUrl: "./add-update-organization-info-management-modal.template.html",
  styleUrls: ["./add-update-organization-info-management-modal.component.css"],
})
export class AddUpdateOrganizationInfoManagementModalComponent
  extends BaseModalComponent
  implements OnInit
{
  decision: string = "";
  table_data: any = [];
  public loading: boolean = false;
  showNoparentDisable: boolean = false;
  pastorateDetail: any = [];
  pastorateUnionDetail: any = [];
  childern: [];
  filteredPastorateDetails: any = [];
  organization_obj: any = {
    _id: null,
    organization_name: null,
    organization_address: {
      address_1: "",
      address_2: "",
      city: "",
      state: "",
      country: "",
      zip_code: "",
    },
    // parent_orgnization: '',
    church_type: null,
    pastorate_detail_id: null,
    children: "",
    organization_phone_number: null,
    organization_email: "",
    organization_time_zone: "UTC",
    is_active: true,
  };
  organization_list_child: any = [];
  countrycodelist: any;
  isDoneBtnDisabled: boolean = false;
  response_data: any = null;
  submitted: boolean = false;
  default_modules_list: any = [];
  default_church_type_list: any = [];
  selected_organization_modules: any = [];
  selected_country_index: any = "";
  selected_state_index: any = "";
  temp_state_list: any = states;
  public tzNames: string[];
  showDisabled: boolean = false;
  table_info: any = [];
  parent_org_list: any = [];
  children: any = [];
  isChurchNameExist: any;
  isChurchNameAvailable: any;
  pastorateDetaildata: any;
  organization_list: any = [];
  storeparentorg: any;
  public selectedTz: string;
  public utcDate: moment.Moment;

  public tzDate: moment.Moment;
  showErrormsg: any;
  states_list: any = {
    afghanistan: [
      "Badakhshan",
      "Badghis",
      "Baghlan",
      "Balkh",
      "Bamian",
      "Daykondi",
      "Farah",
      "Faryab",
      "Ghazni",
      "Ghowr",
      "Helmand",
      "Herat",
      "Jowzjan",
      "Kabul",
      "Kandahar",
      "Kapisa",
      "Khost",
      "Konar",
      "Kondoz",
      "Laghman",
      "Lowgar",
      "Nangarhar",
      "Nimruz",
      "Nurestan",
      "Oruzgan",
      "Paktia",
      "Paktika",
      "Panjshir",
      "Parvan",
      "Samangan",
      "Sar-e Pol",
      "Takhar",
      "Vardak",
      "Zabol",
    ],
    albania: [
      "Berat",
      "Dibres",
      "Durres",
      "Elbasan",
      "Fier",
      "Gjirokastre",
      "Korce",
      "Kukes",
      "Lezhe",
      "Shkoder",
      "Tirane",
      "Vlore",
    ],
    algeria: [
      "Adrar",
      "Ain Defla",
      "Ain Temouchent",
      "Alger",
      "Annaba",
      "Batna",
      "Bechar",
      "Bejaia",
      "Biskra",
      "Blida",
      "Bordj Bou Arreridj",
      "Bouira",
      "Boumerdes",
      "Chlef",
      "Constantine",
      "Djelfa",
      "El Bayadh",
      "El Oued",
      "El Tarf",
      "Ghardaia",
      "Guelma",
      "Illizi",
      "Jijel",
      "Khenchela",
      "Laghouat",
      "Muaskar",
      "Medea",
      "Mila",
      "Mostaganem",
      "M'Sila",
      "Naama",
      "Oran",
      "Ouargla",
      "Oum el Bouaghi",
      "Relizane",
      "Saida",
      "Setif",
      "Sidi Bel Abbes",
      "Skikda",
      "Souk Ahras",
      "Tamanghasset",
      "Tebessa",
      "Tiaret",
      "Tindouf",
      "Tipaza",
      "Tissemsilt",
      "Tizi Ouzou",
      "Tlemcen",
    ],
    american_samoa: [],
    andorra: [
      "Andorra la Vella",
      "Canillo",
      "Encamp",
      "Escaldes-Engordany",
      "La Massana",
      "Ordino",
      "Sant Julia de Loria",
    ],
    angola: [
      "Bengo",
      "Benguela",
      "Bie",
      "Cabinda",
      "Cuando Cubango",
      "Cuanza Norte",
      "Cuanza Sul",
      "Cunene",
      "Huambo",
      "Huila",
      "Luanda",
      "Lunda Norte",
      "Lunda Sul",
      "Malanje",
      "Moxico",
      "Namibe",
      "Uige",
      "Zaire",
    ],
    anguilla: [],
    antarctica: [],
    argentina: [
      "Buenos Aires",
      "Buenos Aires Capital",
      "Catamarca",
      "Chaco",
      "Chubut",
      "Cordoba",
      "Corrientes",
      "Entre Rios",
      "Formosa",
      "Jujuy",
      "La Pampa",
      "La Rioja",
      "Mendoza",
      "Misiones",
      "Neuquen",
      "Rio Negro",
      "Salta",
      "San Juan",
      "San Luis",
      "Santa Cruz",
      "Santa Fe",
      "Santiago del Estero",
      "Tierra del Fuego",
      "Tucuman",
    ],
    antigua_and_barbuda: [
      "Barbuda",
      "Redonda",
      "Saint George",
      "Saint John",
      "Saint Mary",
      "Saint Paul",
      "Saint Peter",
      "Saint Philip",
    ],
    armenia: [
      "Aragatsotn",
      "Ararat",
      "Armavir",
      "Geghark",
      "unik",
      "Kotayk",
      "Lorri",
      "Shirak",
      "Syunik",
      "Tavush",
      "Vayots",
      "Dzor",
      "Yerevan",
    ],
    aruba: [],
    australia: [],
    bahamas: [
      "Acklins and Crooked Islands",
      "Bimini",
      "Cat Island",
      "Exuma",
      "Freeport",
      "Fresh Creek",
      "Governor's Harbour",
      "Green Turtle Cay",
      "Harbour Island",
      "High Rock",
      "Inagua",
      "Kemps Bay",
      "Long Island",
      "Marsh Harbour",
      "Mayaguana",
      "New Providence",
      "Nichollstown and Berry Islands",
      "Ragged Island",
      "Rock Sound",
      "Sandy Point",
      "San Salvador and Rum Cay",
    ],
    azerbaijan: [
      "Abseron Rayonu",
      "Agcabadi Rayonu",
      "Agdam Rayonu",
      "Agdas Rayonu",
      "Agstafa Rayonu",
      "Agsu Rayonu",
      "Astara Rayonu",
      "Balakan Rayonu",
      "Barda Rayonu",
      "Beylaqan Rayonu",
      "Bilasuvar Rayonu",
      "Cabrayil Rayonu",
      "Calilabad Rayonu",
      "Daskasan Rayonu",
      "Davaci Rayonu",
      "Fuzuli Rayonu",
      "Gadabay Rayonu",
      "Goranboy Rayonu",
      "Goycay Rayonu",
      "Haciqabul Rayonu",
      "Imisli Rayonu",
      "Ismayilli Rayonu",
      "Kalbacar Rayonu",
      "Kurdamir Rayonu",
      "Lacin Rayonu",
      "Lankaran Rayonu",
      "Lerik Rayonu",
      "Masalli Rayonu",
      "Neftcala Rayonu",
      "Oguz Rayonu",
      "Qabala Rayonu",
      "Qax Rayonu",
      "Qazax Rayonu",
      "Qobustan Rayonu",
      "Quba Rayonu",
      "Qubadli Rayonu",
      "Qusar Rayonu",
      "Saatli Rayonu",
      "Sabirabad Rayonu",
      "Saki Rayonu",
      "Salyan Rayonu",
      "Samaxi Rayonu",
      "Samkir Rayonu",
      "Samux Rayonu",
      "Siyazan Rayonu",
      "Susa Rayonu",
      "Tartar Rayonu",
      "Tovuz Rayonu",
      "Ucar Rayonu",
      "Xacmaz Rayonu",
      "Xanlar Rayonu",
      "Xizi Rayonu",
      "Xocali Rayonu",
      "Xocavand Rayonu",
      "Yardimli Rayonu",
      "Yevlax Rayonu",
      "Zangilan Rayonu",
      "Zaqatala Rayonu",
      "Zardab Rayonu",
      "Ali Bayramli Sahari",
      "Baki Sahari",
      "Ganca Sahari",
      "Lankaran Sahari",
      "Mingacevir Sahari",
      "Naftalan Sahari",
      "Saki Sahari",
      "Sumqayit Sahari",
      "Susa Sahari",
      "Xankandi Sahari",
      "Yevlax Sahari",
      "Naxcivan Muxtar",
    ],
    austria: [
      "Burgenland",
      "Kaernten",
      "Niederoesterreich",
      "Oberoesterreich",
      "Salzburg",
      "Steiermark",
      "Tirol",
      "Vorarlberg",
      "Wien",
    ],
    bahrain: [
      "Al Hadd",
      "Al Manamah",
      "Al Mintaqah al Gharbiyah",
      "Al Mintaqah al Wusta",
      "Al Mintaqah ash Shamaliyah",
      "Al Muharraq",
      "Ar Rifa' wa al Mintaqah al Janubiyah",
      "Jidd Hafs",
      "Madinat Hamad",
      "Madinat 'Isa",
      "Juzur Hawar",
      "Sitrah",
    ],
    bangladesh: [
      "Barisal",
      "Chittagong",
      "Dhaka",
      "Khulna",
      "Rajshahi",
      "Sylhet",
    ],
    barbados: [
      "Christ Church",
      "Saint Andrew",
      "Saint George",
      "Saint James",
      "Saint John",
      "Saint Joseph",
      "Saint Lucy",
      "Saint Michael",
      "Saint Peter",
      "Saint Philip",
      "Saint Thomas",
    ],
    belarus: [
      "Brest",
      "Homyel",
      "Horad Minsk",
      "Hrodna",
      "Mahilyow",
      "Minsk",
      "Vitsyebsk",
    ],
    belgium: [
      "Antwerpen",
      "Brabant Wallon",
      "Brussels",
      "Flanders",
      "Hainaut",
      "Liege",
      "Limburg",
      "Luxembourg",
      "Namur",
      "Oost-Vlaanderen",
      "Vlaams-Brabant",
      "Wallonia",
      "West-Vlaanderen",
    ],
    belize: [
      "Belize",
      "Cayo",
      "Corozal",
      "Orange Walk",
      "Stann Creek",
      "Toledo",
    ],
    benin: [
      "Alibori",
      "Atakora",
      "Atlantique",
      "Borgou",
      "Collines",
      "Donga",
      "Kouffo",
      "Littoral",
      "Mono",
      "Oueme",
      "Plateau",
      "Zou",
    ],
    bermuda: [
      "Devonshire",
      "Hamilton",
      "Hamilton",
      "Paget",
      "Pembroke",
      "Saint George",
      "Saint George's",
      "Sandys",
      "Smith's",
      "Southampton",
      "Warwick",
    ],
    bhutan: [
      "Bumthang",
      "Chukha",
      "Dagana",
      "Gasa",
      "Haa",
      "Lhuntse",
      "Mongar",
      "Paro",
      "Pemagatshel",
      "Punakha",
      "Samdrup Jongkhar",
      "Samtse",
      "Sarpang",
      "Thimphu",
      "Trashigang",
      "Trashiyangste",
      "Trongsa",
      "Tsirang",
      "Wangdue Phodrang",
      "Zhemgang",
    ],
    bolivia: [
      "Chuquisaca",
      "Cochabamba",
      "Beni",
      "La Paz",
      "Oruro",
      "Pando",
      "Potosi",
      "Santa Cruz",
      "Tarija",
    ],
    bonaire: [],
    bosnia_and_herzegovina: [
      "Una-Sana [Federation]",
      "Posavina [Federation]",
      "Tuzla [Federation]",
      "Zenica-Doboj [Federation]",
      "Bosnian Podrinje [Federation]",
      "Central Bosnia [Federation]",
      "Herzegovina-Neretva [Federation]",
      "West Herzegovina [Federation]",
      "Sarajevo [Federation]",
      " West Bosnia [Federation]",
      "Banja Luka [RS]",
      "Bijeljina [RS]",
      "Doboj [RS]",
      "Fo?a [RS]",
      "Sarajevo-Romanija [RS]",
      "Trebinje [RS]",
      "Vlasenica [RS]",
    ],
    botswana: [
      "Central",
      "Ghanzi",
      "Kgalagadi",
      "Kgatleng",
      "Kweneng",
      "North East",
      "North West",
      "South East",
      "Southern",
    ],
    bouvet_island: [],
    brazil: [
      "Acre",
      "Alagoas",
      "Amapa",
      "Amazonas",
      "Bahia",
      "Ceara",
      "Distrito Federal",
      "Espirito Santo",
      "Goias",
      "Maranhao",
      "Mato Grosso",
      "Mato Grosso do Sul",
      "Minas Gerais",
      "Para",
      "Paraiba",
      "Parana",
      "Pernambuco",
      "Piaui",
      "Rio de Janeiro",
      "Rio Grande do Norte",
      "Rio Grande do Sul",
      "Rondonia",
      "Roraima",
      "Santa Catarina",
      "Sao Paulo",
      "Sergipe",
      "Tocantins",
    ],
    british_Indian_Ocean_Territory: [],
    brune_darussalam: [],
    bulgaria: [
      "Blagoevgrad",
      "Burgas",
      "Dobrich",
      "Gabrovo",
      "Khaskovo",
      "Kurdzhali",
      "Kyustendil",
      "Lovech",
      "Montana",
      "Pazardzhik",
      "Pernik",
      "Pleven",
      "Plovdiv",
      "Razgrad",
      "Ruse",
      "Shumen",
      "Silistra",
      "Sliven",
      "Smolyan",
      "Sofiya",
      "Sofiya-Grad",
      "Stara Zagora",
      "Turgovishte",
      "Varna",
      "Veliko Turnovo",
      "Vidin",
      "Vratsa",
      "Yambol",
    ],
    burkina_faso: [
      "Bale",
      "Bam",
      "Banwa",
      "Bazega",
      "Bougouriba",
      "Boulgou",
      "Boulkiemde",
      "Comoe",
      "Ganzourgou",
      "Gnagna",
      "Gourma",
      "Houet",
      "Ioba",
      "Kadiogo",
      "Kenedougou",
      "Komondjari",
      "Kompienga",
      "Kossi",
      "Koulpelogo",
      "Kouritenga",
      "Kourweogo",
      "Leraba",
      "Loroum",
      "Mouhoun",
      "Namentenga",
      "Nahouri",
      "Nayala",
      "Noumbiel",
      "Oubritenga",
      "Oudalan",
      "Passore",
      "Poni",
      "Sanguie",
      "Sanmatenga",
      "Seno",
      "Sissili",
      "Soum",
      "Sourou",
      "Tapoa",
      "Tuy",
      "Yagha",
      "Yatenga",
      "Ziro",
      "Zondoma",
      "Zoundweogo",
    ],
    burma: [
      "Ayeyarwady",
      "Bago",
      "Magway",
      "Mandalay",
      "Sagaing",
      "Tanintharyi",
      "Yangon",
      "Chin State",
      "Kachin State",
      "Kayin State",
      "Kayah State",
      "Mon State",
      "Rakhine State",
      "Shan State",
    ],
    burundi: [
      "Bubanza",
      "Bujumbura Mairie",
      "Bujumbura Rural",
      "Bururi",
      "Cankuzo",
      "Cibitoke",
      "Gitega",
      "Karuzi",
      "Kayanza",
      "Kirundo",
      "Makamba",
      "Muramvya",
      "Muyinga",
      "Mwaro",
      "Ngozi",
      "Rutana",
      "Ruyigi",
    ],
    cabo_verde: [],
    cambodia: [
      "Banteay Mean Chey",
      "Batdambang",
      "Kampong Cham",
      "Kampong Chhnang",
      "Kampong Spoe",
      "Kampong Thum",
      "Kampot",
      "Kandal",
      "Koh Kong",
      "Kracheh",
      "Mondol Kiri",
      "Otdar Mean Chey",
      "Pouthisat",
      "Preah Vihear",
      "Prey Veng",
      "Rotanakir",
      "Siem Reab",
      "Stoeng Treng",
      "Svay Rieng",
      "Takao",
      "Keb",
      "Pailin",
      "Phnom Penh",
      "Preah Seihanu",
    ],
    cameroon: [
      "Adamaoua",
      "Centre",
      "Est",
      "Extreme-Nord",
      "Littoral",
      "Nord",
      "Nord-Ouest",
      "Ouest",
      "Sud",
      "Sud-Ouest",
    ],
    canada: [
      "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Northwest Territories",
      "Nova Scotia",
      "Nunavut",
      "Ontario",
      "Prince Edward Island",
      "Quebec",
      "Saskatchewan",
      "Yukon Territory",
    ],
    cayman_islands: [],
    central_african_republic: [
      "Bamingui-Bangoran",
      "Bangui",
      "Basse-Kotto",
      "Haute-Kotto",
      "Haut-Mbomou",
      "Kemo",
      "Lobaye",
      "Mambere-Kadei",
      "Mbomou",
      "Nana-Grebizi",
      "Nana-Mambere",
      "Ombella-Mpoko",
      "Ouaka",
      "Ouham",
      "Ouham-Pende",
      "Sangha-Mbaere",
      "Vakaga",
    ],
    chad: [
      "Batha",
      "Biltine",
      "Borkou-Ennedi-Tibesti",
      "Chari-Baguirmi",
      "Guéra",
      "Kanem",
      "Lac",
      "Logone Occidental",
      "Logone Oriental",
      "Mayo-Kebbi",
      "Moyen-Chari",
      "Ouaddaï",
      "Salamat",
      "Tandjile",
    ],
    chile: [
      "Aysen",
      "Antofagasta",
      "Araucania",
      "Atacama",
      "Bio-Bio",
      "Coquimbo",
      "O'Higgins",
      "Los Lagos",
      "Magallanes y la Antartica Chilena",
      "Maule",
      "Santiago Region Metropolitana",
      "Tarapaca",
      "Valparaiso",
    ],
    china: [
      "Anhui",
      "Fujian",
      "Gansu",
      "Guangdong",
      "Guizhou",
      "Hainan",
      "Hebei",
      "Heilongjiang",
      "Henan",
      "Hubei",
      "Hunan",
      "Jiangsu",
      "Jiangxi",
      "Jilin",
      "Liaoning",
      "Qinghai",
      "Shaanxi",
      "Shandong",
      "Shanxi",
      "Sichuan",
      "Yunnan",
      "Zhejiang",
      "Guangxi",
      "Nei Mongol",
      "Ningxia",
      "Xinjiang",
      "Xizang (Tibet)",
      "Beijing",
      "Chongqing",
      "Shanghai",
      "Tianjin",
    ],
    christmas_island: [],
    cocos_island: [],
    colombia: [
      "Amazonas",
      "Antioquia",
      "Arauca",
      "Atlantico",
      "Bogota District Capital",
      "Bolivar",
      "Boyaca",
      "Caldas",
      "Caqueta",
      "Casanare",
      "Cauca",
      "Cesar",
      "Choco",
      "Cordoba",
      "Cundinamarca",
      "Guainia",
      "Guaviare",
      "Huila",
      "La Guajira",
      "Magdalena",
      "Meta",
      "Narino",
      "Norte de Santander",
      "Putumayo",
      "Quindio",
      "Risaralda",
      "San Andres & Providencia",
      "Santander",
      "Sucre",
      "Tolima",
      "Valle del Cauca",
      "Vaupes",
      "Vichada",
    ],
    comoros: ["Grande Comore (Njazidja)", "Anjouan (Nzwani)", "Moheli (Mwali)"],
    congo_democratic_republic: [
      "Bandundu",
      "Bas-Congo",
      "Equateur",
      "Kasai-Occidental",
      "Kasai-Oriental",
      "Katanga",
      "Kinshasa",
      "Maniema",
      "Nord-Kivu",
      "Orientale",
      "Sud-Kivu",
    ],
    congo: [
      "Bouenza",
      "Brazzaville",
      "Cuvette",
      "Cuvette-Ouest",
      "Kouilou",
      "Lekoumou",
      "Likouala",
      "Niari",
      "Plateaux",
      "Pool",
      "Sangha",
    ],
    cook_islands: [],
    costa_rica: [
      "Alajuela",
      "Cartago",
      "Guanacaste",
      "Heredia",
      "Limon",
      "Puntarenas",
      "San Jose",
    ],
    croatia: [
      "Bjelovarsko-Bilogorska",
      "Brodsko-Posavska",
      "Dubrovacko-Neretvanska",
      "Istarska",
      "Karlovacka",
      "Koprivnicko-Krizevacka",
      "Krapinsko-Zagorska",
      "Licko-Senjska",
      "Medimurska",
      "Osjecko-Baranjska",
      "Pozesko-Slavonska",
      "Primorsko-Goranska",
      "Sibensko-Kninska",
      "Sisacko-Moslavacka",
      "Splitsko-Dalmatinska",
      "Varazdinska",
      "Viroviticko-Podravska",
      "Vukovarsko-Srijemska",
      "Zadarska",
      "Zagreb",
      "Zagrebacka",
    ],
    cuba: [
      "Camaguey",
      "Ciego de Avila",
      "Cienfuegos",
      "Ciudad de La Habana",
      "Granma",
      "Guantanamo",
      "Holguin",
      "Isla de la Juventud",
      "La Habana",
      "Las Tunas",
      "Matanzas",
      "Pinar del Rio",
      "Sancti Spiritus",
      "Santiago de Cuba",
      "Villa Clara",
    ],
    curaçao: [],
    cyprus: [
      "Famagusta",
      "Kyrenia",
      "Larnaca",
      "Limassol",
      "Nicosia",
      "Paphos",
    ],
    czechia: [
      "Jihocesky Kraj",
      "Jihomoravsky Kraj",
      "Karlovarsky Kraj",
      "Kralovehradecky Kraj",
      "Liberecky Kraj",
      "Moravskoslezsky Kraj",
      "Olomoucky Kraj",
      "Pardubicky Kraj",
      "Plzensky Kraj",
      "Praha",
      "Stredocesky Kraj",
      "Ustecky Kraj",
      "Vysocina",
      "Zlinsky Kraj",
    ],
    côte_d_Ivoire: [],
    denmark: [
      "Arhus",
      "Bornholm",
      "Frederiksberg",
      "Frederiksborg",
      "Fyn",
      "Kobenhavn",
      "Kobenhavns",
      "Nordjylland",
      "Ribe",
      "Ringkobing",
      "Roskilde",
      "Sonderjylland",
      "Storstrom",
      "Vejle",
      "Vestsjalland",
      "Viborg",
    ],
    djibouti: ["Ali Sabih", "Dikhil", "Djibouti", "Obock", "Tadjoura"],
    dominica: [
      "Saint Andrew",
      "Saint David",
      "Saint George",
      "Saint John",
      "Saint Joseph",
      "Saint Luke",
      "Saint Mark",
      "Saint Patrick",
      "Saint Paul",
      "Saint Peter",
    ],
    dominican_republic: [
      "Azua",
      "Baoruco",
      "Barahona",
      "Dajabon",
      "Distrito Nacional",
      "Duarte",
      "Elias Pina",
      "El Seibo",
      "Espaillat",
      "Hato Mayor",
      "Independencia",
      "La Altagracia",
      "La Romana",
      "La Vega",
      "Maria Trinidad Sanchez",
      "Monsenor Nouel",
      "Monte Cristi",
      "Monte Plata",
      "Pedernales",
      "Peravia",
      "Puerto Plata",
      "Salcedo",
      "Samana",
      "Sanchez Ramirez",
      "San Cristobal",
      "San Jose de Ocoa",
      "San Juan",
      "San Pedro de Macoris",
      "Santiago",
      "Santiago Rodriguez",
      "Santo Domingo",
      "Valverde",
    ],
    ecuador: [
      "Azuay",
      "Bolivar",
      "Canar",
      "Carchi",
      "Chimborazo",
      "Cotopaxi",
      "El Oro",
      "Esmeraldas",
      "Galapagos",
      "Guayas",
      "Imbabura",
      "Loja",
      "Los Rios",
      "Manabi",
      "Morona-Santiago",
      "Napo",
      "Orellana",
      "Pastaza",
      "Pichincha",
      "Sucumbios",
      "Tungurahua",
      "Zamora-Chinchipe",
    ],
    egypt: [
      "Ad Daqahliyah",
      "Al Bahr al Ahmar",
      "Al Buhayrah",
      "Al Fayyum",
      "Al Gharbiyah",
      "Al Iskandariyah",
      "Al Isma'iliyah",
      "Al Jizah",
      "Al Minufiyah",
      "Al Minya",
      "Al Qahirah",
      "Al Qalyubiyah",
      "Al Wadi al Jadid",
      "Ash Sharqiyah",
      "As Suways",
      "Aswan",
      "Asyut",
      "Bani Suwayf",
      "Bur Sa'id",
      "Dumyat",
      "Janub Sina'",
      "Kafr ash Shaykh",
      "Matruh",
      "Qina",
      "Shamal Sina'",
      "Suhaj",
    ],
    el_Salvador: [
      "Ahuachapan",
      "Cabanas",
      "Chalatenango",
      "Cuscatlan",
      "La Libertad",
      "La Paz",
      "La Union",
      "Morazan",
      "San Miguel",
      "San Salvador",
      "Santa Ana",
      "San Vicente",
      "Sonsonate",
      "Usulutan",
    ],
    equatorial_guinea: [
      "Annobon",
      "Bioko Norte",
      "Bioko Sur",
      "Centro Sur",
      "Kie-Ntem",
      "Litoral",
      "Wele-Nzas",
    ],
    eritrea: [
      "Anseba",
      "Debub",
      "Debubawi K'eyih Bahri",
      "Gash Barka",
      "Ma'akel",
      "Semenawi Keyih Bahri",
    ],
    estonia: [
      "Harjumaa (Tallinn)",
      "Hiiumaa (Kardla)",
      "Ida-Virumaa (Johvi)",
      "Jarvamaa (Paide)",
      "Jogevamaa (Jogeva)",
      "Laanemaa (Haapsalu)",
      "Laane-Virumaa (Rakvere)",
      "Parnumaa (Parnu)",
      "Polvamaa (Polva)",
      "Raplamaa (Rapla)",
      "Saaremaa (Kuressaare)",
      "Tartumaa (Tartu)",
      "Valgamaa (Valga)",
      "Viljandimaa (Viljandi)",
      "Vorumaa (Voru)",
    ],
    eswatini: [],
    ethiopia: [
      "Addis Ababa",
      "Afar",
      "Amhara",
      "Binshangul Gumuz",
      "Dire Dawa",
      "Gambela Hizboch",
      "Harari",
      "Oromia",
      "Somali",
      "Tigray",
      "Southern Nations, Nationalities, and Peoples Region",
    ],
    falkland_islands: [],
    faroe_islands: [],
    fiji: [
      "Central (Suva)",
      "Eastern (Levuka)",
      "Northern (Labasa)",
      "Rotuma",
      "Western (Lautoka)",
    ],
    finland: [
      "Aland",
      "Etela-Suomen Laani",
      "Ita-Suomen Laani",
      "Lansi-Suomen Laani",
      "Lappi",
      "Oulun Laani",
    ],
    france: [
      "Alsace",
      "Aquitaine",
      "Auvergne",
      "Basse-Normandie",
      "Bourgogne",
      "Bretagne",
      "Centre",
      "Champagne-Ardenne",
      "Corse",
      "Franche-Comte",
      "Haute-Normandie",
      "Ile-de-France",
      "Languedoc-Roussillon",
      "Limousin",
      "Lorraine",
      "Midi-Pyrenees",
      "Nord-Pas-de-Calais",
      "Pays de la Loire",
      "Picardie",
      "Poitou-Charentes",
      "Provence-Alpes-Cote d'Azur",
      "Rhone-Alpes",
    ],
    french_guiana: [],
    french_polynesia: [],
    french_southern_territories: [],
    gabon: [
      "Estuaire",
      "Haut-Ogooue",
      "Moyen-Ogooue",
      "Ngounie",
      "Nyanga",
      "Ogooue-Ivindo",
      "Ogooue-Lolo",
      "Ogooue-Maritime",
      "Woleu-Ntem",
    ],
    gambia: [
      "Banjul",
      "Central River",
      "Lower River",
      "North Bank",
      "Upper River",
      "Western",
    ],
    georgia: [],
    germany: [
      "Baden-Wuerttemberg",
      "Bayern",
      "Berlin",
      "Brandenburg",
      "Bremen",
      "Hamburg",
      "Hessen",
      "Mecklenburg-Vorpommern",
      "Niedersachsen",
      "Nordrhein-Westfalen",
      "Rheinland-Pfalz",
      "Saarland",
      "Sachsen",
      "Sachsen-Anhalt",
      "Schleswig-Holstein",
      "Thueringen",
    ],
    ghana: [
      "Ashanti",
      "Brong-Ahafo",
      "Central",
      "Eastern",
      "Greater Accra",
      "Northern",
      "Upper East",
      "Upper West",
      "Volta",
      "Western",
    ],
    gibraltar: [],
    greece: [
      "Agion Oros",
      "Achaia",
      "Aitolia kai Akarmania",
      "Argolis",
      "Arkadia",
      "Arta",
      "Attiki",
      "Chalkidiki",
      "Chanion",
      "Chios",
      "Dodekanisos",
      "Drama",
      "Evros",
      "Evrytania",
      "Evvoia",
      "Florina",
      "Fokidos",
      "Fthiotis",
      "Grevena",
      "Ileia",
      "Imathia",
      "Ioannina",
      "Irakleion",
      "Karditsa",
      "Kastoria",
      "Kavala",
      "Kefallinia",
      "Kerkyra",
      "Kilkis",
      "Korinthia",
      "Kozani",
      "Kyklades",
      "Lakonia",
      "Larisa",
      "Lasithi",
      "Lefkas",
      "Lesvos",
      "Magnisia",
      "Messinia",
      "Pella",
      "Pieria",
      "Preveza",
      "Rethynnis",
      "Rodopi",
      "Samos",
      "Serrai",
      "Thesprotia",
      "Thessaloniki",
      "Trikala",
      "Voiotia",
      "Xanthi",
      "Zakynthos",
    ],
    greenland: [
      "Avannaa (Nordgronland)",
      "Tunu (Ostgronland)",
      "Kitaa (Vestgronland)",
    ],
    grenada: [
      "Alta Verapaz",
      "Baja Verapaz",
      "Chimaltenango",
      "Chiquimula",
      "El Progreso",
      "Escuintla",
      "Guatemala",
      "Huehuetenango",
      "Izabal",
      "Jalapa",
      "Jutiapa",
      "Peten",
      "Quetzaltenango",
      "Quiche",
      "Retalhuleu",
      "Sacatepequez",
      "San Marcos",
      "Santa Rosa",
      "Solola",
      "Suchitepequez",
      "Totonicapan",
      "Zacapa",
    ],
    guadeloupe: [],
    guam: [],
    guatemala: [
      "Alta Verapaz",
      "Baja Verapaz",
      "Chimaltenango",
      "Chiquimula",
      "El Progreso",
      "Escuintla",
      "Guatemala",
      "Huehuetenango",
      "Izabal",
      "Jalapa",
      "Jutiapa",
      "Peten",
      "Quetzaltenango",
      "Quiche",
      "Retalhuleu",
      "Sacatepequez",
      "San Marcos",
      "Santa Rosa",
      "Solola",
      "Suchitepequez",
      "Totonicapan",
      "Zacapa",
    ],
    guernsey: [],
    guinea: [
      "Beyla",
      "Boffa",
      "Boke",
      "Conakry",
      "Coyah",
      "Dabola",
      "Dalaba",
      "Dinguiraye",
      "Dubreka",
      "Faranah",
      "Forecariah",
      "Fria",
      "Gaoual",
      "Gueckedou",
      "Kankan",
      "Kerouane",
      "Kindia",
      "Kissidougou",
      "Koubia",
      "Koundara",
      "Kouroussa",
      "Labe",
      "Lelouma",
      "Lola",
      "Macenta",
      "Mali",
      "Mamou",
      "Mandiana",
      "Nzerekore",
      "Pita",
      "Siguiri",
      "Telimele",
      "Tougue",
      "Yomou",
    ],
    guinea_bissau: [
      "Bafata",
      "Biombo",
      "Bissau",
      "Bolama",
      "Cacheu",
      "Gabu",
      "Oio",
      "Quinara",
      "Tombali",
    ],
    guyana: [
      "Barima-Waini",
      "Cuyuni-Mazaruni",
      "Demerara-Mahaica",
      "East Berbice-Corentyne",
      "Essequibo Islands-West Demerara",
      "Mahaica-Berbice",
      "Pomeroon-Supenaam",
      "Potaro-Siparuni",
      "Upper Demerara-Berbice",
      "Upper Takutu-Upper Essequibo",
    ],
    haiti: [
      "Artibonite",
      "Centre",
      "Grand 'Anse",
      "Nord",
      "Nord-Est",
      "Nord-Ouest",
      "Ouest",
      "Sud",
      "Sud-Est",
    ],
    heard_island_and_mcDonald_islands: [],
    holy_see: [],
    honduras: [
      "Atlantida",
      "Choluteca",
      "Colon",
      "Comayagua",
      "Copan",
      "Cortes",
      "El Paraiso",
      "Francisco Morazan",
      "Gracias a Dios",
      "Intibuca",
      "Islas de la Bahia",
      "La Paz",
      "Lempira",
      "Ocotepeque",
      "Olancho",
      "Santa Barbara",
      "Valle",
      "Yoro",
    ],
    hong_kong: [],
    hungary: [
      "Bacs-Kiskun",
      "Baranya",
      "Bekes",
      "Borsod-Abauj-Zemplen",
      "Csongrad",
      "Fejer",
      "Gyor-Moson-Sopron",
      "Hajdu-Bihar",
      "Heves",
      "Jasz-Nagykun-Szolnok",
      "Komarom-Esztergom",
      "Nograd",
      "Pest",
      "Somogy",
      "Szabolcs-Szatmar-Bereg",
      "Tolna",
      "Vas",
      "Veszprem",
      "Zala",
      "Bekescsaba",
      "Debrecen",
      "Dunaujvaros",
      "Eger",
      "Gyor",
      "Hodmezovasarhely",
      "Kaposvar",
      "Kecskemet",
      "Miskolc",
      "Nagykanizsa",
      "Nyiregyhaza",
      "Pecs",
      "Sopron",
      "Szeged",
      "Szekesfehervar",
      "Szolnok",
      "Szombathely",
      "Tatabanya",
      "Veszprem",
      "Zalaegerszeg",
    ],
    iceland: [
      "Austurland",
      "Hofudhborgarsvaedhi",
      "Nordhurland Eystra",
      "Nordhurland Vestra",
      "Sudhurland",
      "Sudhurnes",
      "Vestfirdhir",
      "Vesturland",
    ],
    india: [
      "Andaman and Nicobar Islands",
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chandigarh",
      "Chhattisgarh",
      "Dadra and Nagar Haveli",
      "Daman and Diu",
      "Delhi",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jammu and Kashmir",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      "Lakshadweep",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "Nagaland",
      "Orissa",
      "Pondicherry",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "Tamil Nadu",
      "Tripura",
      "Uttaranchal",
      "Uttar Pradesh",
      "West Bengal",
    ],
    indonesia: [
      "Aceh",
      "Bali",
      "Banten",
      "Bengkulu",
      "Gorontalo",
      "Irian Jaya Barat",
      "Jakarta Raya",
      "Jambi",
      "Jawa Barat",
      "Jawa Tengah",
      "Jawa Timur",
      "Kalimantan Barat",
      "Kalimantan Selatan",
      "Kalimantan Tengah",
      "Kalimantan Timur",
      "Kepulauan Bangka Belitung",
      "Kepulauan Riau",
      "Lampung",
      "Maluku",
      "Maluku Utara",
      "Nusa Tenggara Barat",
      "Nusa Tenggara Timur",
      "Papua",
      "Riau",
      "Sulawesi Barat",
      "Sulawesi Selatan",
      "Sulawesi Tengah",
      "Sulawesi Tenggara",
      "Sulawesi Utara",
      "Sumatera Barat",
      "Sumatera Selatan",
      "Sumatera Utara",
      "Yogyakarta",
    ],
    iran: [
      "Ardabil",
      "Azarbayjan-e Gharbi",
      "Azarbayjan-e Sharqi",
      "Bushehr",
      "Chahar Mahall va Bakhtiari",
      "Esfahan",
      "Fars",
      "Gilan",
      "Golestan",
      "Hamadan",
      "Hormozgan",
      "Ilam",
      "Kerman",
      "Kermanshah",
      "Khorasan-e Janubi",
      "Khorasan-e Razavi",
      "Khorasan-e Shemali",
      "Khuzestan",
      "Kohgiluyeh va Buyer Ahmad",
      "Kordestan",
      "Lorestan",
      "Markazi",
      "Mazandaran",
      "Qazvin",
      "Qom",
      "Semnan",
      "Sistan va Baluchestan",
      "Tehran",
      "Yazd",
      "Zanjan",
    ],
    iraq: [
      "Al Anbar",
      "Al Basrah",
      "Al Muthanna",
      "Al Qadisiyah",
      "An Najaf",
      "Arbil",
      "As Sulaymaniyah",
      "At Ta'mim",
      "Babil",
      "Baghdad",
      "Dahuk",
      "Dhi Qar",
      "Diyala",
      "Karbala'",
      "Maysan",
      "Ninawa",
      "Salah ad Din",
      "Wasit",
    ],
    ireland: [
      "Carlow",
      "Cavan",
      "Clare",
      "Cork",
      "Donegal",
      "Dublin",
      "Galway",
      "Kerry",
      "Kildare",
      "Kilkenny",
      "Laois",
      "Leitrim",
      "Limerick",
      "Longford",
      "Louth",
      "Mayo",
      "Meath",
      "Monaghan",
      "Offaly",
      "Roscommon",
      "Sligo",
      "Tipperary",
      "Waterford",
      "Westmeath",
      "Wexford",
      "Wicklow",
    ],
    isle_of_man: [],
    israel: [
      "Central",
      "Haifa",
      "Jerusalem",
      "Northern",
      "Southern",
      "Tel Aviv",
    ],
    italy: [
      "Abruzzo",
      "Basilicata",
      "Calabria",
      "Campania",
      "Emilia-Romagna",
      "Friuli-Venezia Giulia",
      "Lazio",
      "Liguria",
      "Lombardia",
      "Marche",
      "Molise",
      "Piemonte",
      "Puglia",
      "Sardegna",
      "Sicilia",
      "Toscana",
      "Trentino-Alto Adige",
      "Umbria",
      "Valle d'Aosta",
      "Veneto",
    ],
    jamaica: [
      "Clarendon",
      "Hanover",
      "Kingston",
      "Manchester",
      "Portland",
      "Saint Andrew",
      "Saint Ann",
      "Saint Catherine",
      "Saint Elizabeth",
      "Saint James",
      "Saint Mary",
      "Saint Thomas",
      "Trelawny",
      "Westmoreland",
    ],
    japan: [
      "Aichi",
      "Akita",
      "Aomori",
      "Chiba",
      "Ehime",
      "Fukui",
      "Fukuoka",
      "Fukushima",
      "Gifu",
      "Gumma",
      "Hiroshima",
      "Hokkaido",
      "Hyogo",
      "Ibaraki",
      "Ishikawa",
      "Iwate",
      "Kagawa",
      "Kagoshima",
      "Kanagawa",
      "Kochi",
      "Kumamoto",
      "Kyoto",
      "Mie",
      "Miyagi",
      "Miyazaki",
      "Nagano",
      "Nagasaki",
      "Nara",
      "Niigata",
      "Oita",
      "Okayama",
      "Okinawa",
      "Osaka",
      "Saga",
      "Saitama",
      "Shiga",
      "Shimane",
      "Shizuoka",
      "Tochigi",
      "Tokushima",
      "Tokyo",
      "Tottori",
      "Toyama",
      "Wakayama",
      "Yamagata",
      "Yamaguchi",
      "Yamanashi",
    ],
    jersey: [],
    jordan: [
      "Ajlun",
      "Al 'Aqabah",
      "Al Balqa'",
      "Al Karak",
      "Al Mafraq",
      "'Amman",
      "At Tafilah",
      "Az Zarqa'",
      "Irbid",
      "Jarash",
      "Ma'an",
      "Madaba",
    ],
    kazakhstan: [
      "Almaty Oblysy",
      "Almaty Qalasy",
      "Aqmola Oblysy",
      "Aqtobe Oblysy",
      "Astana Qalasy",
      "Atyrau Oblysy",
      "Batys Qazaqstan Oblysy",
      "Bayqongyr Qalasy",
      "Mangghystau Oblysy",
      "Ongtustik Qazaqstan Oblysy",
      "Pavlodar Oblysy",
      "Qaraghandy Oblysy",
      "Qostanay Oblysy",
      "Qyzylorda Oblysy",
      "Shyghys Qazaqstan Oblysy",
      "Soltustik Qazaqstan Oblysy",
      "Zhambyl Oblysy",
    ],
    kenya: [
      "Central",
      "Coast",
      "Eastern",
      "Nairobi Area",
      "North Eastern",
      "Nyanza",
      "Rift Valley",
      "Western",
    ],
    kiribati: [],
    korea_north: [
      "Chagang",
      "North Hamgyong",
      "South Hamgyong",
      "North Hwanghae",
      "South Hwanghae",
      "Kangwon",
      "North P'yongan",
      "South P'yongan",
      "Yanggang",
      "Kaesong",
      "Najin",
      "Namp'o",
      "Pyongyang",
    ],
    korea_south: [
      "Seoul",
      "Busan City",
      "Daegu City",
      "Incheon City",
      "Gwangju City",
      "Daejeon City",
      "Ulsan",
      "Gyeonggi Province",
      "Gangwon Province",
      "North Chungcheong Province",
      "South Chungcheong Province",
      "North Jeolla Province",
      "South Jeolla Province",
      "North Gyeongsang Province",
      "South Gyeongsang Province",
      "Jeju",
    ],
    kuwait: [
      "Al Ahmadi",
      "Al Farwaniyah",
      "Al Asimah",
      "Al Jahra",
      "Hawalli",
      "Mubarak Al-Kabeer",
    ],
    lao_peoples_democratic_republic: [
      "Attapu",
      "Bokeo",
      "Bolikhamxai",
      "Champasak",
      "Houaphan",
      "Khammouan",
      "Louangnamtha",
      "Louangphrabang",
      "Oudomxai",
      "Phongsali",
      "Salavan",
      "Savannakhet",
      "Viangchan",
      "Viangchan",
      "Xaignabouli",
      "Xaisomboun",
      "Xekong",
      "Xiangkhoang",
    ],
    latvia: [
      "Aizkraukles Rajons",
      "Aluksnes Rajons",
      "Balvu Rajons",
      "Bauskas Rajons",
      "Cesu Rajons",
      "Daugavpils",
      "Daugavpils Rajons",
      "Dobeles Rajons",
      "Gulbenes Rajons",
      "Jekabpils Rajons",
      "Jelgava",
      "Jelgavas Rajons",
      "Jurmala",
      "Kraslavas Rajons",
      "Kuldigas Rajons",
      "Liepaja",
      "Liepajas Rajons",
      "Limbazu Rajons",
      "Ludzas Rajons",
      "Madonas Rajons",
      "Ogres Rajons",
      "Preilu Rajons",
      "Rezekne",
      "Rezeknes Rajons",
      "Riga",
      "Rigas Rajons",
      "Saldus Rajons",
      "Talsu Rajons",
      "Tukuma Rajons",
      "Valkas Rajons",
      "Valmieras Rajons",
      "Ventspils",
      "Ventspils Rajons",
    ],
    lebanon: [
      "Beyrouth",
      "Beqaa",
      "Liban-Nord",
      "Liban-Sud",
      "Mont-Liban",
      "Nabatiye",
    ],
    lesotho: [
      "Berea",
      "Butha-Buthe",
      "Leribe",
      "Mafeteng",
      "Maseru",
      "Mohale's Hoek",
      "Mokhotlong",
      "Qacha's Nek",
      "Quthing",
      "Thaba-Tseka",
    ],
    liberia: [
      "Bomi",
      "Bong",
      "Gbarpolu",
      "Grand Bassa",
      "Grand Cape Mount",
      "Grand Gedeh",
      "Grand Kru",
      "Lofa",
      "Margibi",
      "Maryland",
      "Montserrado",
      "Nimba",
      "River Cess",
      "River Gee",
      "Sinoe",
    ],
    libya: [
      "Ajdabiya",
      "Al 'Aziziyah",
      "Al Fatih",
      "Al Jabal al Akhdar",
      "Al Jufrah",
      "Al Khums",
      "Al Kufrah",
      "An Nuqat al Khams",
      "Ash Shati'",
      "Awbari",
      "Az Zawiyah",
      "Banghazi",
      "Darnah",
      "Ghadamis",
      "Gharyan",
      "Misratah",
      "Murzuq",
      "Sabha",
      "Sawfajjin",
      "Surt",
      "Tarabulus",
      "Tarhunah",
      "Tubruq",
      "Yafran",
      "Zlitan",
    ],
    liechtenstein: [
      "Balzers",
      "Eschen",
      "Gamprin",
      "Mauren",
      "Planken",
      "Ruggell",
      "Schaan",
      "Schellenberg",
      "Triesen",
      "Triesenberg",
      "Vaduz",
    ],
    lithuania: [
      "Alytaus",
      "Kauno",
      "Klaipedos",
      "Marijampoles",
      "Panevezio",
      "Siauliu",
      "Taurages",
      "Telsiu",
      "Utenos",
      "Vilniaus",
    ],
    luxembourg: ["Diekirch", "Grevenmacher", "Luxembourg"],
    macao: [],
    madagascar: [
      "Antananarivo",
      "Antsiranana",
      "Fianarantsoa",
      "Mahajanga",
      "Toamasina",
      "Toliara",
    ],
    malawi: [
      "Balaka",
      "Blantyre",
      "Chikwawa",
      "Chiradzulu",
      "Chitipa",
      "Dedza",
      "Dowa",
      "Karonga",
      "Kasungu",
      "Likoma",
      "Lilongwe",
      "Machinga",
      "Mangochi",
      "Mchinji",
      "Mulanje",
      "Mwanza",
      "Mzimba",
      "Ntcheu",
      "Nkhata Bay",
      "Nkhotakota",
      "Nsanje",
      "Ntchisi",
      "Phalombe",
      "Rumphi",
      "Salima",
      "Thyolo",
      "Zomba",
    ],
    malaysia: [
      "Johor",
      "Kedah",
      "Kelantan",
      "Kuala Lumpur",
      "Labuan",
      "Malacca",
      "Negeri Sembilan",
      "Pahang",
      "Perak",
      "Perlis",
      "Penang",
      "Sabah",
      "Sarawak",
      "Selangor",
      "Terengganu",
    ],
    maldives: [
      "Alifu",
      "Baa",
      "Dhaalu",
      "Faafu",
      "Gaafu Alifu",
      "Gaafu Dhaalu",
      "Gnaviyani",
      "Haa Alifu",
      "Haa Dhaalu",
      "Kaafu",
      "Laamu",
      "Lhaviyani",
      "Maale",
      "Meemu",
      "Noonu",
      "Raa",
      "Seenu",
      "Shaviyani",
      "Thaa",
      "Vaavu",
    ],
    mali: [
      "Bamako (Capital)",
      "Gao",
      "Kayes",
      "Kidal",
      "Koulikoro",
      "Mopti",
      "Segou",
      "Sikasso",
      "Tombouctou",
    ],
    malta: [],
    marshall_islands: [],
    martinique: [],
    mauritania: [
      "Adrar",
      "Assaba",
      "Brakna",
      "Dakhlet Nouadhibou",
      "Gorgol",
      "Guidimaka",
      "Hodh Ech Chargui",
      "Hodh El Gharbi",
      "Inchiri",
      "Nouakchott",
      "Tagant",
      "Tiris Zemmour",
      "Trarza",
    ],
    mauritius: [
      "Agalega Islands",
      "Black River",
      "Cargados Carajos Shoals",
      "Flacq",
      "Grand Port",
      "Moka",
      "Pamplemousses",
      "Plaines Wilhems",
      "Port Louis",
      "Riviere du Rempart",
      "Rodrigues",
      "Savanne",
    ],
    mayotte: [],
    mexico: [
      "Aguascalientes",
      "Baja California",
      "Baja California Sur",
      "Campeche",
      "Chiapas",
      "Chihuahua",
      "Coahuila de Zaragoza",
      "Colima",
      "Distrito Federal",
      "Durango",
      "Guanajuato",
      "Guerrero",
      "Hidalgo",
      "Jalisco",
      "Mexico",
      "Michoacan de Ocampo",
      "Morelos",
      "Nayarit",
      "Nuevo Leon",
      "Oaxaca",
      "Puebla",
      "Queretaro de Arteaga",
      "Quintana Roo",
      "San Luis Potosi",
      "Sinaloa",
      "Sonora",
      "Tabasco",
      "Tamaulipas",
      "Tlaxcala",
      "Veracruz-Llave",
      "Yucatan",
      "Zacatecas",
    ],
    micronesia: [],
    moldova: [
      "Anenii Noi",
      "Basarabeasca",
      "Briceni",
      "Cahul",
      "Cantemir",
      "Calarasi",
      "Causeni",
      "Cimislia",
      "Criuleni",
      "Donduseni",
      "Drochia",
      "Dubasari",
      "Edinet",
      "Falesti",
      "Floresti",
      "Glodeni",
      "Hincesti",
      "Ialoveni",
      "Leova",
      "Nisporeni",
      "Ocnita",
      "Orhei",
      "Rezina",
      "Riscani",
      "Singerei",
      "Soldanesti",
      "Soroca",
      "Stefan-Voda",
      "Straseni",
      "Taraclia",
      "Telenesti",
      "Ungheni",
      "Balti",
      "Bender",
      "Chisinau",
      "Gagauzia",
      "Stinga Nistrului",
    ],
    monaco: [],
    mongolia: [
      "Arhangay",
      "Bayanhongor",
      "Bayan-Olgiy",
      "Bulgan",
      "Darhan Uul",
      "Dornod",
      "Dornogovi",
      "Dundgovi",
      "Dzavhan",
      "Govi-Altay",
      "Govi-Sumber",
      "Hentiy",
      "Hovd",
      "Hovsgol",
      "Omnogovi",
      "Orhon",
      "Ovorhangay",
      "Selenge",
      "Suhbaatar",
      "Tov",
      "Ulaanbaatar",
      "Uvs",
    ],
    montenegro: [],
    montserrat: [],
    morocco: [
      "Agadir",
      "Al Hoceima",
      "Azilal",
      "Beni Mellal",
      "Ben Slimane",
      "Boulemane",
      "Casablanca",
      "Chaouen",
      "El Jadida",
      "El Kelaa des Sraghna",
      "Er Rachidia",
      "Essaouira",
      "Fes",
      "Figuig",
      "Guelmim",
      "Ifrane",
      "Kenitra",
      "Khemisset",
      "Khenifra",
      "Khouribga",
      "Laayoune",
      "Larache",
      "Marrakech",
      "Meknes",
      "Nador",
      "Ouarzazate",
      "Oujda",
      "Rabat-Sale",
      "Safi",
      "Settat",
      "Sidi Kacem",
      "Tangier",
      "Tan-Tan",
      "Taounate",
      "Taroudannt",
      "Tata",
      "Taza",
      "Tetouan",
      "Tiznit",
    ],
    mozambique: [
      "Cabo Delgado",
      "Gaza",
      "Inhambane",
      "Manica",
      "Maputo",
      "Cidade de Maputo",
      "Nampula",
      "Niassa",
      "Sofala",
      "Tete",
      "Zambezia",
    ],
    myanmar: [],
    namibia: [
      "Caprivi",
      "Erongo",
      "Hardap",
      "Karas",
      "Khomas",
      "Kunene",
      "Ohangwena",
      "Okavango",
      "Omaheke",
      "Omusati",
      "Oshana",
      "Oshikoto",
      "Otjozondjupa",
    ],
    nauru: [],
    nepal: [
      "Bagmati",
      "Bheri",
      "Dhawalagiri",
      "Gandaki",
      "Janakpur",
      "Karnali",
      "Kosi",
      "Lumbini",
      "Mahakali",
      "Mechi",
      "Narayani",
      "Rapti",
      "Sagarmatha",
      "Seti",
    ],
    netherlands: [
      "Drenthe",
      "Flevoland",
      "Friesland",
      "Gelderland",
      "Groningen",
      "Limburg",
      "Noord-Brabant",
      "Noord-Holland",
      "Overijssel",
      "Utrecht",
      "Zeeland",
      "Zuid-Holland",
    ],
    new_caledonia: [],
    new_zealand: [
      "Auckland",
      "Bay of Plenty",
      "Canterbury",
      "Chatham Islands",
      "Gisborne",
      "Hawke's Bay",
      "Manawatu-Wanganui",
      "Marlborough",
      "Nelson",
      "Northland",
      "Otago",
      "Southland",
      "Taranaki",
      "Tasman",
      "Waikato",
      "Wellington",
      "West Coast",
    ],
    nicaragua: [
      "Atlantico Norte",
      "Atlantico Sur",
      "Boaco",
      "Carazo",
      "Chinandega",
      "Chontales",
      "Esteli",
      "Granada",
      "Jinotega",
      "Leon",
      "Madriz",
      "Managua",
      "Masaya",
      "Matagalpa",
      "Nueva Segovia",
      "Rio San Juan",
      "Rivas",
    ],
    niger: [
      "Agadez",
      "Diffa",
      "Dosso",
      "Maradi",
      "Niamey",
      "Tahoua",
      "Tillaberi",
      "Zinder",
    ],
    nigeria: [
      "Abia",
      "Abuja Federal Capital",
      "Adamawa",
      "Akwa Ibom",
      "Anambra",
      "Bauchi",
      "Bayelsa",
      "Benue",
      "Borno",
      "Cross River",
      "Delta",
      "Ebonyi",
      "Edo",
      "Ekiti",
      "Enugu",
      "Gombe",
      "Imo",
      "Jigawa",
      "Kaduna",
      "Kano",
      "Katsina",
      "Kebbi",
      "Kogi",
      "Kwara",
      "Lagos",
      "Nassarawa",
      "Niger",
      "Ogun",
      "Ondo",
      "Osun",
      "Oyo",
      "Plateau",
      "Rivers",
      "Sokoto",
      "Taraba",
      "Yobe",
      "Zamfara",
    ],
    niue: [],
    norfolk_island: [],
    northern_mariana_islands: [],
    norway: [
      "Akershus",
      "Aust-Agder",
      "Buskerud",
      "Finnmark",
      "Hedmark",
      "Hordaland",
      "More og Romsdal",
      "Nordland",
      "Nord-Trondelag",
      "Oppland",
      "Oslo",
      "Ostfold",
      "Rogaland",
      "Sogn og Fjordane",
      "Sor-Trondelag",
      "Telemark",
      "Troms",
      "Vest-Agder",
      "Vestfold",
    ],
    oman: [
      "Ad Dakhiliyah",
      "Al Batinah",
      "Al Wusta",
      "Ash Sharqiyah",
      "Az Zahirah",
      "Masqat",
      "Musandam",
      "Dhofar",
    ],
    pakistan: [
      "Balochistan",
      "North-West Frontier Province",
      "Punjab",
      "Sindh",
      "Islamabad Capital Territory",
      "Federally Administered Tribal Areas",
    ],
    palau: [],
    palestine_state_of: [],
    panama: [
      "Bocas del Toro",
      "Chiriqui",
      "Cocle",
      "Colon",
      "Darien",
      "Herrera",
      "Los Santos",
      "Panama",
      "San Blas",
      "Veraguas",
    ],
    papua_new_guinea: [
      "Bougainville",
      "Central",
      "Chimbu",
      "Eastern Highlands",
      "East New Britain",
      "East Sepik",
      "Enga",
      "Gulf",
      "Madang",
      "Manus",
      "Milne Bay",
      "Morobe",
      "National Capital",
      "New Ireland",
      "Northern",
      "Sandaun",
      "Southern Highlands",
      "Western",
      "Western Highlands",
      "West New Britain",
    ],
    paraguay: [
      "Alto Paraguay",
      "Alto Parana",
      "Amambay",
      "Asuncion",
      "Boqueron",
      "Caaguazu",
      "Caazapa",
      "Canindeyu",
      "Central",
      "Concepcion",
      "Cordillera",
      "Guaira",
      "Itapua",
      "Misiones",
      "Neembucu",
      "Paraguari",
      "Presidente Hayes",
      "San Pedro",
    ],
    peru: [
      "Amazonas",
      "Ancash",
      "Apurimac",
      "Arequipa",
      "Ayacucho",
      "Cajamarca",
      "Callao",
      "Cusco",
      "Huancavelica",
      "Huanuco",
      "Ica",
      "Junin",
      "La Libertad",
      "Lambayeque",
      "Lima",
      "Loreto",
      "Madre de Dios",
      "Moquegua",
      "Pasco",
      "Piura",
      "Puno",
      "San Martin",
      "Tacna",
      "Tumbes",
      "Ucayali",
    ],
    philippines: [
      "Abra",
      "Agusan del Norte",
      "Agusan del Sur",
      "Aklan",
      "Albay",
      "Antique",
      "Apayao",
      "Aurora",
      "Basilan",
      "Bataan",
      "Batanes",
      "Batangas",
      "Biliran",
      "Benguet",
      "Bohol",
      "Bukidnon",
      "Bulacan",
      "Cagayan",
      "Camarines Norte",
      "Camarines Sur",
      "Camiguin",
      "Capiz",
      "Catanduanes",
      "Cavite",
      "Cebu",
      "Compostela",
      "Davao del Norte",
      "Davao del Sur",
      "Davao Oriental",
      "Eastern Samar",
      "Guimaras",
      "Ifugao",
      "Ilocos Norte",
      "Ilocos Sur",
      "Iloilo",
      "Isabela",
      "Kalinga",
      "Laguna",
      "Lanao del Norte",
      "Lanao del Sur",
      "La Union",
      "Leyte",
      "Maguindanao",
      "Marinduque",
      "Masbate",
      "Mindoro Occidental",
      "Mindoro Oriental",
      "Misamis Occidental",
      "Misamis Oriental",
      "Mountain Province",
      "Negros Occidental",
      "Negros Oriental",
      "North Cotabato",
      "Northern Samar",
      "Nueva Ecija",
      "Nueva Vizcaya",
      "Palawan",
      "Pampanga",
      "Pangasinan",
      "Quezon",
      "Quirino",
      "Rizal",
      "Romblon",
      "Samar",
      "Sarangani",
      "Siquijor",
      "Sorsogon",
      "South Cotabato",
      "Southern Leyte",
      "Sultan Kudarat",
      "Sulu",
      "Surigao del Norte",
      "Surigao del Sur",
      "Tarlac",
      "Tawi-Tawi",
      "Zambales",
      "Zamboanga del Norte",
      "Zamboanga del Sur",
      "Zamboanga Sibugay",
    ],
    pitcairn: [],
    poland: [
      "Greater Poland (Wielkopolskie)",
      "Kuyavian-Pomeranian (Kujawsko-Pomorskie)",
      "Lesser Poland (Malopolskie)",
      "Lodz (Lodzkie)",
      "Lower Silesian (Dolnoslaskie)",
      "Lublin (Lubelskie)",
      "Lubusz (Lubuskie)",
      "Masovian (Mazowieckie)",
      "Opole (Opolskie)",
      "Podlasie (Podlaskie)",
      "Pomeranian (Pomorskie)",
      "Silesian (Slaskie)",
      "Subcarpathian (Podkarpackie)",
      "Swietokrzyskie (Swietokrzyskie)",
      "Warmian-Masurian (Warminsko-Mazurskie)",
      "West Pomeranian (Zachodniopomorskie)",
    ],
    portugal: [
      "Aveiro",
      "Acores",
      "Beja",
      "Braga",
      "Braganca",
      "Castelo Branco",
      "Coimbra",
      "Evora",
      "Faro",
      "Guarda",
      "Leiria",
      "Lisboa",
      "Madeira",
      "Portalegre",
      "Porto",
      "Santarem",
      "Setubal",
      "Viana do Castelo",
      "Vila Real",
      "Viseu",
    ],
    puerto_rico: [],
    qatar: [
      "Ad Dawhah",
      "Al Ghuwayriyah",
      "Al Jumayliyah",
      "Al Khawr",
      "Al Wakrah",
      "Ar Rayyan",
      "Jarayan al Batinah",
      "Madinat ash Shamal",
      "Umm Sa'id",
      "Umm Salal",
    ],
    republic_of_north_macedonia: [],
    romania: [
      "Alba",
      "Arad",
      "Arges",
      "Bacau",
      "Bihor",
      "Bistrita-Nasaud",
      "Botosani",
      "Braila",
      "Brasov",
      "Bucuresti",
      "Buzau",
      "Calarasi",
      "Caras-Severin",
      "Cluj",
      "Constanta",
      "Covasna",
      "Dimbovita",
      "Dolj",
      "Galati",
      "Gorj",
      "Giurgiu",
      "Harghita",
      "Hunedoara",
      "Ialomita",
      "Iasi",
      "Ilfov",
      "Maramures",
      "Mehedinti",
      "Mures",
      "Neamt",
      "Olt",
      "Prahova",
      "Salaj",
      "Satu Mare",
      "Sibiu",
      "Suceava",
      "Teleorman",
      "Timis",
      "Tulcea",
      "Vaslui",
      "Vilcea",
      "Vrancea",
    ],
    russian_federation: [
      "Amur",
      "Arkhangel'sk",
      "Astrakhan'",
      "Belgorod",
      "Bryansk",
      "Chelyabinsk",
      "Chita",
      "Irkutsk",
      "Ivanovo",
      "Kaliningrad",
      "Kaluga",
      "Kamchatka",
      "Kemerovo",
      "Kirov",
      "Kostroma",
      "Kurgan",
      "Kursk",
      "Leningrad",
      "Lipetsk",
      "Magadan",
      "Moscow",
      "Murmansk",
      "Nizhniy Novgorod",
      "Novgorod",
      "Novosibirsk",
      "Omsk",
      "Orenburg",
      "Orel",
      "Penza",
      "Perm'",
      "Pskov",
      "Rostov",
      "Ryazan'",
      "Sakhalin",
      "Samara",
      "Saratov",
      "Smolensk",
      "Sverdlovsk",
      "Tambov",
      "Tomsk",
      "Tula",
      "Tver'",
      "Tyumen'",
      "Ul'yanovsk",
      "Vladimir",
      "Volgograd",
      "Vologda",
      "Voronezh",
      "Yaroslavl'",
      "Adygeya",
      "Altay",
      "Bashkortostan",
      "Buryatiya",
      "Chechnya",
      "Chuvashiya",
      "Dagestan",
      "Ingushetiya",
      "Kabardino-Balkariya",
      "Kalmykiya",
      "Karachayevo-Cherkesiya",
      "Kareliya",
      "Khakasiya",
      "Komi",
      "Mariy-El",
      "Mordoviya",
      "Sakha",
      "North Ossetia",
      "Tatarstan",
      "Tyva",
      "Udmurtiya",
      "Aga Buryat",
      "Chukotka",
      "Evenk",
      "Khanty-Mansi",
      "Komi-Permyak",
      "Koryak",
      "Nenets",
      "Taymyr",
      "Ust'-Orda Buryat",
      "Yamalo-Nenets",
      "Altay",
      "Khabarovsk",
      "Krasnodar",
      "Krasnoyarsk",
      "Primorskiy",
      "Stavropol'",
      "Moscow",
      "St. Petersburg",
      "Yevrey",
    ],
    rwanda: [
      "Butare",
      "Byumba",
      "Cyangugu",
      "Gikongoro",
      "Gisenyi",
      "Gitarama",
      "Kibungo",
      "Kibuye",
      "Kigali Rurale",
      "Kigali-ville",
      "Umutara",
      "Ruhengeri",
    ],
    réunion: [],
    saint_barthélemy: [],
    saint_helena_ascension_and_tristan_da_cunha: [],
    saint_kitts_and_nevis: [],
    saint_lucia: [],
    saint_martin: [],
    saint_vincent_and_the_grenadines: [],
    samoa: [
      "A'ana",
      "Aiga-i-le-Tai",
      "Atua",
      "Fa'asaleleaga",
      "Gaga'emauga",
      "Gagaifomauga",
      "Palauli",
      "Satupa'itea",
      "Tuamasaga",
      "Va'a-o-Fonoti",
      "Vaisigano",
    ],
    sao_tome_and_principe: [],
    san_marino: [
      "Acquaviva",
      "Borgo Maggiore",
      "Chiesanuova",
      "Domagnano",
      "Faetano",
      "Fiorentino",
      "Montegiardino",
      "San Marino Citta",
      "Serravalle",
    ],
    saudi_arabia: [
      "Al Bahah",
      "Al Hudud ash Shamaliyah",
      "Al Jawf",
      "Al Madinah",
      "Al Qasim",
      "Ar Riyad",
      "Ash Sharqiyah",
      "'Asir",
      "Ha'il",
      "Jizan",
      "Makkah",
      "Najran",
      "Tabuk",
    ],
    senegal: [
      "Dakar",
      "Diourbel",
      "Fatick",
      "Kaolack",
      "Kolda",
      "Louga",
      "Matam",
      "Saint-Louis",
      "Tambacounda",
      "Thies",
      "Ziguinchor",
    ],
    serbia: ["Kosovo", "Montenegro", "Serbia", "Vojvodina"],
    seychelles: [
      "Anse aux Pins",
      "Anse Boileau",
      "Anse Etoile",
      "Anse Louis",
      "Anse Royale",
      "Baie Lazare",
      "Baie Sainte Anne",
      "Beau Vallon",
      "Bel Air",
      "Bel Ombre",
      "Cascade",
      "Glacis",
      "Grand' Anse",
      "Grand' Anse",
      "La Digue",
      "La Riviere Anglaise",
      "Mont Buxton",
      "Mont Fleuri",
      "Plaisance",
      "Pointe La Rue",
      "Port Glaud",
      "Saint Louis",
      "Takamaka",
    ],
    sierra_leone: [],
    singapore: [],
    sint_maarten: [],
    slovakia: [
      "Banskobystricky",
      "Bratislavsky",
      "Kosicky",
      "Nitriansky",
      "Presovsky",
      "Trenciansky",
      "Trnavsky",
      "Zilinsky",
    ],
    slovenia: [
      "Ajdovscina",
      "Beltinci",
      "Benedikt",
      "Bistrica ob Sotli",
      "Bled",
      "Bloke",
      "Bohinj",
      "Borovnica",
      "Bovec",
      "Braslovce",
      "Brda",
      "Brezice",
      "Brezovica",
      "Cankova",
      "Celje",
      "Cerklje na Gorenjskem",
      "Cerknica",
      "Cerkno",
      "Cerkvenjak",
      "Crensovci",
      "Crna na Koroskem",
      "Crnomelj",
      "Destrnik",
      "Divaca",
      "Dobje",
      "Dobrepolje",
      "Dobrna",
      "Dobrova-Horjul-Polhov Gradec",
      "Dobrovnik-Dobronak",
      "Dolenjske Toplice",
      "Dol pri Ljubljani",
      "Domzale",
      "Dornava",
      "Dravograd",
      "Duplek",
      "Gorenja Vas-Poljane",
      "Gorisnica",
      "Gornja Radgona",
      "Gornji Grad",
      "Gornji Petrovci",
      "Grad",
      "Grosuplje",
      "Hajdina",
      "Hoce-Slivnica",
      "Hodos-Hodos",
      "Horjul",
      "Hrastnik",
      "Hrpelje-Kozina",
      "Idrija",
      "Ig",
      "Ilirska Bistrica",
      "Ivancna Gorica",
      "Izola-Isola",
      "Jesenice",
      "Jezersko",
      "Jursinci",
      "Kamnik",
      "Kanal",
      "Kidricevo",
      "Kobarid",
      "Kobilje",
      "Kocevje",
      "Komen",
      "Komenda",
      "Koper-Capodistria",
      "Kostel",
      "Kozje",
      "Kranj",
      "Kranjska Gora",
      "Krizevci",
      "Krsko",
      "Kungota",
      "Kuzma",
      "Lasko",
      "Lenart",
      "Lendava-Lendva",
      "Litija",
      "Ljubljana",
      "Ljubno",
      "Ljutomer",
      "Logatec",
      "Loska Dolina",
      "Loski Potok",
      "Lovrenc na Pohorju",
      "Luce",
      "Lukovica",
      "Majsperk",
      "Maribor",
      "Markovci",
      "Medvode",
      "Menges",
      "Metlika",
      "Mezica",
      "Miklavz na Dravskem Polju",
      "Miren-Kostanjevica",
      "Mirna Pec",
      "Mislinja",
      "Moravce",
      "Moravske Toplice",
      "Mozirje",
      "Murska Sobota",
      "Muta",
      "Naklo",
      "Nazarje",
      "Nova Gorica",
      "Novo Mesto",
      "Odranci",
      "Oplotnica",
      "Ormoz",
      "Osilnica",
      "Pesnica",
      "Piran-Pirano",
      "Pivka",
      "Podcetrtek",
      "Podlehnik",
      "Podvelka",
      "Polzela",
      "Postojna",
      "Prebold",
      "Preddvor",
      "Prevalje",
      "Ptuj",
      "Puconci",
      "Race-Fram",
      "Radece",
      "Radenci",
      "Radlje ob Dravi",
      "Radovljica",
      "Ravne na Koroskem",
      "Razkrizje",
      "Ribnica",
      "Ribnica na Pohorju",
      "Rogasovci",
      "Rogaska Slatina",
      "Rogatec",
      "Ruse",
      "Salovci",
      "Selnica ob Dravi",
      "Semic",
      "Sempeter-Vrtojba",
      "Sencur",
      "Sentilj",
      "Sentjernej",
      "Sentjur pri Celju",
      "Sevnica",
      "Sezana",
      "Skocjan",
      "Skofja Loka",
      "Skofljica",
      "Slovenj Gradec",
      "Slovenska Bistrica",
      "Slovenske Konjice",
      "Smarje pri Jelsah",
      "Smartno ob Paki",
      "Smartno pri Litiji",
      "Sodrazica",
      "Solcava",
      "Sostanj",
      "Starse",
      "Store",
      "Sveta Ana",
      "Sveti Andraz v Slovenskih Goricah",
      "Sveti Jurij",
      "Tabor",
      "Tisina",
      "Tolmin",
      "Trbovlje",
      "Trebnje",
      "Trnovska Vas",
      "Trzic",
      "Trzin",
      "Turnisce",
      "Velenje",
      "Velika Polana",
      "Velike Lasce",
      "Verzej",
      "Videm",
      "Vipava",
      "Vitanje",
      "Vodice",
      "Vojnik",
      "Vransko",
      "Vrhnika",
      "Vuzenica",
      "Zagorje ob Savi",
      "Zalec",
      "Zavrc",
      "Zelezniki",
      "Zetale",
      "Ziri",
      "Zirovnica",
      "Zuzemberk",
      "Zrece",
    ],
    south_africa: [
      "Eastern Cape",
      "Free State",
      "Gauteng",
      "KwaZulu-Natal",
      "Limpopo",
      "Mpumalanga",
      "North-West",
      "Northern Cape",
      "Western Cape",
    ],
    georgia_and_the_south_sandwich_islands: [],
    south_sudan: [],
    spain: [
      "Andalucia",
      "Aragon",
      "Asturias",
      "Baleares",
      "Ceuta",
      "Canarias",
      "Cantabria",
      "Castilla-La Mancha",
      "Castilla y Leon",
      "Cataluna",
      "Comunidad Valenciana",
      "Extremadura",
      "Galicia",
      "La Rioja",
      "Madrid",
      "Melilla",
      "Murcia",
      "Navarra",
      "Pais Vasco",
    ],
    sri_lanka: [
      "Central",
      "North Central",
      "North Eastern",
      "North Western",
      "Sabaragamuwa",
      "Southern",
      "Uva",
      "Western",
    ],
    sudan: [
      "A'ali an Nil",
      "Al Bahr al Ahmar",
      "Al Buhayrat",
      "Al Jazirah",
      "Al Khartum",
      "Al Qadarif",
      "Al Wahdah",
      "An Nil al Abyad",
      "An Nil al Azraq",
      "Ash Shamaliyah",
      "Bahr al Jabal",
      "Gharb al Istiwa'iyah",
      "Gharb Bahr al Ghazal",
      "Gharb Darfur",
      "Gharb Kurdufan",
      "Janub Darfur",
      "Janub Kurdufan",
      "Junqali",
      "Kassala",
      "Nahr an Nil",
      "Shamal Bahr al Ghazal",
      "Shamal Darfur",
      "Shamal Kurdufan",
      "Sharq al Istiwa'iyah",
      "Sinnar",
      "Warab",
    ],
    suriname: [
      "Brokopondo",
      "Commewijne",
      "Coronie",
      "Marowijne",
      "Nickerie",
      "Para",
      "Paramaribo",
      "Saramacca",
      "Sipaliwini",
      "Wanica",
    ],
    svalbard_and_jan_mayen: [],
    sweden: [
      "Blekinge",
      "Dalarnas",
      "Gavleborgs",
      "Gotlands",
      "Hallands",
      "Jamtlands",
      "Jonkopings",
      "Kalmar",
      "Kronobergs",
      "Norrbottens",
      "Orebro",
      "Ostergotlands",
      "Skane",
      "Sodermanlands",
      "Stockholms",
      "Uppsala",
      "Varmlands",
      "Vasterbottens",
      "Vasternorrlands",
      "Vastmanlands",
      "Vastra Gotalands",
    ],
    switzerland: [
      "Aargau",
      "Appenzell Ausser-Rhoden",
      "Appenzell Inner-Rhoden",
      "Basel-Landschaft",
      "Basel-Stadt",
      "Bern",
      "Fribourg",
      "Geneve",
      "Glarus",
      "Graubunden",
      "Jura",
      "Luzern",
      "Neuchatel",
      "Nidwalden",
      "Obwalden",
      "Sankt Gallen",
      "Schaffhausen",
      "Schwyz",
      "Solothurn",
      "Thurgau",
      "Ticino",
      "Uri",
      "Valais",
      "Vaud",
      "Zug",
      "Zurich",
    ],
    syrian_arab_republic: [
      "Al Hasakah",
      "Al Ladhiqiyah",
      "Al Qunaytirah",
      "Ar Raqqah",
      "As Suwayda'",
      "Dar'a",
      "Dayr az Zawr",
      "Dimashq",
      "Halab",
      "Hamah",
      "Hims",
      "Idlib",
      "Rif Dimashq",
      "Tartus",
    ],
    taiwan: [
      "Chang-hua",
      "Chia-i",
      "Hsin-chu",
      "Hua-lien",
      "I-lan",
      "Kao-hsiung",
      "Kin-men",
      "Lien-chiang",
      "Miao-li",
      "Nan-t'ou",
      "P'eng-hu",
      "P'ing-tung",
      "T'ai-chung",
      "T'ai-nan",
      "T'ai-pei",
      "T'ai-tung",
      "T'ao-yuan",
      "Yun-lin",
      "Chia-i",
      "Chi-lung",
      "Hsin-chu",
      "T'ai-chung",
      "T'ai-nan",
      "Kao-hsiung city",
      "T'ai-pei city",
    ],
    tajikistan: [],
    tanzania_united_republic_of: [
      "Arusha",
      "Dar es Salaam",
      "Dodoma",
      "Iringa",
      "Kagera",
      "Kigoma",
      "Kilimanjaro",
      "Lindi",
      "Manyara",
      "Mara",
      "Mbeya",
      "Morogoro",
      "Mtwara",
      "Mwanza",
      "Pemba North",
      "Pemba South",
      "Pwani",
      "Rukwa",
      "Ruvuma",
      "Shinyanga",
      "Singida",
      "Tabora",
      "Tanga",
      "Zanzibar Central/South",
      "Zanzibar North",
      "Zanzibar Urban/West",
    ],
    thailand: [
      "Amnat Charoen",
      "Ang Thong",
      "Buriram",
      "Chachoengsao",
      "Chai Nat",
      "Chaiyaphum",
      "Chanthaburi",
      "Chiang Mai",
      "Chiang Rai",
      "Chon Buri",
      "Chumphon",
      "Kalasin",
      "Kamphaeng Phet",
      "Kanchanaburi",
      "Khon Kaen",
      "Krabi",
      "Krung Thep Mahanakhon",
      "Lampang",
      "Lamphun",
      "Loei",
      "Lop Buri",
      "Mae Hong Son",
      "Maha Sarakham",
      "Mukdahan",
      "Nakhon Nayok",
      "Nakhon Pathom",
      "Nakhon Phanom",
      "Nakhon Ratchasima",
      "Nakhon Sawan",
      "Nakhon Si Thammarat",
      "Nan",
      "Narathiwat",
      "Nong Bua Lamphu",
      "Nong Khai",
      "Nonthaburi",
      "Pathum Thani",
      "Pattani",
      "Phangnga",
      "Phatthalung",
      "Phayao",
      "Phetchabun",
      "Phetchaburi",
      "Phichit",
      "Phitsanulok",
      "Phra Nakhon Si Ayutthaya",
      "Phrae",
      "Phuket",
      "Prachin Buri",
      "Prachuap Khiri Khan",
      "Ranong",
      "Ratchaburi",
      "Rayong",
      "Roi Et",
      "Sa Kaeo",
      "Sakon Nakhon",
      "Samut Prakan",
      "Samut Sakhon",
      "Samut Songkhram",
      "Sara Buri",
      "Satun",
      "Sing Buri",
      "Sisaket",
      "Songkhla",
      "Sukhothai",
      "Suphan Buri",
      "Surat Thani",
      "Surin",
      "Tak",
      "Trang",
      "Trat",
      "Ubon Ratchathani",
      "Udon Thani",
      "Uthai Thani",
      "Uttaradit",
      "Yala",
      "Yasothon",
    ],
    timor_leste: [],
    togo: ["Kara", "Plateaux", "Savanes", "Centrale", "Maritime"],
    tokelau: [],
    tonga: [],
    trinidad_and_tobago: [
      "Couva",
      "Diego Martin",
      "Mayaro",
      "Penal",
      "Princes Town",
      "Sangre Grande",
      "San Juan",
      "Siparia",
      "Tunapuna",
      "Port-of-Spain",
      "San Fernando",
      "Arima",
      "Point Fortin",
      "Chaguanas",
      "Tobago",
    ],
    tunisia: [
      "Ariana (Aryanah)",
      "Beja (Bajah)",
      "Ben Arous (Bin 'Arus)",
      "Bizerte (Banzart)",
      "Gabes (Qabis)",
      "Gafsa (Qafsah)",
      "Jendouba (Jundubah)",
      "Kairouan (Al Qayrawan)",
      "Kasserine (Al Qasrayn)",
      "Kebili (Qibili)",
      "Kef (Al Kaf)",
      "Mahdia (Al Mahdiyah)",
      "Manouba (Manubah)",
      "Medenine (Madanin)",
      "Monastir (Al Munastir)",
      "Nabeul (Nabul)",
      "Sfax (Safaqis)",
      "Sidi Bou Zid (Sidi Bu Zayd)",
      "Siliana (Silyanah)",
      "Sousse (Susah)",
      "Tataouine (Tatawin)",
      "Tozeur (Tawzar)",
      "Tunis",
      "Zaghouan (Zaghwan)",
    ],
    turkey: [
      "Adana",
      "Adiyaman",
      "Afyonkarahisar",
      "Agri",
      "Aksaray",
      "Amasya",
      "Ankara",
      "Antalya",
      "Ardahan",
      "Artvin",
      "Aydin",
      "Balikesir",
      "Bartin",
      "Batman",
      "Bayburt",
      "Bilecik",
      "Bingol",
      "Bitlis",
      "Bolu",
      "Burdur",
      "Bursa",
      "Canakkale",
      "Cankiri",
      "Corum",
      "Denizli",
      "Diyarbakir",
      "Duzce",
      "Edirne",
      "Elazig",
      "Erzincan",
      "Erzurum",
      "Eskisehir",
      "Gaziantep",
      "Giresun",
      "Gumushane",
      "Hakkari",
      "Hatay",
      "Igdir",
      "Isparta",
      "Istanbul",
      "Izmir",
      "Kahramanmaras",
      "Karabuk",
      "Karaman",
      "Kars",
      "Kastamonu",
      "Kayseri",
      "Kilis",
      "Kirikkale",
      "Kirklareli",
      "Kirsehir",
      "Kocaeli",
      "Konya",
      "Kutahya",
      "Malatya",
      "Manisa",
      "Mardin",
      "Mersin",
      "Mugla",
      "Mus",
      "Nevsehir",
      "Nigde",
      "Ordu",
      "Osmaniye",
      "Rize",
      "Sakarya",
      "Samsun",
      "Sanliurfa",
      "Siirt",
      "Sinop",
      "Sirnak",
      "Sivas",
      "Tekirdag",
      "Tokat",
      "Trabzon",
      "Tunceli",
      "Usak",
      "Van",
      "Yalova",
      "Yozgat",
      "Zonguldak",
    ],
    turkmenistan: [
      "Ahal Welayaty (Ashgabat)",
      "Balkan Welayaty (Balkanabat)",
      "Dashoguz Welayaty",
      "Lebap Welayaty (Turkmenabat)",
      "Mary Welayaty",
    ],
    turks_and_caicos_islands: [],
    tuvalu: [],
    uganda: [
      "Adjumani",
      "Apac",
      "Arua",
      "Bugiri",
      "Bundibugyo",
      "Bushenyi",
      "Busia",
      "Gulu",
      "Hoima",
      "Iganga",
      "Jinja",
      "Kabale",
      "Kabarole",
      "Kaberamaido",
      "Kalangala",
      "Kampala",
      "Kamuli",
      "Kamwenge",
      "Kanungu",
      "Kapchorwa",
      "Kasese",
      "Katakwi",
      "Kayunga",
      "Kibale",
      "Kiboga",
      "Kisoro",
      "Kitgum",
      "Kotido",
      "Kumi",
      "Kyenjojo",
      "Lira",
      "Luwero",
      "Masaka",
      "Masindi",
      "Mayuge",
      "Mbale",
      "Mbarara",
      "Moroto",
      "Moyo",
      "Mpigi",
      "Mubende",
      "Mukono",
      "Nakapiripirit",
      "Nakasongola",
      "Nebbi",
      "Ntungamo",
      "Pader",
      "Pallisa",
      "Rakai",
      "Rukungiri",
      "Sembabule",
      "Sironko",
      "Soroti",
      "Tororo",
      "Wakiso",
      "Yumbe",
    ],
    ukraine: [
      "Cherkasy",
      "Chernihiv",
      "Chernivtsi",
      "Crimea",
      "Dnipropetrovs'k",
      "Donets'k",
      "Ivano-Frankivs'k",
      "Kharkiv",
      "Kherson",
      "Khmel'nyts'kyy",
      "Kirovohrad",
      "Kiev",
      "Kyyiv",
      "Luhans'k",
      "L'viv",
      "Mykolayiv",
      "Odesa",
      "Poltava",
      "Rivne",
      "Sevastopol'",
      "Sumy",
      "Ternopil'",
      "Vinnytsya",
      "Volyn'",
      "Zakarpattya",
      "Zaporizhzhya",
      "Zhytomyr",
    ],
    united_arab_emirates: [
      "Abu Dhabi",
      "'Ajman",
      "Al Fujayrah",
      "Sharjah",
      "Dubai",
      "Ra's al Khaymah",
      "Umm al Qaywayn",
    ],
    united_kingdom_of_great_britain_and_northern_ireland: [],
    united_states_minor_outlying_islands: [],
    usa: [
      "Alabama",
      "Alaska",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "District of Columbia",
      "Florida",
      "Georgia",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
    ],
    uruguay: [
      "Artigas",
      "Canelones",
      "Cerro Largo",
      "Colonia",
      "Durazno",
      "Flores",
      "Florida",
      "Lavalleja",
      "Maldonado",
      "Montevideo",
      "Paysandu",
      "Rio Negro",
      "Rivera",
      "Rocha",
      "Salto",
      "San Jose",
      "Soriano",
      "Tacuarembo",
      "Treinta y Tres",
    ],
    uzbekistan: [
      "Andijon Viloyati",
      "Buxoro Viloyati",
      "Farg'ona Viloyati",
      "Jizzax Viloyati",
      "Namangan Viloyati",
      "Navoiy Viloyati",
      "Qashqadaryo Viloyati",
      "Qaraqalpog'iston Respublikasi",
      "Samarqand Viloyati",
      "Sirdaryo Viloyati",
      "Surxondaryo Viloyati",
      "Toshkent Shahri",
      "Toshkent Viloyati",
      "Xorazm Viloyati",
    ],
    vanuatu: ["Malampa", "Penama", "Sanma", "Shefa", "Tafea", "Torba"],
    venezuela: [
      "Amazonas",
      "Anzoategui",
      "Apure",
      "Aragua",
      "Barinas",
      "Bolivar",
      "Carabobo",
      "Cojedes",
      "Delta Amacuro",
      "Dependencias Federales",
      "Distrito Federal",
      "Falcon",
      "Guarico",
      "Lara",
      "Merida",
      "Miranda",
      "Monagas",
      "Nueva Esparta",
      "Portuguesa",
      "Sucre",
      "Tachira",
      "Trujillo",
      "Vargas",
      "Yaracuy",
      "Zulia",
    ],
    viet_nam: [
      "An Giang",
      "Bac Giang",
      "Bac Kan",
      "Bac Lieu",
      "Bac Ninh",
      "Ba Ria-Vung Tau",
      "Ben Tre",
      "Binh Dinh",
      "Binh Duong",
      "Binh Phuoc",
      "Binh Thuan",
      "Ca Mau",
      "Cao Bang",
      "Dac Lak",
      "Dac Nong",
      "Dien Bien",
      "Dong Nai",
      "Dong Thap",
      "Gia Lai",
      "Ha Giang",
      "Hai Duong",
      "Ha Nam",
      "Ha Tay",
      "Ha Tinh",
      "Hau Giang",
      "Hoa Binh",
      "Hung Yen",
      "Khanh Hoa",
      "Kien Giang",
      "Kon Tum",
      "Lai Chau",
      "Lam Dong",
      "Lang Son",
      "Lao Cai",
      "Long An",
      "Nam Dinh",
      "Nghe An",
      "Ninh Binh",
      "Ninh Thuan",
      "Phu Tho",
      "Phu Yen",
      "Quang Binh",
      "Quang Nam",
      "Quang Ngai",
      "Quang Ninh",
      "Quang Tri",
      "Soc Trang",
      "Son La",
      "Tay Ninh",
      "Thai Binh",
      "Thai Nguyen",
      "Thanh Hoa",
      "Thua Thien-Hue",
      "Tien Giang",
      "Tra Vinh",
      "Tuyen Quang",
      "Vinh Long",
      "Vinh Phuc",
      "Yen Bai",
      "Can Tho",
      "Da Nang",
      "Hai Phong",
      "Hanoi",
      "Ho Chi Minh",
    ],
    virgin_islands_british: [],
    virgin_islands_us: [],
    wallis_and_futuna: [],
    western_sahara: [],
    yemen: [
      "Abyan",
      "'Adan",
      "Ad Dali'",
      "Al Bayda'",
      "Al Hudaydah",
      "Al Jawf",
      "Al Mahrah",
      "Al Mahwit",
      "'Amran",
      "Dhamar",
      "Hadramawt",
      "Hajjah",
      "Ibb",
      "Lahij",
      "Ma'rib",
      "Sa'dah",
      "San'a'",
      "Shabwah",
      "Ta'izz",
    ],
    zambia: [
      "Central",
      "Copperbelt",
      "Eastern",
      "Luapula",
      "Lusaka",
      "Northern",
      "North-Western",
      "Southern",
      "Western",
    ],
    zimbabwe: [
      "Bulawayo",
      "Harare",
      "Manicaland",
      "Mashonaland Central",
      "Mashonaland East",
      "Mashonaland West",
      "Masvingo",
      "Matabeleland North",
      "Matabeleland South",
      "Midlands",
    ],
  };

  country_list: any = [
    { id: "afghanistan", name: "Afghanistan" },
    { id: "albania", name: "Albania" },
    { id: "algeria", name: "Algeria" },
    { id: "american_samoa", name: "American Samoa" },
    { id: "andorra", name: "Andorra" },
    { id: "angola", name: "Angola" },
    { id: "antarctica", name: "Antarctica" },
    { id: "antigua_and_barbuda", name: "antigua_and_barbuda" },
    { id: "argentina", name: "Argentina" },
    { id: "aruba", name: "Aruba" },
    { id: "australia", name: "australia" },
    { id: "austria", name: "Austria" },
    { id: "azerbaijan", name: "Azerbaijan" },
    { id: "bahamas", name: "Bahamas" },
    { id: "bahrain", name: "Bahrain" },
    { id: "bangladesh", name: "Bangladesh" },
    { id: "barbados", name: "Barbados" },
    { id: "belarus", name: "Belarus" },
    { id: "belgium", name: "Belgium" },
    { id: "belize", name: "Belize" },
    { id: "benin", name: "Benin" },
    { id: "bermuda", name: "Bermuda" },
    { id: "bhutan", name: "Bhutan" },
    { id: "bolivia", name: "Bolivia" },
    { id: "bonaire", name: "Bonaire, Sint Eustatius and Saba" },
    { id: "bosnia_and_herzegovina", name: "Bosnia and Herzegovina" },
    { id: "botswana", name: "Botswana" },
    { id: "bouvet_island", name: "Bouvet Island" },
    { id: "brazil", name: "Brazil" },
    {
      id: "british_indian_ocean_territory",
      name: "British Indian Ocean Territory ",
    },
    { id: "brune_darussalam", name: "Brunei Darussalam" },
    { id: "bulgaria", name: "Bulgaria" },
    { id: "burkina_faso", name: "Burkina Faso" },
    { id: "burma", name: "Burma" },
    { id: "burundi", name: "Burundi" },
    { id: "cabo_verde", name: "Cabo Verde" },
    { id: "cambodia", name: "Cambodia" },
    { id: "cameroon", name: "Cameroon" },
    { id: "canada", name: "Canada" },
    { id: "cayman_islands", name: "Cayman Islands" },
    { id: "central_african_republic", name: "Central African Republic (the)" },
    { id: "chad", name: "Chad" },
    { id: "chile", name: "Chile" },
    { id: "china", name: "China" },
    { id: "christmas_island", name: "Christmas Island" },
    { id: "cocos_island", name: "Cocos Island" },
    { id: "colombia", name: "Colombia" },
    { id: "comoros", name: "Comoros (the)" },
    {
      id: "congo_democratic_republic",
      name: "Congo (the Democratic Republic of the)",
    },
    { id: "congo", name: "Congo" },
    { id: "cook_islands", name: "Cook Islands" },
    { id: "costa_rica", name: "Costa Rica" },
    { id: "croatia", name: "Croatia" },
    { id: "cuba", name: "Cuba" },
    { id: "curaçao", name: "Curaçao" },
    { id: "cyprus", name: "cyprus" },
    { id: "czechia", name: "Czechia" },
    { id: "Côte_d_Ivoire", name: "Côte d'Ivoire" },
    { id: "denmark", name: "Denmark" },
    { id: "djibouti", name: "djibouti" },
    { id: "dominica", name: "Dominica" },
    { id: "dominican_republic", name: "Dominican Republic" },
    { id: "ecuador", name: "Ecuador" },
    { id: "egypt", name: "Egypt" },
    { id: "el_Salvador", name: "El Salvador" },
    { id: "equatorial_guinea", name: "Equatorial Guinea" },
    { id: "eritrea", name: "Eritrea" },
    { id: "estonia", name: "Estonia" },
    { id: "eswatini", name: "Eswatini" },
    { id: "ethiopia", name: "Ethiopia" },
    { id: "falkland_islands", name: "Falkland Islands" },
    { id: "faroe_islands", name: "Faroe Islands" },
    { id: "faroe_islands", name: "Faroe Islands" },
    { id: "fiji", name: "Fiji" },
    { id: "finland", name: "Finland" },
    { id: "france", name: "France" },
    { id: "french_guiana", name: "French Guiana" },
    { id: "french_polynesia", name: "French Polynesia" },
    { id: "french_southern_territories", name: "French Southern Territories" },
    { id: "gabon", name: "Gabon" },
    { id: "gambia", name: "Gambia (the)" },
    { id: "georgia", name: "georgia" },
    { id: "germany", name: "Germany" },
    { id: "ghana", name: "Ghana" },
    { id: "gibraltar", name: "Gibraltar" },
    { id: "greece", name: "Greece" },
    { id: "greenland", name: "Greenland" },
    { id: "grenada", name: "Grenada" },
    { id: "guadeloupe", name: "Guadeloupe" },
    { id: "guam", name: "Guam" },
    { id: "guatemala", name: "Guatemala" },
    { id: "guernsey", name: "Guernsey" },
    { id: "guinea", name: "Guinea" },
    { id: "guinea_bissau", name: "Guinea-Bissau" },
    { id: "guyana", name: "Guyana" },
    { id: "haiti", name: "Haiti" },
    {
      id: "heard_island_and_mcDonald_islands",
      name: "Heard Island and McDonald Islands",
    },
    { id: "holy_see", name: "Holy See" },
    { id: "honduras", name: "Honduras" },
    { id: "hong_kong", name: "Hong Kong" },
    { id: "hungary", name: "Hungary" },
    { id: "iceland", name: "Iceland" },
    { id: "india", name: "India" },
    { id: "indonesia", name: "Indonesia" },
    { id: "iran", name: "Iran" },
    { id: "iraq", name: "Iraq" },
    { id: "ireland", name: "Ireland" },
    { id: "Isle_of_man", name: "Isle of Man" },
    { id: "israel", name: "Israel" },
    { id: "italy", name: "Italy" },
    { id: "jamaica", name: "Jamaica" },
    { id: "japan", name: "Japan" },
    { id: "jersey", name: "Jersey" },
    { id: "jordan", name: "Jordan" },
    { id: "kazakhstan", name: "Kazakhstan" },
    { id: "kenya", name: "Kenya" },
    { id: "kiribati", name: "Kiribati" },
    { id: "korea_north", name: "korea_North" },
    { id: "korea_south", name: "korea_South" },
    { id: "kuwait", name: "Kuwait" },
    { id: "kyrgyzstan", name: "Kyrgyzstan" },
    {
      id: "lao_peoples_democratic_republic",
      name: "Lao People's Democratic Republic (the)",
    },
    { id: "latvia", name: "Latvia" },
    { id: "lebanon", name: "Lebanon" },
    { id: "lesotho", name: "Lesotho" },
    { id: "liberia", name: "Liberia" },
    { id: "libya", name: "Libya" },
    { id: "liechtenstein", name: "Liechtenstein" },
    { id: "lithuania", name: "Lithuania" },
    { id: "luxembourg", name: "Luxembourg" },
    { id: "macao", name: "Macao" },
    { id: "madagascar", name: "Madagascar" },
    { id: "malawi", name: "Malawi" },
    { id: "malaysia", name: "Malaysia" },
    { id: "maldives", name: "Maldives" },
    { id: "mali", name: "Mali" },
    { id: "malta", name: "Malta" },
    { id: "marshall_islands", name: "Marshall Islands" },
    { id: "martinique", name: "Martinique" },
    { id: "mauritania", name: "Mauritania" },
    { id: "mauritius", name: "Mauritius" },
    { id: "mayotte", name: "Mayotte" },
    { id: "mexico", name: "Mexico" },
    { id: "micronesia", name: "Micronesia" },
    { id: "moldova", name: "Moldova" },
    { id: "monaco", name: "Monaco" },
    { id: "mongolia", name: "Mongolia" },
    { id: "montenegro", name: "Montenegro" },
    { id: "montserrat", name: "Montserrat" },
    { id: "morocco", name: "Morocco" },
    { id: "mozambique", name: "Mozambique" },
    { id: "myanmar", name: "Myanmar" },
    { id: "namibia", name: "Namibia" },
    { id: "nauru", name: "Nauru" },
    { id: "nepal", name: "Nepal" },
    { id: "netherlands", name: "Netherlands" },
    { id: "new_caledonia", name: "New Caledonia" },
    { id: "new_zealand", name: "New Zealand" },
    { id: "Nicaragua", name: "Nicaragua" },
    { id: "niger", name: "Niger" },
    { id: "nigeria", name: "Nigeria" },
    { id: "niue", name: "Niue" },
    { id: "norfolk_island", name: "Norfolk Island" },
    { id: "northern_mariana_islands", name: "Northern Mariana Islands" },
    { id: "norway", name: "Norway" },
    { id: "oman", name: "Oman" },
    { id: "pakistan", name: "Pakistan" },
    { id: "palau", name: "Palau" },
    { id: "palestine_state_of", name: "Palestine, State of" },
    { id: "panama", name: "Panama" },
    { id: "papua_new_guinea", name: "Papua New Guinea" },
    { id: "paraguay", name: "Paraguay" },
    { id: "peru", name: "Peru" },
    { id: "philippines", name: "Philippines" },
    { id: "pitcairn", name: "Pitcairn" },
    { id: "poland", name: "Poland" },
    { id: "portugal", name: "Portugal" },
    { id: "puerto_rico", name: "Puerto Rico" },
    { id: "qatar", name: "Qatar" },
    { id: "republic_of_north_macedonia", name: "Republic of North Macedonia" },
    { id: "romania", name: "Romania" },
    { id: "russian_federation", name: "Russian Federation" },
    { id: "rwanda", name: "Rwanda" },
    { id: "réunion", name: "Réunion" },
    { id: "saint_barthélemy", name: "Saint Barthélemy" },
    {
      id: "saint_helena_ascension_and_tristan_da_cunha",
      name: "Saint Helena, Ascension and Tristan da Cunha",
    },
    { id: "saint_kitts_and_nevis", name: "Saint Kitts and Nevis" },
    { id: "saint_lucia", name: "Saint Lucia" },
    { id: "saint_pierre_and_miquelon", name: "Saint Pierre and Miquelon" },
    {
      id: "saint_vincent_and_the_grenadines",
      name: "Saint Vincent and the Grenadines",
    },
    { id: "samoa", name: "Samoa" },
    { id: "san_marino", name: "San Marino" },
    { id: "saint_martin", name: "Saint Martin" },
    { id: "sao_tome_and_principe", name: "Sao Tome and Principe" },
    { id: "saudi_arabia", name: "Saudi Arabia" },
    { id: "senegal", name: "Senegal" },
    { id: "serbia", name: "Serbia" },
    { id: "seychelles", name: "Seychelles" },
    { id: "sierra_leone", name: "Sierra Leone" },
    { id: "singapore", name: "Singapore" },
    { id: "sint_maarten ", name: "Sint Maarten (Dutch part)" },
    { id: "slovakia", name: "slovakia" },
    { id: "slovenia", name: "Slovenia" },
    { id: "south_africa", name: "South Africa" },
    {
      id: "south-georgia_and_the_south_sandwich_islands",
      name: "South Georgia and the South Sandwich Islands",
    },
    { id: "south_sudan", name: "South Sudan" },
    { id: "spain", name: "Spain" },
    { id: "sri_lanka", name: "Sri Lanka" },
    { id: "sudan", name: "Sudan" },
    { id: "suriname", name: "Suriname" },
    { id: "svalbard_and_jan_mayen", name: "Svalbard and Jan Mayen" },
    { id: "sweden", name: "Sweden" },
    { id: "switzerland", name: "Switzerland" },
    { id: "syrian_arab_republic", name: "Syrian Arab Republic" },
    { id: "taiwan", name: "Taiwan" },
    { id: "tajikistan", name: "Tajikistan" },
    { id: "tanzania_united_republic_of", name: "Tanzania, United Republic of" },
    { id: "thailand", name: "Thailand" },
    { id: "timor_leste", name: "Timor-Leste" },
    { id: "togo", name: "Togo" },
    { id: "tokelau", name: "Tokelau" },
    { id: "tonga", name: "Tonga" },
    { id: "trinidad_and_tobago", name: "Trinidad and Tobago" },
    { id: "tunisia", name: "Tunisia" },
    { id: "turkey", name: "Turkey" },
    { id: "turkmenistan", name: "Turkmenistan" },
    { id: "turks_and_caicos_islands", name: "Turks and Caicos Islands (the)" },
    { id: "tuvalu", name: "Tuvalu" },
    { id: "uganda", name: "Uganda" },
    { id: "ukraine", name: "Ukraine" },
    { id: "united_arab_emirates", name: "United Arab Emirates" },
    {
      id: "united_kingdom_of_great_britain_and_northern_ireland",
      name: "United Kingdom of Great Britain and Northern Ireland (the)",
    },
    {
      id: "united_states_minor_outlying_islands ",
      name: "United States Minor Outlying Islands (the)",
    },
    { id: "usa", name: "USA" },
    { id: "uruguay", name: "Uruguay" },
    { id: "uzbekistan", name: "Uzbekistan" },
    { id: "vanuatu", name: "Vanuatu" },
    { id: "venezuela", name: "Venezuela (Bolivarian Republic of)" },
    { id: "viet_nam", name: "Viet Nam" },
    { id: "virgin_islands_british", name: "Virgin Islands (British)" },
    { id: "virgin_islands_us", name: "Virgin Islands (U.S.)" },
    { id: "wallis_and_futuna", name: "Wallis-and-Futuna" },
    { id: "western_sahara", name: "Western-Sahara" },
    { id: "yemen", name: "Yemen" },
    { id: "zambia", name: "Zambia" },
    { id: "zimbabwe", name: "Zimbabwe" },
  ];

  countryCode: any = [
    {
      name: "afghanistan",
      dial_code: "+93",
      code: "AF",
    },
    {
      name: "Aland Islands",
      dial_code: "+358",
      code: "AX",
    },
    {
      name: "albania",
      dial_code: "+355",
      code: "AL",
    },
    {
      name: "algeria",
      dial_code: "+213",
      code: "DZ",
    },
    {
      name: "american_samoa",
      dial_code: "+1684",
      code: "AS",
    },
    {
      name: "andorra",
      dial_code: "+376",
      code: "AD",
    },
    {
      name: "angola",
      dial_code: "+244",
      code: "AO",
    },
    {
      name: "anguilla",
      dial_code: "+1264",
      code: "AI",
    },
    {
      name: "antarctica",
      dial_code: "+672",
      code: "AQ",
    },
    {
      name: "antigua_and_barbuda",
      dial_code: "+1268",
      code: "AG",
    },
    {
      name: "argentina",
      dial_code: "+54",
      code: "AR",
    },
    {
      name: "armenia",
      dial_code: "+374",
      code: "AM",
    },
    {
      name: "aruba",
      dial_code: "+297",
      code: "AW",
    },
    {
      name: "australia",
      dial_code: "+61",
      code: "AU",
    },
    {
      name: "austria",
      dial_code: "+43",
      code: "AT",
    },
    {
      name: "azerbaijan",
      dial_code: "+994",
      code: "AZ",
    },
    {
      name: "bahamas",
      dial_code: "+1242",
      code: "BS",
    },
    {
      name: "bahrain",
      dial_code: "+973",
      code: "BH",
    },
    {
      name: "bangladesh",
      dial_code: "+880",
      code: "BD",
    },
    {
      name: "barbados",
      dial_code: "+1246",
      code: "BB",
    },
    {
      name: "belarus",
      dial_code: "+375",
      code: "BY",
    },
    {
      name: "belgium",
      dial_code: "+32",
      code: "BE",
    },
    {
      name: "belize",
      dial_code: "+501",
      code: "BZ",
    },
    {
      name: "benin",
      dial_code: "+229",
      code: "BJ",
    },
    {
      name: "bermuda",
      dial_code: "+1441",
      code: "BM",
    },
    {
      name: "bhutan",
      dial_code: "+975",
      code: "BT",
    },
    {
      name: "bolivia",
      dial_code: "+591",
      code: "BO",
    },
    {
      name: "bosnia_and_herzegovina",
      dial_code: "+387",
      code: "BA",
    },
    {
      name: "botswana",
      dial_code: "+267",
      code: "BW",
    },
    {
      name: "brazil",
      dial_code: "+55",
      code: "BR",
    },
    {
      name: "british_Indian_Ocean_Territory",
      dial_code: "+246",
      code: "IO",
    },
    {
      name: "brune_darussalam",
      dial_code: "+673",
      code: "BN",
    },
    {
      name: "bulgaria",
      dial_code: "+359",
      code: "BG",
    },
    {
      name: "burkina_faso",
      dial_code: "+226",
      code: "BF",
    },
    {
      name: "burundi",
      dial_code: "+257",
      code: "BI",
    },
    {
      name: "cambodia",
      dial_code: "+855",
      code: "KH",
    },
    {
      name: "cameroon",
      dial_code: "+237",
      code: "CM",
    },
    {
      name: "canada",
      dial_code: "+1",
      code: "CA",
    },
    {
      name: "Cape Verde",
      dial_code: "+238",
      code: "CV",
    },
    {
      name: "cayman_islands",
      dial_code: "+ 345",
      code: "KY",
    },
    {
      name: "central_african_republic",
      dial_code: "+236",
      code: "CF",
    },
    {
      name: "chad",
      dial_code: "+235",
      code: "TD",
    },
    {
      name: "chile",
      dial_code: "+56",
      code: "CL",
    },
    {
      name: "china",
      dial_code: "+86",
      code: "CN",
    },
    {
      name: "christmas_island",
      dial_code: "+61",
      code: "CX",
    },
    {
      name: "cocos_island",
      dial_code: "+61",
      code: "CC",
    },
    {
      name: "colombia",
      dial_code: "+57",
      code: "CO",
    },
    {
      name: "comoros",
      dial_code: "+269",
      code: "KM",
    },
    {
      name: "congo",
      dial_code: "+242",
      code: "CG",
    },
    {
      name: "congo_democratic_republic",
      dial_code: "+243",
      code: "CD",
    },
    {
      name: "cook_islands",
      dial_code: "+682",
      code: "CK",
    },
    {
      name: "costa_rica",
      dial_code: "+506",
      code: "CR",
    },
    {
      name: "Cote d'Ivoire",
      dial_code: "+225",
      code: "CI",
    },
    {
      name: "croatia",
      dial_code: "+385",
      code: "HR",
    },
    {
      name: "cuba",
      dial_code: "+53",
      code: "CU",
    },
    {
      name: "cyprus",
      dial_code: "+357",
      code: "CY",
    },
    {
      name: "czechia",
      dial_code: "+420",
      code: "CZ",
    },
    {
      name: "denmark",
      dial_code: "+45",
      code: "DK",
    },
    {
      name: "djibouti",
      dial_code: "+253",
      code: "DJ",
    },
    {
      name: "dominica",
      dial_code: "+1767",
      code: "DM",
    },
    {
      name: "dominican_republic",
      dial_code: "+1849",
      code: "DO",
    },
    {
      name: "ecuador",
      dial_code: "+593",
      code: "EC",
    },
    {
      name: "egypt",
      dial_code: "+20",
      code: "EG",
    },
    {
      name: "el_Salvador",
      dial_code: "+503",
      code: "SV",
    },
    {
      name: "equatorial_guinea",
      dial_code: "+240",
      code: "GQ",
    },
    {
      name: "eritrea",
      dial_code: "+291",
      code: "ER",
    },
    {
      name: "estonia",
      dial_code: "+372",
      code: "EE",
    },
    {
      name: "ethiopia",
      dial_code: "+251",
      code: "ET",
    },
    {
      name: "falkland_islands",
      dial_code: "+500",
      code: "FK",
    },
    {
      name: "faroe_islands",
      dial_code: "+298",
      code: "FO",
    },
    {
      name: "fiji",
      dial_code: "+679",
      code: "FJ",
    },
    {
      name: "finland",
      dial_code: "+358",
      code: "FI",
    },
    {
      name: "france",
      dial_code: "+33",
      code: "FR",
    },
    {
      name: "french_guiana",
      dial_code: "+594",
      code: "GF",
    },
    {
      name: "french_polynesia",
      dial_code: "+689",
      code: "PF",
    },
    {
      name: "gabon",
      dial_code: "+241",
      code: "GA",
    },
    {
      name: "gambia",
      dial_code: "+220",
      code: "GM",
    },
    {
      name: "georgia",
      dial_code: "+995",
      code: "GE",
    },
    {
      name: "germany",
      dial_code: "+49",
      code: "DE",
    },
    {
      name: "ghana",
      dial_code: "+233",
      code: "GH",
    },
    {
      name: "gibraltar",
      dial_code: "+350",
      code: "GI",
    },
    {
      name: "greece",
      dial_code: "+30",
      code: "GR",
    },
    {
      name: "greenland",
      dial_code: "+299",
      code: "GL",
    },
    {
      name: "grenada",
      dial_code: "+1473",
      code: "GD",
    },
    {
      name: "guadeloupe",
      dial_code: "+590",
      code: "GP",
    },
    {
      name: "guam",
      dial_code: "+1671",
      code: "GU",
    },
    {
      name: "guatemala",
      dial_code: "+502",
      code: "GT",
    },
    {
      name: "guernsey",
      dial_code: "+44",
      code: "GG",
    },
    {
      name: "guinea",
      dial_code: "+224",
      code: "GN",
    },
    {
      name: "guinea_bissau",
      dial_code: "+245",
      code: "GW",
    },
    {
      name: "guyana",
      dial_code: "+595",
      code: "GY",
    },
    {
      name: "haiti",
      dial_code: "+509",
      code: "HT",
    },
    {
      name: "holy_see",
      dial_code: "+379",
      code: "VA",
    },
    {
      name: "honduras",
      dial_code: "+504",
      code: "HN",
    },
    {
      name: "hong_kong",
      dial_code: "+852",
      code: "HK",
    },
    {
      name: "hungary",
      dial_code: "+36",
      code: "HU",
    },
    {
      name: "iceland",
      dial_code: "+354",
      code: "IS",
    },
    {
      name: "india",
      dial_code: "+91",
      code: "IN",
    },
    {
      name: "indonesia",
      dial_code: "+62",
      code: "ID",
    },
    {
      name: "iran",
      dial_code: "+98",
      code: "IR",
    },
    {
      name: "iraq",
      dial_code: "+964",
      code: "IQ",
    },
    {
      name: "ireland",
      dial_code: "+353",
      code: "IE",
    },
    {
      name: "isle_of_man",
      dial_code: "+44",
      code: "IM",
    },
    {
      name: "israel",
      dial_code: "+972",
      code: "IL",
    },
    {
      name: "italy",
      dial_code: "+39",
      code: "IT",
    },
    {
      name: "jamaica",
      dial_code: "+1876",
      code: "JM",
    },
    {
      name: "japan",
      dial_code: "+81",
      code: "JP",
    },
    {
      name: "jersey",
      dial_code: "+44",
      code: "JE",
    },
    {
      name: "jordan",
      dial_code: "+962",
      code: "JO",
    },
    {
      name: "kazakhstan",
      dial_code: "+77",
      code: "KZ",
    },
    {
      name: "kenya",
      dial_code: "+254",
      code: "KE",
    },
    {
      name: "kiribati",
      dial_code: "+686",
      code: "KI",
    },
    {
      name: "korea_north",
      dial_code: "+850",
      code: "KP",
    },
    {
      name: "korea_south",
      dial_code: "+82",
      code: "KR",
    },
    {
      name: "kuwait",
      dial_code: "+965",
      code: "KW",
    },
    {
      name: "kyrgyzstan",
      dial_code: "+996",
      code: "KG",
    },
    {
      name: "laos",
      dial_code: "+856",
      code: "LA",
    },
    {
      name: "latvia",
      dial_code: "+371",
      code: "LV",
    },
    {
      name: "lebanon",
      dial_code: "+961",
      code: "LB",
    },
    {
      name: "lesotho",
      dial_code: "+266",
      code: "LS",
    },
    {
      name: "liberia",
      dial_code: "+231",
      code: "LR",
    },
    {
      name: "libya",
      dial_code: "+218",
      code: "LY",
    },
    {
      name: "liechtenstein",
      dial_code: "+423",
      code: "LI",
    },
    {
      name: "lithuania",
      dial_code: "+370",
      code: "LT",
    },
    {
      name: "luxembourg",
      dial_code: "+352",
      code: "LU",
    },
    {
      name: "macao",
      dial_code: "+853",
      code: "MO",
    },
    {
      name: "macedonia",
      dial_code: "+389",
      code: "MK",
    },
    {
      name: "madagascar",
      dial_code: "+261",
      code: "MG",
    },
    {
      name: "malawi",
      dial_code: "+265",
      code: "MW",
    },
    {
      name: "malaysia",
      dial_code: "+60",
      code: "MY",
    },
    {
      name: "maldives",
      dial_code: "+960",
      code: "MV",
    },
    {
      name: "mali",
      dial_code: "+223",
      code: "ML",
    },
    {
      name: "malta",
      dial_code: "+356",
      code: "MT",
    },
    {
      name: "marshall_islands",
      dial_code: "+692",
      code: "MH",
    },
    {
      name: "martinique",
      dial_code: "+596",
      code: "MQ",
    },
    {
      name: "mauritania",
      dial_code: "+222",
      code: "MR",
    },
    {
      name: "mauritius",
      dial_code: "+230",
      code: "MU",
    },
    {
      name: "mayotte",
      dial_code: "+262",
      code: "YT",
    },
    {
      name: "mexico",
      dial_code: "+52",
      code: "MX",
    },
    {
      name: "micronesia",
      dial_code: "+691",
      code: "FM",
    },
    {
      name: "moldova",
      dial_code: "+373",
      code: "MD",
    },
    {
      name: "monaco",
      dial_code: "+377",
      code: "MC",
    },
    {
      name: "mongolia",
      dial_code: "+976",
      code: "MN",
    },
    {
      name: "montenegro",
      dial_code: "+382",
      code: "ME",
    },
    {
      name: "montserrat",
      dial_code: "+1664",
      code: "MS",
    },
    {
      name: "morocco",
      dial_code: "+212",
      code: "MA",
    },
    {
      name: "mozambique",
      dial_code: "+258",
      code: "MZ",
    },
    {
      name: "myanmar",
      dial_code: "+95",
      code: "MM",
    },
    {
      name: "namibia",
      dial_code: "+264",
      code: "NA",
    },
    {
      name: "nauru",
      dial_code: "+674",
      code: "NR",
    },
    {
      name: "nepal",
      dial_code: "+977",
      code: "NP",
    },
    {
      name: "netherlands",
      dial_code: "+31",
      code: "NL",
    },
    {
      name: "Netherlands Antilles",
      dial_code: "+599",
      code: "AN",
    },
    {
      name: "new_caledonia",
      dial_code: "+687",
      code: "NC",
    },
    {
      name: "new_zealand",
      dial_code: "+64",
      code: "NZ",
    },
    {
      name: "nicaragua",
      dial_code: "+505",
      code: "NI",
    },
    {
      name: "niger",
      dial_code: "+227",
      code: "NE",
    },
    {
      name: "nigeria",
      dial_code: "+234",
      code: "NG",
    },
    {
      name: "niue",
      dial_code: "+683",
      code: "NU",
    },
    {
      name: "norfolk_island",
      dial_code: "+672",
      code: "NF",
    },
    {
      name: "northern_mariana_islands",
      dial_code: "+1670",
      code: "MP",
    },
    {
      name: "norway",
      dial_code: "+47",
      code: "NO",
    },
    {
      name: "oman",
      dial_code: "+968",
      code: "OM",
    },
    {
      name: "pakistan",
      dial_code: "+92",
      code: "PK",
    },
    {
      name: "palau",
      dial_code: "+680",
      code: "PW",
    },
    {
      name: "palestine_state_of",
      dial_code: "+970",
      code: "PS",
    },
    {
      name: "panama",
      dial_code: "+507",
      code: "PA",
    },
    {
      name: "papua_new_guinea",
      dial_code: "+675",
      code: "PG",
    },
    {
      name: "paraguay",
      dial_code: "+595",
      code: "PY",
    },
    {
      name: "peru",
      dial_code: "+51",
      code: "PE",
    },
    {
      name: "philippines",
      dial_code: "+63",
      code: "PH",
    },
    {
      name: "pitcairn",
      dial_code: "+872",
      code: "PN",
    },
    {
      name: "poland",
      dial_code: "+48",
      code: "PL",
    },
    {
      name: "portugal",
      dial_code: "+351",
      code: "PT",
    },
    {
      name: "puerto_rico",
      dial_code: "+1939",
      code: "PR",
    },
    {
      name: "qatar",
      dial_code: "+974",
      code: "QA",
    },
    {
      name: "romania",
      dial_code: "+40",
      code: "RO",
    },
    {
      name: "russian_federation",
      dial_code: "+7",
      code: "RU",
    },
    {
      name: "rwanda",
      dial_code: "+250",
      code: "RW",
    },
    {
      name: "réunion",
      dial_code: "+262",
      code: "RE",
    },
    {
      name: "saint_barthélemy",
      dial_code: "+590",
      code: "BL",
    },
    {
      name: "saint_helena_ascension_and_tristan_da_cunha",
      dial_code: "+290",
      code: "SH",
    },
    {
      name: "saint_kitts_and_nevis",
      dial_code: "+1869",
      code: "KN",
    },
    {
      name: "saint_lucia",
      dial_code: "+1758",
      code: "LC",
    },
    {
      name: "saint_martin",
      dial_code: "+590",
      code: "MF",
    },
    {
      name: "Saint Pierre and Miquelon",
      dial_code: "+508",
      code: "PM",
    },
    {
      name: "saint_vincent_and_the_grenadines",
      dial_code: "+1784",
      code: "VC",
    },
    {
      name: "samoa",
      dial_code: "+685",
      code: "WS",
    },
    {
      name: "san_marino",
      dial_code: "+378",
      code: "SM",
    },
    {
      name: "Sao Tome and Principe",
      dial_code: "+239",
      code: "ST",
    },
    {
      name: "saudi_arabia",
      dial_code: "+966",
      code: "SA",
    },
    {
      name: "senegal",
      dial_code: "+221",
      code: "SN",
    },
    {
      name: "serbia",
      dial_code: "+381",
      code: "RS",
    },
    {
      name: "seychelles",
      dial_code: "+248",
      code: "SC",
    },
    {
      name: "sierra_leone",
      dial_code: "+232",
      code: "SL",
    },
    {
      name: "singapore",
      dial_code: "+65",
      code: "SG",
    },
    {
      name: "slovakia",
      dial_code: "+421",
      code: "SK",
    },
    {
      name: "slovenia",
      dial_code: "+386",
      code: "SI",
    },
    {
      name: "Solomon Islands",
      dial_code: "+677",
      code: "SB",
    },
    {
      name: "Somalia",
      dial_code: "+252",
      code: "SO",
    },
    {
      name: "south_africa",
      dial_code: "+27",
      code: "ZA",
    },
    {
      name: "south_sudan",
      dial_code: "+211",
      code: "SS",
    },
    {
      name: "georgia_and_the_south_sandwich_islands",
      dial_code: "+500",
      code: "GS",
    },
    {
      name: "spain",
      dial_code: "+34",
      code: "ES",
    },
    {
      name: "sri_lanka",
      dial_code: "+94",
      code: "LK",
    },
    {
      name: "sudan",
      dial_code: "+249",
      code: "SD",
    },
    {
      name: "suriname",
      dial_code: "+597",
      code: "SR",
    },
    {
      name: "svalbard_and_jan_mayen",
      dial_code: "+47",
      code: "SJ",
    },
    {
      name: "swaziland",
      dial_code: "+268",
      code: "SZ",
    },
    {
      name: "sweden",
      dial_code: "+46",
      code: "SE",
    },
    {
      name: "switzerland",
      dial_code: "+41",
      code: "CH",
    },
    {
      name: "syrian_arab_republic",
      dial_code: "+963",
      code: "SY",
    },
    {
      name: "taiwan",
      dial_code: "+886",
      code: "TW",
    },
    {
      name: "tajikistan",
      dial_code: "+992",
      code: "TJ",
    },
    {
      name: "tanzania_united_republic_of",
      dial_code: "+255",
      code: "TZ",
    },
    {
      name: "thailand",
      dial_code: "+66",
      code: "TH",
    },
    {
      name: "timor_leste",
      dial_code: "+670",
      code: "TL",
    },
    {
      name: "togo",
      dial_code: "+228",
      code: "TG",
    },
    {
      name: "tokelau",
      dial_code: "+690",
      code: "TK",
    },
    {
      name: "tonga",
      dial_code: "+676",
      code: "TO",
    },
    {
      name: "trinidad_and_tobago",
      dial_code: "+1868",
      code: "TT",
    },
    {
      name: "tunisia",
      dial_code: "+216",
      code: "TN",
    },
    {
      name: "turkey",
      dial_code: "+90",
      code: "TR",
    },
    {
      name: "turkmenistan",
      dial_code: "+993",
      code: "TM",
    },
    {
      name: "turks_and_caicos_islands",
      dial_code: "+1649",
      code: "TC",
    },
    {
      name: "tuvalu",
      dial_code: "+688",
      code: "TV",
    },
    {
      name: "uganda",
      dial_code: "+256",
      code: "UG",
    },
    {
      name: "ukraine",
      dial_code: "+380",
      code: "UA",
    },
    {
      name: "united_arab_emirates",
      dial_code: "+971",
      code: "AE",
    },
    {
      name: "united_kingdom_of_great_britain_and_northern_ireland",
      dial_code: "+44",
      code: "GB",
    },
    {
      name: "usa",
      dial_code: "+1",
      code: "US",
    },
    {
      name: "uruguay",
      dial_code: "+598",
      code: "UY",
    },
    {
      name: "uzbekistan",
      dial_code: "+998",
      code: "UZ",
    },
    {
      name: "vanuatu",
      dial_code: "+678",
      code: "VU",
    },
    {
      name: "venezuela",
      dial_code: "+58",
      code: "VE",
    },
    {
      name: "viet_nam",
      dial_code: "+84",
      code: "VN",
    },
    {
      name: "virgin_islands_british",
      dial_code: "+1284",
      code: "VG",
    },
    {
      name: "virgin_islands_us",
      dial_code: "+1340",
      code: "VI",
    },
    {
      name: "wallis_and_futuna",
      dial_code: "+681",
      code: "WF",
    },
    {
      name: "yemen",
      dial_code: "+967",
      code: "YE",
    },
    {
      name: "zambia",
      dial_code: "+260",
      code: "ZM",
    },
    {
      name: "zimbabwe",
      dial_code: "+263",
      code: "ZW",
    },
  ];

  organization_logo_data: any = "";
  time_zones = moment.tz.names();

  constructor(
    public modalRef: BsModalRef,
    public _toastMessageService: ToastMessageService,
    public commonHelper: CommonHelper,
    public organizationManagementService: OrganizationManagementService,
    public churchUserTypeService: ChurchUserTypeService,
    public pastorateDetailService: PastorateDetailService
  ) {
    super(modalRef);
    this.tzNames = moment.tz.names();
    // this.timeZoneChanged('America/New_York');
  }
  public timeZoneChanged(timeZone: string): void {
    this.selectedTz = timeZone;

    this.updateTime(timeZone);
  }

  private updateTime(timeZone: string) {
    const currentTime = new Date().getTime();

    this.utcDate = moment(currentTime).utc();

    this.tzDate = moment(currentTime).tz(timeZone);
  }

  ngOnInit() {
    this.getParentOrgnizationList();
    this.getChurchTypeList();

    this.getPastorateUnionDetails();
    setTimeout(() => {
      if (this.organization_obj._id) {
        this.organization_obj.church_type = this.organization_obj.church_type._id;
        this.getPastorateUnionDetails();
        this.onPastorateUnionChange(this.organization_obj.church_type);

        if (this.organization_obj.parent_orgnization != null) {
          this.organization_obj.parent_orgnization =
            this.organization_obj.parent_orgnization._id;
        }
      }
    }, 300);
  }

  getParentOrgnizationList() {
    this.loading = true;
    let params = {
      page: 1,
      limit: 1000,
    };
    this.organizationManagementService.getOrganization(params).subscribe(
      (res: any) => {
        this.loading = false;
        if (res.status == 200 && res.data) {
          this.parent_org_list = res.data.data;
          const noParentOption = {
            _id: "No Parent",
            organization_name: "No Parent",
          };
          if (
            !this.parent_org_list.some(
              (parent) => parent._id === noParentOption._id
            )
          ) {
            this.parent_org_list.unshift(noParentOption);
          }
        }
      },
      (error) => {
        this.loading = false;
        this.commonHelper.showError(error);
      }
    );
  }

  getChurchTypeList() {
    return new Promise((resolve, reject) => {
      this.churchUserTypeService.getAChurchTypes().subscribe(
        (response: any) => {
          this.default_church_type_list = response.data.data.map((d) => {
            return {
              _id: d._id,
              text: d.organization_name,
              organization_name: d.organization_name,
              is_active: d.is_active,
            };
          });
          return resolve({});
        },
        (err) => {
          this.commonHelper.showError(err);
          return resolve({});
        }
      );
    });
  }

  onCancel() {
    this.decision = "cancel";
    this.close(true);
  }

  getCountryCode(event) {
    this.countryCode.forEach((element) => {
      if (element.name == event) {
        this.organization_obj.country_code = element.dial_code;
      }
    });
  }

  submit() {
    if (
      !this.organization_obj.organization_name ||
      !this.organization_obj.organization_name.trim()
    ) {
      this._toastMessageService.alert("error", "Please add organization name");
      return;
    }

    let sData = JSON.parse(JSON.stringify(this.organization_obj));
    sData.organization_phone_number = sData.organization_phone_number
      ? sData.organization_phone_number.trim().replace(/\D/g, "")
      : "";
    if (!this.organization_obj._id) {
      //add new organization
      this.isDoneBtnDisabled = true;
      delete sData._id;
      this.organizationManagementService.addOrganization(sData).subscribe(
        (response: any) => {
          this.response_data = response.data;
          if (this.organization_logo_data) {
            this.changeOrganizationLogo(response.data._id).then(
              (logoResult: any) => {
                this._toastMessageService.alert(
                  "success",
                  "Organization data added successfully."
                );
                this.getParentOrgnizationList();
                this.organization_obj.organization_logo_url =
                  logoResult.data && logoResult.data.organization_logo_url
                    ? environment.url +
                      logoResult.data.organization_logo_url +
                      "?t=" +
                      new Date().getTime()
                    : "";
                this.response_data.organization_logo_url =
                  this.organization_obj.organization_logo_url;
                this.isDoneBtnDisabled = false;
                this.done();
                this.getParentOrgnizationList();
              }
            );
          } else {
            this._toastMessageService.alert(
              "success",
              "Organization data added successfully."
            );
            this.isDoneBtnDisabled = false;
            this.done();
            this.getParentOrgnizationList();
          }
        },
        (err) => {
          this.commonHelper.showError(err);
          this.isDoneBtnDisabled = false;
        }
      );
    } else {
      //update organization
      this.isDoneBtnDisabled = true;
      this.organizationManagementService
        .updateOrganization(this.organization_obj._id, sData)
        .subscribe(
          (response: any) => {
            this._toastMessageService.alert(
              "success",
              "Organization User data updated successfully."
            );
            this.getParentOrgnizationList();
            this.response_data = response.data;

            if (this.organization_logo_data) {
              this.changeOrganizationLogo(this.organization_obj._id).then(
                (logoResult: any) => {
                  this.organization_obj.organization_logo_url =
                    logoResult.data && logoResult.data.organization_logo_url
                      ? environment.url +
                        logoResult.data.organization_logo_url +
                        "?t=" +
                        new Date().getTime()
                      : "";
                  this.isDoneBtnDisabled = false;
                  this.done();

                  this.organization_obj.parent_orgnization =
                    this.organization_obj.parent_orgnization._id;
                  this.getParentOrgnizationList();
                }
              );
            } else {
              this.isDoneBtnDisabled = false;
              this.done();
              this.getParentOrgnizationList();
            }
          },
          (err) => {
            this.commonHelper.showError(err);
            this.isDoneBtnDisabled = false;
          }
        );
    }
  }

  changeOrganizationLogo(organization_id) {
    return new Promise((resolve, reject) => {
      this.organizationManagementService
        .updateOrganizationLogo(organization_id, {
          organization_logo_data: this.organization_logo_data,
        })
        .subscribe(
          (response: any) => {
            return resolve({ status: 0, data: response.data });
          },
          (err) => {
            this.commonHelper.showError(err);
            return resolve({ status: -1 });
          }
        );
    });
  }

  onOrganizationLogoUpload(event) {
    if (event && event.indexOf("base64") != -1) {
      this.organization_logo_data = event;
    }
  }

  done() {
    this.decision = "done";
    this.close(true);
  }

  checkZip(value) {
    if (this.organization_obj.organization_address.country == "usa") {
      return /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(value);
    } else if (this.organization_obj.organization_address.country == "india") {
      return /^\d{3}\s?\d{3}$/.test(value);
    }
  }
  zipCodeValidation(event) {
    var value = event;
    if (this.checkZip(value)) {
      this._toastMessageService.alert("success", "Valid Zip Code.");
    } else {
      // this.showErrormsg='Invalid ZipCode';
      this._toastMessageService.alert("error", "In Valid Zip Code.");
    }
  }

  onParentSelect() {
    const parent_id = this.organization_obj.parent_orgnization;
    console.log(parent_id, "id");

    this.children = this.organization_list_child.filter(
      (org) => org.parent_orgnization == parent_id
    );
  }

  getPastorateUnionDetails() {
    this.churchUserTypeService.getAllChurchUserType().subscribe((res: any) => {
      this.loading = false;
      if (res.status == 200 && res.data) {
        this.pastorateUnionDetail = res.data.data;
        this.getPastorateDetails();
      }
    });
  }

  getPastorateDetails() {
    this.pastorateDetailService
      .getAllPastorateType({})
      .subscribe((res: any) => {
        this.loading = false;
        if (res.status == 200 && res.data) {
          this.pastorateDetail = res.data.data;
        }
      });
  }

  onPastorateUnionChange(event) {
    this.filteredPastorateDetails = [];
    setTimeout(() => {
      this.pastorateDetail.forEach((element) => {
        if (element.pastorateUnionDetails._id === event) {
          this.filteredPastorateDetails.push(element);
        }
      });
    }, 200);
  }

  isChurchNameExists(data) {
    let params = {
      churchName: data,
      church_type: this.organization_obj.church_type,
    };
    this.organizationManagementService.checkUservalid(params).subscribe(
      (res: any) => {
        if (res.status == 200 && res.data) {
          if (res.data.message === "This church name already exists") {
            this.isChurchNameExist = "Church name already exists";
            this.isChurchNameAvailable = "";
          } else {
            this.isChurchNameExist = "";
            this.isChurchNameAvailable = "Church name available";
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
