import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonHelper } from 'app/components/common/helpers/common.helper';
import { ChurchUserTypeService } from 'app/components/common/services/church-user-type.service';
import { ToastMessageService } from 'app/components/common/toast-message/toast-message.service';
import { LoaderService } from 'app/views/loader.service';
import { BaseModalComponent } from 'app/views/modals/base-modal.component';
import { ConfirmationModalComponent } from 'app/views/modals/confirmation-modal/confirmation-modal.component';
import { AddUpdateChurchUserTypeComponent } from 'app/views/church-user-type/add-update-church-user-type/add-update-church-user-type.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-church-user-type-management',
  templateUrl: './church-user-type-management.component.html',
  styleUrls: ['./church-user-type-management.component.css'],
})
export class ChurchUserTypeManagementComponent
  extends BaseModalComponent
  implements OnInit
{
  @Input('table_info') table_info: any;
  @Input('default_modules_list') default_modules_list: any = [];

  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  @Output() onLoaderStatusChange: EventEmitter<any> = new EventEmitter();
  default_roles_list: any = [];
  public table_data: any[] = [];
  search_text: any = '';
  constructor(
    private router: Router,
    private commonHelper: CommonHelper,
    private _loaderService: LoaderService,
    private churchUserTypeService: ChurchUserTypeService,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private _toastMessageService: ToastMessageService
  ) {
    super(modalRef);
  }

  ngOnInit() {
    this.getpastorateunionList();
  }

  onClickAdd() {
    this.modalRef = this.modalService.show(AddUpdateChurchUserTypeComponent, {
      class: 'organization-info-management-modal',
      backdrop: 'static',
      keyboard: false,
    });
    this.modalRef.content.decision = '';
    this.modalRef.content.default_roles_list = this.default_roles_list;
    /*this.modalRef.content.country_list = this.country_list;
    this.modalRef.content.state_list = this.state_list;*/

    var currSub: Subscription = this.modalRef.content.onHide.subscribe(
      (result: boolean) => {
        currSub.unsubscribe();
        if (this.modalRef.content.decision === 'done') {
          this.modalRef.content.response_data.index =
            this.table_info.length + 1;
          if (!this.modalRef.content.response_data.middle_name) {
            this.modalRef.content.response_data.middle_name = '';
          }
          this.table_info.push(
            JSON.parse(JSON.stringify(this.modalRef.content.response_data))
          );
        }
      }
    );
  }

  onClickEdit(refData, index) {
    this.modalRef = this.modalService.show(AddUpdateChurchUserTypeComponent, {
      class: 'organization-info-management-modal',
      backdrop: 'static',
      keyboard: false,
    });
    this.modalRef.content.institute_obj = JSON.parse(JSON.stringify(refData));
    this.modalRef.content.default_roles_list = this.default_roles_list;
    var currSub: Subscription = this.modalRef.content.onHide.subscribe(
      (result: boolean) => {
        currSub.unsubscribe();
        if (this.modalRef.content.decision === 'done') {
          this.table_info[index] = JSON.parse(
            JSON.stringify(this.modalRef.content.institute_obj)
          );
        }
      }
    );
  }

  onClickDelete(refData, index) {
    this.modalRef = this.modalService.show(ConfirmationModalComponent, {
      class: 'common-small-modal',
      backdrop: 'static',
      keyboard: false,
    });
    this.modalRef.content.decision = '';
    this.modalRef.content.step = 2;
    this.modalRef.content.confirmation_text =
      'Are you sure to delete church user type?';

    var currSub: Subscription = this.modalRef.content.onHide.subscribe(
      (result: boolean) => {
        currSub.unsubscribe();
        if (this.modalRef.content.decision === 'done') {
          this.onLoaderStatusChange.emit(true);
          //this._loaderService.changeLoaderStatus(true);
          this.churchUserTypeService
            .removeChurchUserType(refData._id, {})
            .subscribe(
              (response: any) => {
                if (response.data) {
                  this._toastMessageService.alert(
                    'success',
                    'Church user type data deleted successfully.'
                  );
                  this.table_info.splice(index, 1);
                }
                this.onLoaderStatusChange.emit(false);
                //this._loaderService.changeLoaderStatus(false);
              },
              (err) => {
                this.commonHelper.showError(err);
                this.onLoaderStatusChange.emit(false);
                //this._loaderService.changeLoaderStatus(false);
              }
            );
        }
      }
    );
  }

  getpastorateunionList() {
    return new Promise((resolve, reject) => {
      this.churchUserTypeService.getAllChurchUserType().subscribe(
        (response: any) => {
          this.table_info = [];
          this.table_info = JSON.parse(JSON.stringify(response.data.data));
        },
        (err) => {
          this.commonHelper.showError(err);
          return resolve({});
        }
      );
    });
  }

  searchOnTable() {
    const searchLower = this.search_text.trim().toLowerCase();
    
    const searchUpper = this.search_text.trim().toUpperCase();

    if(searchLower=='' ||  searchUpper==''){
      this.getpastorateunionList();
    } 
  
    const filteredResults = this.table_info.filter((x) =>
      x.church_user_type_name.toLowerCase().includes(searchLower) ||
      x.church_user_type_name.toUpperCase().includes(searchUpper)
    );

    // Assuming you want to show only the first matching record
    if (filteredResults.length > 0) {
      this.table_info = [filteredResults[0]];
    } else {
      // If no match is found, you might want to reset the table_info to its original state
      this.getpastorateunionList();
    }
  }
  
  


  resetSearchOnTable() {
    this.search_text ='';
    this.getpastorateunionList();

 }


}
